import React from "react";
import { Col, Row } from "reactstrap";
import CompleteWomenCare from "../../assets/img/CompleteWomenCare.png";
import AdvancedTreatmentApproach from "../../assets/img/AdvancedTreatmentApproach.png";
import ExceptionalHospitality from "../../assets/img/ExceptionalHospitality.png";
import "./style.scss";

const WhyOma = () => {
  let whyoma = [
    {
      img: ExceptionalHospitality,
      title: "Exceptional\n Hospitality",
    },
    {
      img: AdvancedTreatmentApproach,
      title: "Advanced Treatment\n Approach",
    },
    {
      img: CompleteWomenCare,
      title: "Holistic Care\n for Women",
    },
  ];

  return (
    // <section className="section-wrapper">
    <div className="container">
      <div className="whyOma-contain-wrapper">
        <div className="template-heading oma-heading">WHAT SETS OMA APART?</div>
        <div className="whyOma-facility-circles">
          <Row className="oma-row">
            {whyoma.map((data) => {
              return (
                <Col sm="" xs="12" className="why-oma-data">
                  <span className="stat-circle">
                    <img src={data.img} alt="" className="ivf-img" />
                  </span>
                  <div>
                    <h6 className="circle-desc">{data.title}</h6>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
    // </section>
  );
};

export default WhyOma;
