import React, { useState } from "react";
import "./style.scss";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import InfraImg1 from "../../assets/img/InfraBanner1.jpg";
import InfraImg2 from "../../assets/img/InfraBanner2.jpg";
import InfraImg3 from "../../assets/img/InfraBanner3.jpg";
import { useNavigate } from "react-router-dom";

const Infrastructure = ({ data }) => {
  const navigate = useNavigate();
  const items = [{ src: InfraImg1 }, { src: InfraImg2 }, { src: InfraImg3 }];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const currentSlideData = data?.content?.[activeIndex] || {};

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === data?.content?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? data?.content?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = data?.content?.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <img src={item?.src} alt={item?.title} className="carousel-img" />
        {/* <CarouselCaption
          captionText={item?.title}
          captionHeader={item?.title}
        /> */}
      </CarouselItem>
    );
  });

  const handleKnowMoreClick = () => {
    navigate("/infrastructure");
  };

  return (
    <section
      className="section-wrapper infrastructure-section"
      id="aboutus-ambience-page"
    >
      <div className="container">
        <div className="content-wrapper">
          <div className=" page-titles oma-aboutus-heading">{data.heading}</div>
          <p className="page-content">{data.description}</p>
          <div className="infra-carousel-contain">
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
                className="opac"
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
                className="opac"
              />
            </Carousel>
            <div className="infra-about">{currentSlideData.title}</div>
          </div>
          <p className="page-content">{currentSlideData.description}</p>
        </div>
        <div className="know-more-wrapper-button">
          <button className="know-more-button" onClick={handleKnowMoreClick}>
            Know More
          </button>
        </div>
      </div>
    </section>
  );
};

export default Infrastructure;
