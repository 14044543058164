import React from "react";
import "./styles.scss";

const ConnectPage = ({ data }) => {
  return (
    <div
      className="section-wrapper connect-page-container"
      id="connect-page-container"
    >
      <div className="container">
        <div className="connect-page-left">
          <div className="template-heading connect-page-heading">
            {data[0].title}
          </div>
          <div className="template-heading connect-page-heading2">
            {data[0].desc}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectPage;
