import React from "react";
import CoreTeam from "../../component/CoreTeam";
import AboutOffering from "../../component/AboutOffering";
import Ambience from "../../component/Ambience";
import {
  AboutOfferingData,
  MeetDoctorsData,
  AmbienceData,
  nicuCircleData,
  AmbienceImages,
} from "./data";
import Footer from "../../common/Footer";
import ComplexCommon from "../../component/ComplexComponent";

const Nicu = () => {
  return (
    <>
      <AboutOffering data={AboutOfferingData} />
      {/* <ComplexCommon data={nicuCircleData} circleCount={"ten"} /> */}
      <ComplexCommon data={nicuCircleData} circleCount={"six"} />
      <CoreTeam data={MeetDoctorsData} slidesToShow={2} />
      <Ambience data={AmbienceData} items={AmbienceImages} />
      <Footer />
    </>
  );
};
export default Nicu;
