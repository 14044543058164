import React from "react";
import "./style.scss";
// import messageIcon from "../../assets/img/popupiconMessage.png";
import tickIcon from "../../assets/img/popupiconTick.png";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

const PopUpModal = ({ data, show, setShow }) => {
  return (
    <div className="popUp-modal-container">
      <Modal
        className="popup-modal-wrapper"
        isOpen={show}
        centered={true}
        backdrop={false}
        scrollable={true}
        size="l"
        fullscreen="l"
        toggle={() => {
          setShow(!show);
        }}
      >
        <ModalBody>
          <div className="popup-modal-icon">
            <img alt="" src={data.icon}></img>{" "}
          </div>
          <div className="popup-modal-desc">
            <h1 className="popup-headings">{data.expression}</h1>
            <br />
            <div className="pop-up-modal-details">{data.message}</div>
          </div>
          {/* <div className="popup-modal-desc">
            <h1 className="popup-headings">
              {data.subexpression}
            </h1>
            <div className="pop-up-modal-details">
              {data.submessage}
            </div>
          </div> */}
          <div className="popup-modal-desc">
            {/* We will share a confirmation email with you shortly. */}
            {data.footnote}
          </div>
          <div className="popup-buttons">
            <button
              className="popup-submit-button"
              onClick={() => setShow(false)}
            >
              OKAY
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PopUpModal;
