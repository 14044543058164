import React, { useState } from "react";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import PopUpModal from "../../component/PopUpModal";
import "./style.scss";
import Sideform from "../../assets/img/Sideform.png";
import { appointment as appointmentData } from "./data";
import popupiconTick from "../../assets/img/popupiconTick.png";
import sorryIcon from "../../assets/img/sorryIcon.png";
import DatePicker from "react-datepicker";
// import {
//   CustomDropdownIndicator,
//   customSelectStyles,
//   customSelectTheme,
// } from "../../utils/utils";

const getCurrentDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[now.getMonth()];
  return `${day}-${month}`;
};

const SideButton = ({ data }) => {
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [state, setState] = useState({
    department: "",
    doctor: "",
    timeslot: "",
    patientName: "",
    reason: "",
    contactNumber: "",
    bookingDate: "",
  });

  // const CurrDate = new Date().toLocaleDateString();
  let alertData = {
    icon: sorryIcon,
    expression: "SORRY!",
    message:
      "The form filled is incorrect/incomplete. Kindly check and fill in the form.",
    subexpression: "",
    submessage: "",
    footnote: "Please check all the details before procedding ahead.",
  };
  let popupdata = {
    icon: popupiconTick,
    expression: "THANKYOU",
    message: `Mr/Mrs. ${state.patientName}, we've got your booking request.`,
    subexpression: "3:20 P.M. | Dr. Tanuja Uchil",
    submessage: "Friday, January 15, 2023 OMA Women's Hospital, Chembur",
    footnote: `We will contact you on your number once booking is confirmed.`,
  };
  let d = new Date();
  const sendEmail = (e) => {
    e.preventDefault();
    let body = {
      subject: "Book Appointment",
      para: "",
      mailData: [
        // { department: { label: "department", value: state.department } },
        // { doctor: { label: "doctor", value: state.doctor } },
        // { timeslot: { label: "timeslot", value: state.timeslot } },
        { patientName: { label: "patientName", value: state.patientName } },
        { reason: { label: "reason", value: state.reason } },
        {
          contactNumber: { label: "contactNumber", value: state.contactNumber },
        },
        { bookingDate: { label: "bookingDate", value: state.bookingDate } },
      ],
    };

    if (
      // state.department === null || state.department === "",
      // state.doctor === null || state.doctor === "",
      // state.timeslot === null || state.timeslot === "",
      (state.patientName === null || state.patientName === "",
      state.reason === null || state.reason === "",
      state.contactNumber === null || state.contactNumber === "",
      state.bookingDate === null || state.bookingDate === "")
    ) {
      setShowAlert(true);
    } else {
      axios
        .post(process.env.REACT_APP_API_CALL, body)
        .then((res) => {
          setShowPopup(true);
        })
        .catch(() => {
          console.log("not sent");
        });
    }
  };

  let handleChange = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <section
        className={show ? "sideform-wrapper active" : "sideform-wrapper"}
      ></section>
      <div
        className={show ? "sideform-container active" : "sideform-container"}
      >
        <button className="sideform-btn" onClick={() => setShow(!show)}>
          <span className="btn-span">
            <img src={Sideform} alt="" /> Request Appointment
          </span>
        </button>
        <div className="appointment-container">
          <div className="cross-icon" onClick={() => setShow(!show)}>
            <span className="line"></span>
            <span className="line"></span>
          </div>
          <form onSubmit={sendEmail}>
            <Row>
              {/* <Col xs={12} md={6} lg={6} xl={7} className="sideform-left">
                <div className="appointment-actions">
                  <button className="callback-btn box">
                    Request a Call Back
                  </button>
                  <p>OR</p>
                  <button className="book-online-btn box">Book Online</button>
                </div>
                <div className="appointment-option">
                  <Select
                    placeholder="Select the department"
                    styles={customSelectStyles}
                    theme={customSelectTheme()}
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                    id="floatingSelect"
                    name="department"
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        department: e.value,
                      }));
                    }}
                    options={[
                      { value: "", label: "Select the Department" },
                      ...appointmentData["departments"].map((itm) => ({
                        value: itm,
                        label: itm,
                      })),
                    ]}
                  />

                  <Select
                    placeholder="Select the doctor"
                    styles={customSelectStyles}
                    theme={customSelectTheme()}
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                    id="floatingSelect"
                    name="doctor"
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        doctor: e.value,
                      }));
                    }}
                    options={[
                      { value: "", label: "Select the Doctor" },
                      ...(state &&
                        state.department &&
                        appointmentData["doctors"][state.department].map(
                          (itm) => ({
                            value: itm,
                            label: itm,
                          })
                        )),
                    ]}
                  />

                  <Select
                    placeholder="Select the timeslot"
                    styles={customSelectStyles}
                    theme={customSelectTheme()}
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                    id="floatingSelect"
                    name="timeslot"
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        timeslot: e.value,
                      }));
                    }}
                    options={[
                      { value: "", label: "Select the timeslot" },
                      ...(state &&
                        state.doctor &&
                        appointmentData["time_slots"][state.doctor].map(
                          (itm) => ({
                            value: itm,
                            label: itm,
                          })
                        )),
                    ]}
                  />
                </div>
              </Col> */}

              <Col xs={12} md={12} lg={12} xl={12} className="sideform-right">
                <div className="right-top">
                  {state.doctor && (
                    <div>
                      <p className="right-top-heading">Dr.{state.doctor}</p>

                      {state &&
                        state.doctor &&
                        appointmentData["description"][
                          state && state.doctor
                        ].map((itm) => {
                          return <p className="right-top-para">{itm}</p>;
                        })}
                    </div>
                  )}
                  {state.timeslot && (
                    <div className="d-flex">
                      <span>
                        <p className="appoint">Appointment</p>
                        <p className="appoint">Date: {getCurrentDate()}</p>
                        <p className="appoint">Time: {state.timeslot}</p>
                      </span>
                    </div>
                  )}
                </div>
                <div className="right-bottom">
                  <Form.Control
                    name="patientName"
                    type="text"
                    placeholder="Patient Name"
                    className="right-bottom-info box"
                    onChange={handleChange}
                  />
                  <Form.Control
                    name="contactNumber"
                    type="number"
                    placeholder="Contact No"
                    className="right-bottom-info box"
                    onChange={handleChange}
                    style={{ MozAppearance: "textfield" }}
                    inputMode="numeric"
                  />
                  <Form.Control
                    name="reason"
                    type="text"
                    placeholder="Reason"
                    className="right-bottom-info box"
                    onChange={handleChange}
                  />
                  <DatePicker
                    className="right-bottom-info box date-picker"
                    selected={state?.bookingDate}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, bookingDate: e }))
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select booking date"
                    minDate={new Date()}
                    maxDate={
                      new Date(new Date().getTime() + 15 * 24 * 60 * 60 * 1000)
                    }
                  />

                  <div className="confirm-btn-container">
                    <button className="confirm-btn box" type="submit">
                      Request Appointment
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
        <PopUpModal show={showPopup} setShow={setShowPopup} data={popupdata} />
        <PopUpModal show={showAlert} setShow={setShowAlert} data={alertData} />
      </div>
    </>
  );
};
export default SideButton;
