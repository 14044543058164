import aboutUs from "../../assets/img/AboutUs.png";
import coreTeam from "../../assets/img/CoreTeam.png";
import ambience from "../../assets/img/Ambience.png";
import awards from "../../assets/img/Awards.png";
import successStories from "../../assets/img/SuccessStories.png";
import maternity from "../../assets/img/Maternity.png";
import gynecology from "../../assets/img/Gynecology.png";
import asGynecology from "../../assets/img/AestheticGynecology.png";
import ivf from "../../assets/img/IVF-2.png";
import fertility from "../../assets/img/Fertility.png";
import radiology from "../../assets/img/Radiology.png";
import nicu from "../../assets/img/NICU.png";
import labTest from "../../assets/img/LabTest.png";
import vaccination from "../../assets/img/Vaccination.png";
import vConsulting from "../../assets/img/VirtualConsulting.png";

export let navLinks = [
  {
    label: "About OMA",
    url: "/aboutus",
    menuId: "aboutoma",
    subnav: true,
    subnavList: [
      {
        key: 1,
        id: "aboutus-page",
        label: "About Us",
        url: "/aboutus",
        icons: aboutUs,
      },
      {
        key: 2,
        id: "aboutus-core-team-page",
        label: "Core Team",
        url: "/aboutus",
        icons: coreTeam,
      },
      {
        key: 3,
        id: "aboutus-ambience-page",
        label: "Ambience",
        url: "/aboutus",
        icons: ambience,
      },
      // {
      //   key: 4,
      //   id: "aboutus-awards-page",
      //   label: "Awards & Certificates",
      //   url: "/aboutus",
      //   icons: awards,
      // },
      // {
      //   key: 5,
      //   id: "aboutus-success-stories-page",
      //   label: "Success Stories",
      //   url: "/aboutus",
      //   icons: successStories,
      // },
    ],
  },
  {
    label: "Offerings",
    url: "/maternity",
    subnav: true,
    menuId: "offerings",
    subnavList: [
      {
        label: "Maternity",
        url: "/maternity",
        icons: maternity,
      },
      {
        label: "Gynecology",
        url: "/gynecology",
        icons: gynecology,
      },

      {
        label: "IVF & Fertility",
        url: "/ivf-&-fertility",
        icons: ivf,
      },
      {
        label: "Onco Gynecology",
        url: "/onco-gynecology",
        icons: fertility,
      },
      {
        label: "Aesthetic Regenerative & Functional Gynecology",
        url: "/aesthetic-&-regenerative-gynecology",
        icons: asGynecology,
      },
      {
        label: "Radiology & Diagnostics",
        url: "/radiology-&-diagnostics",
        icons: radiology,
      },
      {
        label: "Neonatology Department",
        url: "/neonatology-department",
        icons: nicu,
      },
    ],
  },
  // {
  //   label: "Home Services",
  //   menuId: "homeservices",
  //   url: "#",
  //   subnav: true,
  //   subnavList: [
  //     {
  //       label: "Lab Test",
  //       url: "/labtest",
  //       icons: labTest,
  //     },
  //     {
  //       label: "Vaccination",
  //       url: "/vaccination",
  //       icons: vaccination,
  //     },
  //     {
  //       label: "Virtual Consulting",
  //       url: "/virtual",
  //       icons: vConsulting,
  //     },
  //   ],
  // },
  // {
  //   label: "OMA Community",
  //   url: "/community",
  //   subnav: false,
  //   menuId: "omacommunity",
  //   subnavList: [],
  // },
  {
    label: "Resources",
    url: "/resources",
    menuId: "resources",
    subnav: false,
    subnavList: [],
  },
  {
    label: "Let's Connect",
    url: "/connect",
    menuId: "connect",
    subnav: false,
    subnavList: [],
  },
];

export let offcanvasLinks = [
  {
    label: "About OMA",
    url: "/aboutus",
    subnav: false,
  },
  {
    label: "Offerings",
    url: "#",
    subnav: true,
    subnavList: [
      {
        label: "Maternity",
        url: "/maternity",
        icons: "hshsh",
      },
      {
        label: "Gynecology",
        url: "/gynecology",
        icons: "hshsh",
      },
      {
        label: "IVF & Fertility",
        url: "/ivf-&-fertility",
        icons: "hshsh",
      },
      {
        label: "Onco Gynecology",
        url: "/onco-gynecology",
        icons: "hshsh",
      },
      {
        label: "Aesthetic Regenerative & Functional Gynecology",
        url: "/aesthetic-&-regenerative-gynecology",
        icons: "hshsh",
      },
      {
        label: "Radiology & Diagnostics",
        url: "/radiology-&-diagnostics",
        icons: "hshsh",
      },
      {
        label: "Neonatology Department",
        url: "/neonatology-department",
        icons: "hshsh",
      },
    ],
  },
  // {
  //   label: "Home Services",
  //   url: "#",
  //   subnav: true,
  //   subnavList: [
  //     {
  //       label: "Lab Test",
  //       url: "/labtest",
  //       icons: "hshsh",
  //     },
  //     {
  //       label: "Vaccination",
  //       url: "/vaccination",
  //       icons: "hshsh",
  //     },
  //     {
  //       label: "Virtual Consulting",
  //       url: "/virtual",
  //       icons: "hshsh",
  //     },
  //   ],
  // },
  // {
  //   label: "OMA Community",
  //   url: "/community",
  //   subnav: false,
  //   subnavList: [],
  // },
  {
    label: "Resources",
    url: "/resources",
    subnav: false,
    subnavList: [],
  },
  {
    label: "Let's Connect",
    url: "/connect",
    subnav: false,
    subnavList: [],
  },
];

export let communicationInformation = {
  call: "Call : +917208973301",
  email: "Email : info@omahospital.com",
  emergency: "Emergency : +919136956680",
  whatsapp: "Whatsapp : +917208973301",
};
