import "./style.scss";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import ambulance from "../../assets/img/Ambulance.png";
import informationDesk from "../../assets/img/Information-desk.png";
import axios from "axios";
import sorryIcon from "../../assets/img/sorryIcon.png";
import popupiconTick from "../../assets/img/popupiconTick.png";
import PopUpModal from "../PopUpModal";

const QuickPoint = ({ data }) => {
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [form, setForm] = useState({
    oma_name: "",
    oma_mobile: "",
    oma_email: "",
    oma_city: "",
    oma_department: "",
    oma_doctor: "",
    oma_procedure: "",
  });

  let alertData = {
    icon: sorryIcon,
    expression: "SORRY!",
    message:
      "The form filled is incorrect/incomplete. Kindly check and fill in the form.",
    subexpression: "",
    submessage: "",
    footnote: "Please check all the details before procedding ahead.",
  };

  let popupdata = {
    icon: popupiconTick,
    expression: "Thank You!",
    message: "Mrs Shah, we`ve got you confirmed for your appointment",
    subexpression: "3:20 P.M. | Dr. Tanuja Uchil",
    submessage: "Friday, January 15, 2023 OMA Women's Hospital, Chembur",
    footnote: "We will share a confirmation email with you shortly.",
  };

  let handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    let body = {
      subject: "Request to Connect",
      para: "",
      mailData: [
        { oma_name: { label: "Name", value: form.oma_name } },
        { oma_mobile: { label: "Mobile", value: form.oma_mobile } },
        { oma_email: { label: "Email", value: form.oma_email } },
        { oma_city: { label: "City Name", value: form.oma_city } },
        { oma_department: { label: "Department", value: form.oma_department } },
        { oma_doctor: { label: "Doctor", value: form.oma_doctor } },
        { oma_procedure: { label: "Procedure", value: form.oma_procedure } },
      ],
    };
    if (
      (form.oma_name === null || form.oma_name === "",
      form.oma_mobile === null || form.oma_mobile === "",
      form.oma_email === null || form.oma === "",
      form.oma_city === null || form.oma_city === "",
      form.oma_department === null || form.oma_department === "",
      form.oma_doctor === null || form.oma_doctor === "",
      form.oma_procedure === null || form.oma_procedure === "")
    ) {
      setAlert(true);
    } else {
      axios
        .post(process.env.REACT_APP_API_CALL, body)
        .then((res) => {
          setShow(true);
        })
        .catch(() => {
          console.log("not sent");
        });
    }
  };

  return (
    <section
      className="section-wrapper quick-touchpoint-section"
      id="quick-touchpoint-section"
    >
      <div className="container">
        <div className="template-heading left-align resp-header ">
          {data[0].mainHeading}
        </div>
        <div className="quick-touchpoint-container">
          <div className="quick-touchpoint-left-container">
            <p className="quick-touchpoint-paras">{data[0].description[0]}</p>
            <p className="quick-touchpoint-paras">{data[0].description[1]}</p>
            <p className="quick-touchpoint-paras">{data[0].description[2]}</p>
            <div className="emergency-number">
              <span className="quick-touchpoint-circles">
                <img
                  src={informationDesk}
                  alt=""
                  className="quick-touchpoint-images"
                ></img>
              </span>
              <p className="quick-touchpoint-number">{data[0].contacts[0]}</p>
            </div>
            <div className="emergency-number">
              <span className="quick-touchpoint-circles">
                <img
                  src={ambulance}
                  alt=""
                  className="quick-touchpoint-images"
                ></img>
              </span>
              <p className="quick-touchpoint-number">{data[0].contacts[1]}</p>
            </div>
          </div>
          <div className="quick-touchpoint-right-container">
            <div className="quick-booking-form-container">
              <div className="form-heading">Book Your Service</div>
              <Form className="form-inputs-container" onSubmit={sendEmail}>
                <div className="form-inputs-container">
                  <div className="form-input-box oneline">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Name"
                      name="oma_name"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Mobile"
                      name="oma_mobile"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-input-box">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Email"
                      name="oma_email"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-input-box input-arrow">
                    <select
                      name="oma_city"
                      className="form-input form-input-dropdown"
                      placeholder="Select City"
                      onChange={handleChange}
                    >
                      <option value="" placeholder="Select City">
                        Select City
                      </option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Pune">Pune</option>
                      <option value="Navi Mumbai">Navi Mumbai</option>
                      <option value="Thane">Thane</option>
                    </select>
                  </div>
                  <div className="form-input-box input-arrow">
                    <select
                      name="oma_department"
                      className="form-input form-input-dropdown"
                      placeholder="Select City"
                      onChange={handleChange}
                    >
                      <option value="" placeholder="Select Department">
                        Select Department
                      </option>
                      <option value="Option 1">Option 1</option>
                      <option value="Option 2">Option 2</option>
                      <option value="Option 3">Option 3</option>
                      <option value="Option 4">Option 4</option>
                    </select>
                  </div>
                  <div className="form-input-box input-arrow">
                    <select
                      name="oma_doctor"
                      className="form-input form-input-dropdown"
                      placeholder="Select Doctor"
                      onChange={handleChange}
                    >
                      <option value="" placeholder="Select Doctor">
                        Select Doctor
                      </option>
                      <option value="Option 1">Option 1</option>
                      <option value="Option 2">Option 2</option>
                      <option value="Option 3">Option 3</option>
                      <option value="Option 4">Option 4</option>
                    </select>
                  </div>
                  <div className="form-input-box input-arrow">
                    <select
                      name="oma_procedure"
                      className="form-input form-input-dropdown"
                      placeholder="Select City"
                      onChange={handleChange}
                    >
                      <option value="" placeholder="Select City">
                        Select Procedure
                      </option>
                      <option value="Option 1">Option 1</option>
                      <option value="Option 2">Option 2</option>
                      <option value="Option 3">Option 3</option>
                      <option value="Option 4">Option 4</option>
                    </select>
                  </div>
                </div>

                <div className="get-call-back">
                  <button className="call-back-button">Get Call Back</button>
                  <button className="submit-query-button" type="submit">
                    Book Now
                  </button>
                </div>
                <PopUpModal show={show} setShow={setShow} data={popupdata} />
                <PopUpModal show={alert} setShow={setAlert} data={alertData} />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuickPoint;
