import React from "react";
import "./styles.scss";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

const ModalPage = ({ modalData, show, setShow }) => {
  return (
    <div>
      <Modal
        className="modal-wrapper"
        isOpen={show}
        centered={true}
        backdrop={false}
        scrollable={true}
        size="xl"
        fullscreen="xl"
        toggle={() => {
          setShow(!show);
        }}
      >
        <ModalHeader
          toggle={() => {
            setShow(!show);
          }}
        >
          {modalData && modalData.title}
        </ModalHeader>
        <ModalBody>
          <h1 className="template-heading">
            {modalData && modalData.subheading}
          </h1>
          <div className="modal-details">{modalData && modalData.details}</div>
        </ModalBody>
       
      </Modal>
    </div>
  );
};

export default ModalPage;
