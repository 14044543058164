import commonDesign from "../../assets/img/commonDesign.png";
import AboutNicu from "../../assets/img/AboutNicu1.png";
// import AboutNicu from "../../assets/img/AboutNicu.png";
// import TanujaUchil from "../../assets/img/TanujaUchil.png";
// import HarshMehta from "../../assets/img/HarshMehta.png";
// import AnitaSharma from "../../assets/img/AnitaSharma.png";
// import ComponentImg from "../../assets/img/nicu-circle.png";
import ComponentImg from "../../assets/img/nicu-circle1.png";

import NICUImg1 from "../../assets/img/NICUBanner1.jpg";
// import NICUImg2 from "../../assets/img/NICUBanner2.jpg";
import NeonatologyImg2 from "../../assets/img/neonatology-img-2.png";
import NICUImg3 from "../../assets/img/NICUBanner3.jpg";
import DefaultAvatar from "../../assets/img/DefaultAvatar.jpeg";

export let AboutOfferingData = {
  mainHeading: "NEONATOLOGY DEPARTMENT",
  subHeadeing: "DELIVERING MIRACLES AT OUR NEONATOLOGY DEPARTMENT",
  description: [
    "Our neonatal-trained nurses and Level 1 Neonatology Department ensure that our tiniest babies always have a safe and comfortable place to recover and grow.",
  ],
  img: AboutNicu,
};

// export let nicuCircleData = {
//   mainHeading: "OMA COVERS EVERYTHING FOR YOUR LITTLE ONE",
//   circleImg: ComponentImg,
//   circleData: [
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       text: "Facilities for Central\n line Insertion",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       text: "In-House Cardiac 2D Echo\n and USG Monitoring",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       text: "24-hour consultant level\n Neonatologists",
//     },
//     {},
//     {},
//     {},
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       text: "Facilities for total\n Parental Nutrition",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       text: "Multipara Monitors and\n Apnea monitors",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       text: "CPAP and Sipap &\n LED phototherapy",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       text: "Ventilator with Clieo",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       text: "Warmers & Ventilators ",
//     },
//     {},
//     {},
//     {},
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       text: "Facilities for Blood Exchange",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       text: "Facilities for Neonatal\n Surgeries",
//     },
//   ],
//   responsiveCircleData: [
//     {
//       positionClass: "bottom",
//       text: "Facilities for Blood Exchange\n & Neonatal Surgeries",
//     },
//     {
//       positionClass: "bottom",
//       text: "Facilities for Central line\n Insertion",
//     },
//     {
//       positionClass: "bottom",
//       text: "In-House Cardiac 2D Echo\n and USG Monitoring",
//     },
//     {
//       positionClass: "bottom",
//       text: "24-hour consultant level\n Neonatologists",
//     },
//     {
//       positionClass: "top",
//       text: "Facilities for total\n Parental Nutrition",
//     },
//     {
//       positionClass: "top",
//       text: "Multipara Monitors and\n Apnea monitors",
//     },
//     {
//       positionClass: "top",
//       text: "CPAP and Sipap &\n LED phototherapy",
//     },
//     {
//       positionClass: "top",
//       text: "Warmers & Ventilators\n with Clieo",
//     },
//   ],
//   contentDesc:
//     "The health of your infant is constantly monitored by our specially built incubators equipped with blood gas cylinders and baby warmers.",
// };

export let nicuCircleData = {
  mainHeading: "OMA CARES FOR YOUR LITTLE ONE",
  // description: [
  //   "Our services encompass prevention, treatment, and holistic support for women battling gynecologic cancers.",
  // ],

  circleImg: ComponentImg,
  circleData: [
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      headingClass: "radiology-header",
      headingText: "Nutritional Support:",
      text: "Ensuring proper nutrition for premature and sick newborns through various means.",
    },
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      headingClass: "radiology-header",
      headingText: "Collaborative Approach:",
      text: "Working with healthcare professionals like neonatal nurses, respiratory therapists, and social workers to provide comprehensive care.",
    },
    {},
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      headingClass: "radiology-header",
      headingText: "Family-centered care: ",
      text: "Close collaboration with parents, providing emotional support, education, and guidance.",
    },
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      headingClass: "radiology-header",
      headingText: "Infections:",
      text: " Prompt diagnosis and treatment of infections in newborns.",
    },
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      headingClass: "radiology-header",
      headingText: "Newborn Care:  ",
      text: "Specialized care for newborns, including preemies, low birth weight infants, and those with congenital conditions.",
    },
    {},
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      headingClass: "radiology-header",
      headingText: "Jaundice: ",
      text: "Diagnosis and treatment of neonatal jaundice, often using phototherapy.",
    },
  ],
  responsiveCircleData: [
    {
      positionClass: "bottom",
      text: "Screening and Diagnosis: Advanced methods like Pap smears, HPV tests, and imaging for early cancer detection.",
    },
    {
      positionClass: "bottom",
      text: "Surgical Treatments: Minimally invasive surgeries, hysterectomies, and fertility-sparing procedures.",
    },
    {
      positionClass: "bottom",
      text: "Chemotherapy and Radiation Therapy: Customized chemotherapy and radiation treatments.",
    },
    {
      positionClass: "top",
      text: "Targeted and Immunotherapy: Innovative therapies focusing on cancer cells and boosting the immune system.",
    },
    {
      positionClass: "top",
      text: "Fertility Preservation and Genetic Counseling: Options for preserving fertility and genetic testing for those at high risk.",
    },
    {
      positionClass: "top",
      text: "Palliative Care and Rehabilitation: Managing symptoms and aiding recovery post-treatment.",
    },
  ],
};

export let MeetDoctorsData = {
  mainHeading: "Meet the Doctors",
  subHeading:
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields.",
  description: [
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields.",
  ],
  commonDesign: commonDesign,
  ifquestion: true,
  question: "Got a question? Leave no room for doubt.",
  button: "Contact Us",
  // doctors: [
  //   {
  //     img: TanujaUchil,
  //     name: "Dr. Kalpesh Shah",
  //     about:
  //       "MBBS, DGO, MD(OBG) Senior Consultant Obstetrician & Gynaecologist",
  //   },
  //   {
  //     img: HarshMehta,
  //     name: "Dr. Neeta Nathani",
  //     about:
  //       "MBBS, MD, DGO, MRCOG(UK), FRCOG Consultant Obstetrician and Gynaecologist",
  //   },
  // ],
  doctors: [
    {
      img: DefaultAvatar,
      name: "Dr. Kalpesh Shah",
      role: "Radiologist and Ultrasonologist",
      experience: [
        "MBBS (1979) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai in 1979.",
        "Diploma in Medical Radio-Diagnosis (1982) -  Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
        "MD - Radio Diagnosis/Radiology (1984) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
      ],
      description: [
        "Dr. Manmohan Madan is an experienced Radiologist and Ultrasonologist with over four decades of expertise. He holds an MD in Radio Diagnosis and has been a key contributor to medical imaging services. Dr. Madan's commitment to diagnostic accuracy and patient care has made him a respected figure in the field of radiology.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Neeta Nathani",
      role: "Pediatrician",
      experience: [
        "MBBS - M. G. M. Medical College, Indore",
        "MD (Pediatrics) - M. G. M. Medical College, Indore & M. Y. H. Hospital, Mumbai",
      ],
      description: [
        "Dr. Neeta Nathani has been serving the healthcare needs of children in Mumbai for over three decades. She runs Nathani Clinic in Chembur and serves as an attending pediatrician at Surya Hospital, Kolekar Hospital, and Zen Hospital in Chembur. Dr. Nathani is not only a trusted pediatrician but also an educator who conducts teaching sessions for family practitioners and delivers CME lectures at various conferences. Her commitment to pediatric care and medical education is commendable.",
      ],
    },

    // {
    //   img: AnitaSharma,
    //   name: "Dr. Neeta Nathani",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Neeta Nathani has been serving the healthcare needs of children in Mumbai for over three decades. She runs Nathani Clinic in Chembur and serves as an attending pediatrician at Surya Hospital, Kolekar Hospital, and Zen Hospital in Chembur. Dr. Nathani is not only a trusted pediatrician but also an educator who conducts teaching sessions for family practitioners and delivers CME lectures at various conferences. Her commitment to pediatric care and medical education is commendable.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Amit Sahu",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Amit Sahu is a fellowship-trained Interventional Radiologist having an experience of over 12 years. He has undergone training in Vascular and interventional Radiology and Interventional Neuroradiology from Seth GS Medical College & King Edward Memorial (KEM) Hospital in Mumbai and the Johns Hopkins Hospital in Baltimore, USA.",
    //     "He has extensive experience in managing and treating patients related to Neuro, Body & Peripheral Interventional Superspecialist. He has an experience of performing over thousands of endovascular procedures to his credit.",
    //     "Scope Of Services: ",
    //     "He is highly proficient in performing Interventional Procedures centered around women-related vascular problems, and male and female infertility apart from other Neuro & Peripheral Vascular Interventions.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Prasad Mahadevan",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Prasad Mahadevan is a highly skilled gynecologist with a passion for women's health. With over three decades of experience, he is known for his expertise in obstetrics and Gynecology. Dr. Mahadevan's commitment to patient well-being, along with his involvement in various medical associations, highlights his dedication to women's healthcare.",
    //   ],
    // },
  ],
};

export let AmbienceData = {
  heading: "Nurture the new life at our NICU",
  description:
    "We have a specialised NICU for your little one with all the advanced technological equipment and highly skilled staff who are always on their toes to look after your baby’s every medical need. We at OMA, take no chances when it comes to caring for your little one just as you would.",
};

export let AmbienceImages = [
  {
    src: NICUImg1,
  },
  {
    src: NeonatologyImg2,
  },
  {
    src: NICUImg3,
  },
];
