import React from "react";

import Header from "../common/Header";
import SideButton from "../common/SideButton";

const Layout = (props) => {
  
  return (
    <>
      <main className="main-app">
        <Header />
        <div className="main-container">{props.children}</div>
        <SideButton />
      </main>
    </>
  );
};

export default Layout;