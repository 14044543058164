import commonDesign from "../../assets/img/commonDesign.png";
import TanujaUchil from "../../assets/img/TanujaUchil.png";
import HarshMehta from "../../assets/img/HarshMehta.png";
import AnitaSharma from "../../assets/img/AnitaSharma.png";

import award1 from "../../assets/img/award1.png";
import award2 from "../../assets/img/award2.png";
import award3 from "../../assets/img/award3.png";
import award4 from "../../assets/img/award4.png";
import DefaultAvatar from "../../assets/img/DefaultAvatar.jpeg";

import OperationTheatre from "../../assets/img/OperationTheater.jpeg";
import OperationTheatre2 from "../../assets/img/OperationTheater2.JPG";
import Neonatalogy from "../../assets/img/Neonatalogy.JPG";
import Fertility_Ivf1 from "../../assets/img/Fertility_Ivf1.JPG";
import Fertility_Ivf2 from "../../assets/img/Fertility_Ivf2.JPG";
import Rooms_Accomodation1 from "../../assets/img/Rooms_Accomodation1.JPG";
import Rooms_Accomodations2 from "../../assets/img/Rooms_Accomodations2.JPG";

import UsgRoom1 from "../../assets/img/UsgRoom1.JPG";
import UsgRoom2 from "../../assets/img/UsgRoom2.JPG";
import AntenatalExercise from "../../assets/img/AntenatalExercise.JPG";
import ConsultingRoom1 from "../../assets/img/ConsultingRoom1.JPG";
import ConsultingRoom2 from "../../assets/img/ConsultingRoom2.JPG";

import Reception1 from "../../assets/img/Reception1.JPG";
import Reception2 from "../../assets/img/Reception2.JPG";
import StaffTraining1 from "../../assets/img/StaffTraining1.JPG";
import StaffTraining2 from "../../assets/img/StaffTraining2.JPG";

export let AboutUsData = {
  heading1: "Your Health, Our Promise: Built Around You",
  // heading2: "Which is all About YOU",
  description1:
    "At OMA Hospital, we transcend traditional healthcare. Our ethos is rooted in a deep commitment to your comprehensive health and wellness. Here, you're not just a patient, but a valued member of our community, embraced by our team of highly skilled doctors and warm-hearted healthcare professionals. We blend heartfelt care with top-notch medical expertise, creating a positive environment for your health journey.",
  description2:
    "Our approach is simple yet impactful: to enrich every facet of a woman's health journey with compassion and effectiveness. We aim to foster a life of health and happiness for both mothers and their little ones.",
  description3:
    "With state-of-the-art facilities, a skilled team, and a hassle-free medical journey, OMA is your partner in holistic health and well-being. Welcome to a brighter, healthier future with OMA.",
  circleData: [
    {
      key: 1,
      Number: "1000+",
      desc: "Healthy babies",
    },
    {
      id: 2,
      Number: "520+",
      desc: "Succesful IVF cases",
    },
    {
      id: 3,
      Number: "189",
      desc: "Satisfied aesthetic gynaecology patients",
    },
  ],
};

export const FoundingDoctor = {
  id: "founding-doctor",
  mainHeading: "Caring Experts: Your Health Partners",
  description: "Meet Our Founding Doctor",
  descriptionBottom:
    "Dr. Tanuja Uchil serves as a full-time consultant in Obstetrics and Gynecology at OMA Hospital. Dr. Uchil's journey in the field of medicine is characterized by excellence, expertise, and a genuine commitment to women's health.",
  doctors: [
    {
      img: TanujaUchil,
      name: "Dr. Tanuja Uchil",
    },
  ],
};

export let MeetCoreTeamData = {
  id: "aboutus-core-team-page",
  mainHeading: "Meet Our dedicated Medical Team",
  subHeading: "",
  description:
    "  At Oma, we take pride in having a team of dedicated and accomplished medical professionals who are committed to providing the highest quality healthcare services. Our team includes highly qualified specialists with vast experience in their respective fields. Here's a glimpse of some of our esteemed medical experts:",
  commonDesign: commonDesign,
  ifquestion: false,
  doctors: [
    {
      img: DefaultAvatar,
      name: "Dr. Manmohan Madan",
      role: "Radiologist and Ultrasonologist",
      experience: [
        "MBBS (1979) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai in 1979.",
        "Diploma in Medical Radio-Diagnosis (1982) -  Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
        "MD - Radio Diagnosis/Radiology (1984) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
      ],
      description: [
        "Dr. Manmohan Madan is an experienced Radiologist and Ultrasonologist with over four decades of expertise. He holds an MD in Radio Diagnosis and has been a key contributor to medical imaging services. Dr. Madan's commitment to diagnostic accuracy and patient care has made him a respected figure in the field of radiology.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Amit Sahu",
      role: "Consultant Interventional Radiologist",
      experience: ["MD FVIR FINR"],
      description: [
        "Dr. Amit Sahu is a fellowship-trained Interventional Radiologist having an experience of over 12 years. He has undergone training in Vascular and interventional Radiology and Interventional Neuroradiology from Seth GS Medical College & King Edward Memorial (KEM) Hospital in Mumbai and the Johns Hopkins Hospital in Baltimore, USA.",
        "He has extensive experience in managing and treating patients related to Neuro, Body & Peripheral Interventional Superspecialist. He has an experience of performing over thousands of endovascular procedures to his credit.",
        "Scope Of Services:",
        "He is highly proficient in performing Interventional Procedures centered around women-related vascular problems, and male and female infertility apart from other Neuro & Peripheral Vascular Interventions.",
        "Varicose Veins: EVLT (Laser), Sclerotherapy, Venaseal",
        "Deep Vein Thrombosis: Catheter Thrombolysis, IVC Filter Placement",
        "Uterine Artery Fibroid Embolization",
        "Uterine Artery Embolization in Adenomyosis, Uterine AV Malformations, Post Partum Bleeding",
        "Pelvic Congestion Syndrome: Ovarian Vein Embolization",
        "Vascular Malformations: Embolization, Sclerotherapy",
        "Male Infertility: Varicocele Embolization",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Prasad Mahadevan",
      role: "Gynecologist",
      experience: [
        "MBBS (1990) - Rajiv Gandhi University of Health Sciences, Bangalore",
        "DGO (1992) -  Kasturba Medical College",
        "MD - Obstetrics & Gynaecology (1993) - Kasturba Medical College in 1993",
      ],
      description: [
        "Dr. Prasad Mahadevan is a highly skilled gynecologist with a passion for women's health. With over three decades of experience, he is known for his expertise in obstetrics and Gynecology. Dr. Mahadevan's commitment to patient well-being, along with his involvement in various medical associations, highlights his dedication to women's healthcare.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Keshav Pai",
      role: "Gynecologist and Laparoscopic Surgeon",
      experience: ["M.B.B.S, MD / MS, DNB, D.G.O"],
      description: [
        "Dr. Keshav Pai is a distinguished gynecologist and laparoscopic surgeon with a strong academic background. He completed his post-graduation at LTMMC and LTMGH and received specialized training in laparoscopy in Belgium. Dr. Pai's special interests include fertility-enhancing surgery and minimally invasive procedures like laparoscopic hysterectomy. His contributions to research, medical publications, and his dedication to patient care make him a sought-after gynecologist.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Jay Rashmi Anam",
      role: "Visiting Consultant Surgical Oncologist",
      experience: [
        "M.Ch. Surgical Oncology (2013 – 2016) - Tata Memorial Hospital, Mumbai",
      ],
      description: [
        "Dr. Jay Rashmi Anam is a highly skilled and accomplished Surgical Oncologist with a wealth of experience and a strong educational background. Currently serving as a Visiting Consultant Surgical Oncologist at Saifee Hospital, Kohinoor Hospital, Bhatia Hospital, and Honorary Visiting Surgical Oncologist at K.J Somaiya Medical College, Dr. Anam has established himself as a key figure in the field. His extensive educational journey includes an M.Ch. in Surgical Oncology from Tata Memorial Hospital and a Diplomate of National Board in General Surgery. Dr. Anam's strengths lie in performing aesthetic breast conservation surgeries with oncoplasty, port insertions for chemotherapy, and cytoreductive surgeries. He is also recognized for his research contributions, publications, and international poster presentations in the field of oncology. Dr. Anam's dedication to advancing surgical oncology and his commitment to patient care make him a highly respected consultant in the medical community.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Rajpurohit Jitesh Rajendra Singh",
      role: "Surgical Oncologist",
      experience: [
        "M.B.B.S (2004-2010) from PDVVPF’s Medical College & Hospital, Ahmednagar, Maharashtra",
        "MS General Surgery (2013 – 2016) - TNMC & BYL Nair Hospital, Mumbai Central, Maharashtra",
        "MCh Surgical Oncology (2017 – 2020) - Regional Cancer Centre,Trivandrum, Kerala",
      ],
      description: [
        "Dr. Rajpurohit is a renowned Surgical Oncologist with an M.S. in General Surgery and an MCh in Surgical Oncology. He currently serves as a Consultant at Speciality Surgical Oncology, Ghatkopar, Mumbai, bringing his expertise to the forefront in cancer treatment. With extensive training and a commitment to excellence, he is a valuable asset to our healthcare team.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Neeta Nathani",
      role: "Pediatrician",
      experience: [
        "MBBS - M. G. M. Medical College, Indore",
        "MD (Pediatrics) - M. G. M. Medical College, Indore & M. Y. H. Hospital, Mumbai",
      ],
      description: [
        "Dr. Neeta Nathani has been serving the healthcare needs of children in Mumbai for over three decades. She runs Nathani Clinic in Chembur and serves as an attending pediatrician at Surya Hospital, Kolekar Hospital, and Zen Hospital in Chembur. Dr. Nathani is not only a trusted pediatrician but also an educator who conducts teaching sessions for family practitioners and delivers CME lectures at various conferences. Her commitment to pediatric care and medical education is commendable.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Dilip Bhosale",
      role: "General Surgeon and Proctologist",
      experience: [
        "MBBS (1981) - Grant Medical College,",
        "MS, General Surgery (1985) - JJ Hospital, Mumbai",
      ],
      description: [
        "Dr. Dilip Bhosale is a highly experienced general surgeon with over three decades of practice in Chembur, Mumbai. He specializes in proctology and is known for his expertise in laser surgery for anorectal disorders. Dr. Bhosale is not only a practitioner but also a faculty member at two laser academies, where he trains surgeons across India in laser proctology. His commitment to surgical excellence and education sets him apart in the field of proctology.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Jeevan J. Gadgil",
      role: "Anesthetist and Critical Care Specialist",
      experience: [
        "MBBS (2001) - Maharashtra Medical College, Mumbai",
        "D.A (2003) - Mumbai Medical Council, Mumbai",
        "Diploma in Anaesthesiology (2003) - University of Mumbai",
      ],
      description: [
        "Dr. Gadgil is a seasoned anesthetist and critical care specialist with two decades of experience. His qualifications include MBBS, D.A., FCPS, and DNB. With a strong foundation in anesthesia and critical care, Dr. Gadgil plays a pivotal role in ensuring the safety and well-being of our patients. His expertise and dedication are invaluable in complex surgical procedures and critical care management.",
      ],
    },

    // {
    //   img: AnitaSharma,
    //   name: "Dr. Neeta Nathani",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Neeta Nathani has been serving the healthcare needs of children in Mumbai for over three decades. She runs Nathani Clinic in Chembur and serves as an attending pediatrician at Surya Hospital, Kolekar Hospital, and Zen Hospital in Chembur. Dr. Nathani is not only a trusted pediatrician but also an educator who conducts teaching sessions for family practitioners and delivers CME lectures at various conferences. Her commitment to pediatric care and medical education is commendable.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Amit Sahu",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Amit Sahu is a fellowship-trained Interventional Radiologist having an experience of over 12 years. He has undergone training in Vascular and interventional Radiology and Interventional Neuroradiology from Seth GS Medical College & King Edward Memorial (KEM) Hospital in Mumbai and the Johns Hopkins Hospital in Baltimore, USA.",
    //     "He has extensive experience in managing and treating patients related to Neuro, Body & Peripheral Interventional Superspecialist. He has an experience of performing over thousands of endovascular procedures to his credit.",
    //     "Scope Of Services: ",
    //     "He is highly proficient in performing Interventional Procedures centered around women-related vascular problems, and male and female infertility apart from other Neuro & Peripheral Vascular Interventions.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Prasad Mahadevan",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Prasad Mahadevan is a highly skilled gynecologist with a passion for women's health. With over three decades of experience, he is known for his expertise in obstetrics and Gynecology. Dr. Mahadevan's commitment to patient well-being, along with his involvement in various medical associations, highlights his dedication to women's healthcare.",
    //   ],
    // },
  ],
};

export let ourInfrastructure = {
  heading: "Our Infrastructure. Our Strength.",
  description:
    "OMA's grade-A operating rooms are pristine and safe thanks to our air circulation technology",
  content: [
    {
      src: OperationTheatre,
      title: "Operating Theater",
      description:
        "Exceptional air circulation technology, stainless steel walls & many more such features provide absolute safety & hygiene for patients.",
    },
    {
      src: OperationTheatre2,
      title: "Operating Theater",
      description:
        "Exceptional air circulation technology, stainless steel walls & many more such features provide absolute safety & hygiene for patients.",
    },
    // {
    //   src: InfraImg1,
    //   title: "Collection center",
    //   description:
    //     "OMA acts as a one-stop-solution be offering sample collection facility at its premises.",
    // },
    {
      src: Neonatalogy,
      title: "NEONATALOGY DEPARTMENT",
      description:
        "Neonatalogy department is staffed by a team of dedicated specialists, who provide specialized care for newborns, ensuring their health and well-being",
    },
    {
      src: Fertility_Ivf1,
      title: "Fertility & IVF Center",
      description:
        "Our IVF center offers comprehensive fertility treatments and personalized care, helping couples realize their dreams of a complete family.",
    },
    {
      src: Fertility_Ivf2,
      title: "Fertility & IVF Center",
      description:
        "Our IVF center offers comprehensive fertility treatments and personalized care, helping couples realize their dreams of a complete family.",
    },
    {
      src: Rooms_Accomodation1,
      title: "Rooms and Accommodations",
      description:
        "From luxurious suites to comfortable general wards, we offer a range of thoughtfully designed rooms to cater to the different needs of our women patients and their families.",
    },
    {
      src: Rooms_Accomodations2,
      title: "Rooms and Accommodations",
      description:
        "From luxurious suites to comfortable general wards, we offer a range of thoughtfully designed rooms to cater to the different needs of our women patients and their families.",
    },
    {
      src: Reception1,
      title: "Reception Area",
      description:
        " As you step into Oma, the modern infrastructure and warm greetings embrace you, ensuring a comforting start to your journey.",
    },
    {
      src: Reception2,
      title: "Reception Area",
      description:
        " As you step into Oma, the modern infrastructure and warm greetings embrace you, ensuring a comforting start to your journey.",
    },
    {
      src: UsgRoom1,
      title: "USG Rooms",
      description:
        "We provide comprehensive ultrasonography services with highly experienced doctors, ensuring quality, confidentiality, and patient satisfaction.",
    },
    {
      src: UsgRoom2,
      title: "USG Rooms",
      description:
        "We provide comprehensive ultrasonography services with highly experienced doctors, ensuring quality, confidentiality, and patient satisfaction.",
    },
    {
      src: StaffTraining1,
      title: "Staff Training",
      description:
        "Dr. Tanuja's training workshops for hospital staff cover essential protocols, fostering excellence and proficiency in facility operation.",
    },
    {
      src: StaffTraining2,
      title: "Staff Training",
      description:
        "Dr. Tanuja's training workshops for hospital staff cover essential protocols, fostering excellence and proficiency in facility operation.",
    },
    {
      src: AntenatalExercise,
      title: "Antenatal Exercises",
      description:
        "During the 9-month antenatal care (ANC) period, OMA offers tailored exercises,nutrition guidance, and support for each trimester promoting overall well-being of the mothers-to-be.",
    },
    {
      src: ConsultingRoom1,
      title: "Consulting Room",
      description:
        "Our doctors are on a mission to support new mothers and babies with personalized care, while ensuring confidentiality and satisfaction.",
    },
    {
      src: ConsultingRoom2,
      title: "Consulting Room",
      description:
        "Our doctors are on a mission to support new mothers and babies with personalized care, while ensuring confidentiality and satisfaction.",
    },
  ],
};

export let successStories = {
  id: "aboutus-success-stories-page",
  heading: "Success Stories",
  subHeading:
    "At OMA we strive to make each patient's visit a positive experience because we value having happy patients above everything else.",
  content: [
    {
      key: 1,
      title: "Patient",
      description:
        "We couldn't be happier with the services and care we received at OMA. The doctors and staff work together to make each visit a rewarding one. We chose OMA for both our first and second child. Dr. Tanuja Uchil was our doctor during both of our pregnancies. She is very skilled, and with her help, we had a great and seamless experience.",
      name: "Mrs. Sarita Mishra",
    },
    {
      key: 2,
      title: "Doctor",
      description:
        "I recently had the privilege to attend a seminar led by Dr. Tanuja Uchil. I was swept away by her knowledge, skills and confidence. With patient care at her core and years of experience, she is committed to dedicating her life to women’s healthcare and I feel there's no better doctor than her to do the same.",
      name: "Dr. Anjali Thakur",
    },
    {
      key: 3,
      title: "Nurse",
      description:
        "I have been associated with OMA for several years now and the experience has been nothing less than amazing. Under Dr. Tanuja's guidance, I have had the opportunity to learn and explore a lot when it comes to women's healthcare. The nursing staff shares a personal relationship with every patient which is a part of our nature that helps us help them reach their wellness goal faster together.",
      name: "Head Nurse - Kirti Shah",
    },
    // {
    //   key: 4,
    //   title: "Fellow Doctor",
    //   description:
    //     "This testimonial will be from a fellow Doctor who had attended Dr. Tanuja's seminar and was very appreciative of her knowledge and skills. How good overall experience it was for him. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   name: "Dr. Anjali Thakur",
    // },
  ],
};

export let awardData = {
  heading: "Awards & Accolades",
  description:
    "When we provide exceptional service, we are rewarded with accolades and trophies. At OMA, we are committed to providing exceptional medical services throughout all of our healthcare initiatives. And when we are awarded for our commitments, it feels like a cherry on top.",
  images: [
    {
      id: 1,
      img: award1,
      alt: "award1",
    },
    {
      id: 2,
      img: award2,
      alt: "award2",
    },
    {
      id: 3,
      img: award3,
      alt: "award3",
    },
    {
      id: 4,
      img: award4,
      alt: "award4",
    },
  ],
};
