import React from "react";
import "./index.scss";
import { Row, Col } from "reactstrap";
import {
  Logo,
  InstragramLogo,
  FacebookLogo,
  TwitterLogo,
} from "../../assets/img/Svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <Row>
          <Col lg={4}>
            <div className="footer-column">
              <div className="footer-column1">
                <div className="footer-logo">
                  <Link to="/">
                    <h1 className="footer-header"> OMA Women’s Hospital</h1>
                  </Link>
                </div>
                <p className="footer-content">
                  OMA is a women’s multi-speciality hospital led by a highly
                  renowned team of doctors and highly experienced professionals.
                  Built around you, OMA follows international quality standards
                  and new-age medical methodologies using modern technologies
                  and equipment. OMA is dedicated to providing care that is
                  second to none in terms of quality, safety, and the overall
                  experience of patients.
                </p>
              </div>
              <div className="footer-column2">
                <h1 className="footer-header"> Contact us</h1>
                <ul className="footer-ul">
                  <li className="footer-li">info@omahospital.com</li>
                  <li className="footer-li">+917208973301</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="footer-column">
              <div className="footer-column1">
                <h1 className="footer-header">Address</h1>
                <p className="footer-content">
                  V N Purav Marg, Off Sion Trombay Road Behind CMC bank,
                  Landmark: Near Diamond Garden & Opposite To K Star Mall,
                  Chembur- East Maharashtra, Mumbai
                </p>
              </div>
              <div className="footer-column2">
                <h1 className="footer-header">Others</h1>
                <ul className="footer-ul">
                  <li className="footer-li">Terms & Condition</li>
                  <li className="footer-li">Privacy Policy</li>
                  <li className="footer-li">Company Policies</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="footer-column">
              <div className="footer-column1">
                <h1 className="footer-header">Sitemap</h1>
                <ul className="footer-ul">
                  <li className="footer-li">
                    <Link to="/aboutus">About OMA</Link>
                  </li>
                  <li className="footer-li">
                    <Link to="/Maternity">Services/Offerings</Link>
                  </li>
                  {/* <li className="footer-li">
                    <Link to="/LabTest">@ Home Services</Link>
                  </li>
                  <li className="footer-li">
                    <Link to="/community">OMA Community</Link>
                  </li> */}
                  <li className="footer-li">
                    <Link to="/resources">Resources</Link>
                  </li>
                  <li className="footer-li">
                    <Link to="/connect">Lets Connect</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-column2 social-wrapper">
                <Link to="/">
                  <span className="social-icon">
                    <InstragramLogo />
                  </span>
                </Link>
                <Link to="/">
                  <span className="social-icon">
                    <FacebookLogo />
                  </span>
                </Link>
                <Link to="/">
                  <span className="social-icon">
                    <TwitterLogo />
                  </span>
                </Link>
              </div>
            </div>
          </Col>
        </Row>

        <div className="footer-wrapper">
          <div className="logo-resp">
            <Link to="/">
              <Logo />
            </Link>
            <p className="footer-para">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
              dignissimos officiis eveniet ut sit explicabo, odio fugiat rerum
              facere a aut optio dolorem debitis praesentium cum, quibusdam
              atque reprehenderit assumenda.
            </p>
          </div>
          <hr />

          <div className="service-resp">
            <div className="service-left">
              <h1 className="template-heading footer-headings">Sitemap</h1>
              <Link to="/aboutus">
                <p className="footer-para">About OMA</p>
              </Link>
              <Link to="/aboutus">
                <p className="footer-para">Service/Offerings</p>
              </Link>
              <Link to="/aboutus">
                <p className="footer-para">@ Home Services</p>
              </Link>
              <Link to="/aboutus">
                <p className="footer-para">OMA Community</p>
              </Link>
              <Link to="/aboutus">
                <p className="footer-para">Resources</p>
              </Link>
              <Link to="/aboutus">
                <p className="footer-para">Lets Connect</p>
              </Link>
            </div>
            <div className="service-right">
              <h1 className="template-heading footer-headings">Others</h1>
              <Link to="/aboutus">
                <p className="footer-para">Terms & Conditions</p>
              </Link>
              <Link to="/aboutus">
                <p className="footer-para">Privacy Policy</p>
              </Link>
              <Link to="/aboutus">
                <p className="footer-para">Company Policies</p>
              </Link>
            </div>
          </div>
          <hr />
          <div className="address-resp">
            <h1 className="template-heading footer-headings">Address</h1>
            <p className="footer-para">
              V N Purav Marg, Off Sion Trombay Road Behind CMC bank, Landmark:
              Near Diamond Garden & Opposite To K Star Mall, Chembur-East
              Maharashtra, Mumbai
            </p>
          </div>
          <hr />
          <div className="contact-resp">
            <h1 className="template-heading footer-headings">Contact Us</h1>
            <p className="footer-para">info@omahospital.com</p>
            <p className="footer-para">+91 9823998654</p>
          </div>
          <div className="social-resp">
            <span>
              <Link to="/">
                <span>
                  <InstragramLogo />
                </span>
              </Link>
              <Link to="/">
                <span>
                  <FacebookLogo />
                </span>
              </Link>
              <Link to="/">
                <span>
                  <TwitterLogo />
                </span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
