import React from "react";
import AboutOffering from "../../component/AboutOffering";
import BlogPackage from "../../component/BlogPackage";
import BgCoverImg from "../../component/BgCoverImg";
import OurEmpanelled from "../../component/OurEmpanelled";
import MediaVideo from "../../component/MediaVideo";
import {
  AboutOfferingData,
  CommonBackgroundImage,
  OurEmpanelledData,
  PackageData,
  mediaData,
} from "./data";
import Footer from "../../common/Footer";

const Resources = () => {
  return (
    <>
      <AboutOffering data={AboutOfferingData} />
      <BlogPackage data={PackageData} />
      <BgCoverImg data={CommonBackgroundImage} />
      <OurEmpanelled data={OurEmpanelledData} />
      <MediaVideo data={mediaData} />
      <Footer />
    </>
  );
};
export default Resources;
