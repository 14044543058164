import aboutMaternity from "../../assets/img/AboutMaternity1.png";
import commonDesign from "../../assets/img/commonDesign.png";
import coverImg from "../../assets/img/CoverImg11.png";
import TanujaUchil from "../../assets/img/TanujaUchil.png";
// import HarshMehta from "../../assets/img/HarshMehta.png";
// import AnitaSharma from "../../assets/img/AnitaSharma.png";
// import ComponentImg from "../../assets/img/maternity-circle.png";
import ComponentImg from "../../assets/img/maternity-circle1.png";

import MaternityImg1 from "../../assets/img/MaternityAmbience1.png";
import MaternityImg2 from "../../assets/img/MaternityAmbience2.png";
import MaternityImg3 from "../../assets/img/MaternityAmbience3.png";
import DefaultAvatar from "../../assets/img/DefaultAvatar.jpeg";

export let AboutOfferingData = {
  mainHeading: "Maternity",
  subHeadeing: "Treasure Every Part of You",
  description: [
    "Women are special not only because of the important roles they play in our lives but also because of their anatomy which is blessed to create life. The blessing also comes with certain complexities which need undivided care and attention. At OMA, our world-class Maternity Services are designed in a way to cater to every woman to support their needs and help them create the memorable milestone they have set out to achieve.",
    "The staff at OMA have received extensive training and is widely regarded as among the best available. OMA has more in common with a hotel than a hospital, thanks to the many amenities and luxuries available here. The state-of-the-art facilities, new-age technologies, highly skilled professionals with the combination of unmatched care and comfort make OMA the most preferred Women's Healthcare destination in all of Mumbai.",
  ],
  img: aboutMaternity,
};

export let MeetDoctorsData = {
  mainHeading: "Meet the Doctors",
  subHeading: "",
  description: [
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields.",
  ],
  commonDesign: commonDesign,
  ifquestion: true,
  question: "Got a question? Leave no room for doubt.",
  button: "Contact Us",
  // doctors: [
  //   {
  //     img: TanujaUchil,
  //     name: "Dr. Tanuja Uchil",
  //     about:
  //       "MBBS, DGO, MD(OBG) Senior Consultant Obstetrician & Gynaecologist",
  //   },
  //   {
  //     img: HarshMehta,
  //     name: "Dr. Prasad Mahadevan",
  //     about:
  //       "MBBS, MD, DGO, MRCOG(UK), FRCOG Consultant Obstetrician and Gynaecologist",
  //   },
  //   {
  //     img: AnitaSharma,
  //     name: "Dr. Keshav Pai",
  //     about:
  //       "MBBS, DGO, DNB - Obstetrics & Gynecology, MNAMS - Obstetrician and Gynecologist",
  //   },
  //   // {
  //   //   img: AnitaSharma,
  //   //   name: "Dr. Anita Sharma",
  //   //   about:
  //   //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   // },
  //   // {
  //   //   img: AnitaSharma,
  //   //   name: "Dr. Anita Sharma",
  //   //   about:
  //   //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   // },
  // ],
  doctors: [
    {
      img: TanujaUchil,
      name: "Dr. Tanuja Uchil",
      // role: "Role: Radiologist and Ultrasonologist",
      experience: [
        "MBBS, DGO, DNB - Obstetrics & Gynecology, MNAMS - Obstetrician and Gynecologist",
      ],
      description: [
        "Dr. Tanuja Uchil serves as a full-time consultant in Obstetrics and Gynecology at OMA Hospital. Dr. Uchil's journey in the field of medicine is characterized by excellence, expertise, and a genuine commitment to women's health.",
        "Educational Background: Dr. Uchil's academic journey commenced with her MBBS degree from Seth G S Medical College & KEM Hospital in 1997 followed by her role as a resident medical officer specializing in Obstetrics and Gynecology at the Nowrosjee Wadia Maternity Hospital and pursuing the degree of MD in Obstetrics & Gynecology from Seth G S Medical College & KEM Hospital, University of Mumbai.",
        "",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Prasad Mahadevan",
      role: "Gynecologist",
      experience: [
        "MBBS (1990) - Rajiv Gandhi University of Health Sciences, Bangalore",
        "DGO (1992) -  Kasturba Medical College",
        "MD - Obstetrics & Gynaecology (1993) - Kasturba Medical College in 1993",
      ],
      description: [
        "Dr. Prasad Mahadevan is a highly skilled gynecologist with a passion for women's health. With over three decades of experience, he is known for his expertise in obstetrics and Gynecology. Dr. Mahadevan's commitment to patient well-being, along with his involvement in various medical associations, highlights his dedication to women's healthcare.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Keshav Pai",
      role: "Gynecologist and Laparoscopic Surgeon",
      experience: ["M.B.B.S, MD / MS, DNB, D.G.O"],
      description: [
        "Dr. Keshav Pai is a distinguished gynecologist and laparoscopic surgeon with a strong academic background. He completed his post-graduation at LTMMC and LTMGH and received specialized training in laparoscopy in Belgium. Dr. Pai's special interests include fertility-enhancing surgery and minimally invasive procedures like laparoscopic hysterectomy. His contributions to research, medical publications, and his dedication to patient care make him a sought-after gynecologist.",
      ],
    },

    // {
    //   img: AnitaSharma,
    //   name: "Dr. Neeta Nathani",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Neeta Nathani has been serving the healthcare needs of children in Mumbai for over three decades. She runs Nathani Clinic in Chembur and serves as an attending pediatrician at Surya Hospital, Kolekar Hospital, and Zen Hospital in Chembur. Dr. Nathani is not only a trusted pediatrician but also an educator who conducts teaching sessions for family practitioners and delivers CME lectures at various conferences. Her commitment to pediatric care and medical education is commendable.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Amit Sahu",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Amit Sahu is a fellowship-trained Interventional Radiologist having an experience of over 12 years. He has undergone training in Vascular and interventional Radiology and Interventional Neuroradiology from Seth GS Medical College & King Edward Memorial (KEM) Hospital in Mumbai and the Johns Hopkins Hospital in Baltimore, USA.",
    //     "He has extensive experience in managing and treating patients related to Neuro, Body & Peripheral Interventional Superspecialist. He has an experience of performing over thousands of endovascular procedures to his credit.",
    //     "Scope Of Services: ",
    //     "He is highly proficient in performing Interventional Procedures centered around women-related vascular problems, and male and female infertility apart from other Neuro & Peripheral Vascular Interventions.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Prasad Mahadevan",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Prasad Mahadevan is a highly skilled gynecologist with a passion for women's health. With over three decades of experience, he is known for his expertise in obstetrics and Gynecology. Dr. Mahadevan's commitment to patient well-being, along with his involvement in various medical associations, highlights his dedication to women's healthcare.",
    //   ],
    // },
  ],
};

export let CommonBackgroundImage = {
  img: coverImg,
};

export let AmbienceData = {
  heading: "Nurturing the Mother in You",
  description:
    "We create a safe environment for mothers to feel at home with us at OMA. We have varied rooms for expectant mothers to choose from and they all have high-end technology, pristine environment, and caring staff in common.",
};

export const AmbienceImages = [
  {
    src: MaternityImg1,
  },
  {
    src: MaternityImg2,
  },
  {
    src: MaternityImg3,
  },
];

export let maternityCircleData = {
  mainHeading: "WHAT MAKES  OMA UNIQUE AMONG MUMBAI’S MATERNITY HOSPITALS?",
  circleImg: ComponentImg,
  circleData: [
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      headingClass: "radiology-header",
      headingText: "Personalized Birth Plans: ",
      text: " Tailored to your preferences, from natural births to C-sections.",
    },
    {
      // class: "dot-container left-alignT",
      // textSpan: "circle-text",
      // headingClass: "radiology-header",
      // headingText: "Education & Support:",
      // text: " Benefit from childbirth classes, breastfeeding assistance, and postpartum care.",
    },
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",

      // headingClass: "radiology-header",
      // headingText: "Personalized Birth Plans: ",
      // text: " Tailored to your preferences, from natural births to C-sections.",
      // text: "Visit us to discuss your unique maternity care needs.",

      headingClass: "radiology-header",
      headingText: "Education & Support:",
      text: " Benefit from childbirth classes, breastfeeding assistance, and postpartum care.",
    },
    {},
    {},
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      headingClass: "radiology-header",
      headingText: "Stellar Reputation: ",
      text: "Read positive reviews from satisfied parents who trust OMA Hospital.",
    },
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      headingClass: "radiology-header",
      headingText: "Family-Centered Care: ",
      text: " We prioritize involving your loved ones throughout the birthing process.",
    },
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      headingClass: "radiology-header",
      headingText: "State-of-the-Art Facilities:",
      text: "Enjoy modern delivery rooms and comfortable recovery areas.",
    },
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side top-dot-left",
      headingClass: "radiology-header",
      headingText: "Experienced Team: ",
      text: "Our skilled healthcare professionals specialize in maternity care, ensuring top-quality services.",
    },
    {},
    {},
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text top-dot-right",
      headingClass: "radiology-header",
      headingText: "Comprehensive Prenatal Care: ",
      text: " We offer complete prenatal services, including check-ups and screenings.",
    },
    {},
    {
      // class: "dot-container left-alignT",
      // textSpan: "circle-text",
      // headingClass: "radiology-header",
      // headingText: "Personalized Birth Plans: ",
      // text: " Tailored to your preferences, from natural births to C-sections.",
    },
  ],

  responsiveCircleData: [
    {
      positionClass: "bottom",
      text: "Facility of MR-guided\n Focused Ultrasound",
    },
    {
      positionClass: "bottom",
      text: "Perinatal counseling classes for\n cases of high risk pregnancy",
    },
    {
      positionClass: "bottom",
      text: "Diet and nutrition counseling sessions\n conducted for pregnant women",
    },
    {
      positionClass: "bottom",
      text: "Endoscopic interventional\n procedures available",
    },
    {
      positionClass: "top",
      text: "Post-natal and antenatal\n classes for pregnant women",
    },
    {
      positionClass: "top",
      text: "Specialized operation theaters\n for C-section delivery",
    },
    {
      positionClass: "top",
      text: "Hygiene and healthy habits\n during and after pregnancy",
    },
    {
      positionClass: "top",
      text: "Best accommodation\n patients and their families",
    },
  ],
  contentDesc: "Visit us to discuss your unique maternity care needs.",
};
