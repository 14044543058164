// import AboutFertility from "../../assets/img/AboutFertility.png";
import AboutFertility from "../../assets/img/AboutFertility1.png";
// import CoverImg5 from "../../assets/img/CoverImg5.png";
import CoverImg5 from "../../assets/img/CoverImg51.png";
import commonDesign from "../../assets/img/commonDesign.png";
import TanujaUchil from "../../assets/img/TanujaUchil.png";
// import HarshMehta from "../../assets/img/HarshMehta.png";
// import AnitaSharma from "../../assets/img/AnitaSharma.png";
import ComponentImg from "../../assets/img/fertility-circle.png";
import FertilityImg1 from "../../assets/img/FertilityBanner1.jpg";
import FertilityImg2 from "../../assets/img/FertilityBanner2.jpg";
import FertilityImg3 from "../../assets/img/FertilityBanner3.jpg";

export let AboutOfferingData = {
  mainHeading: "ONCO GYNECOLOGY",
  subHeadeing:
    "EXCEPTIONAL TREATMENT. EXCEPTIONAL RESULTS. EXCEPTIONAL DESTINATION.",
  description: [
    "The increase in cancer among women globally is a concerning trend that can't be ignored. Onco-gynecology, focusing on cancers in the female reproductive system, is more important than ever. It's not just about numbers; it's about real women, from all walks of life, facing this growing challenge. We're seeing more cases, and it's pushing us to not only find better treatments but also to emphasize early detection and prevention. At Oma, treatment plans are individualized based on the type and stage of cancer, as well as the patient’s overall health and preferences.",
  ],
  img: AboutFertility,
};

export let oncoGynecologyCircleData = {
  mainHeading: "OUR OFFERINGS",
  description: [
    "Our services encompass prevention, treatment, and holistic support for women battling gynecologic cancers.",
  ],

  circleImg: ComponentImg,
  circleData: [
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      text: "Targeted and Immunotherapy: Innovative therapies focusing on cancer cells and boosting the immune system.",
    },
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      text: "Palliative Care and Rehabilitation: Managing symptoms and aiding recovery post-treatment.",
    },
    {},
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      text: "Fertility Preservation and Genetic Counseling: Options for preserving fertility and genetic testing for those at high risk.",
    },
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      text: "Chemotherapy and Radiation Therapy: Customized chemotherapy and radiation treatments.",
    },
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      text: "Screening and Diagnosis: Advanced methods like Pap smears, HPV tests, and imaging for early cancer detection.",
    },
    {},
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      text: "Surgical Treatments: Minimally invasive surgeries, hysterectomies, and fertility-sparing procedures.",
    },
  ],
  responsiveCircleData: [
    {
      positionClass: "bottom",
      text: "Screening and Diagnosis: Advanced methods like Pap smears, HPV tests, and imaging for early cancer detection.",
    },
    {
      positionClass: "bottom",
      text: "Surgical Treatments: Minimally invasive surgeries, hysterectomies, and fertility-sparing procedures.",
    },
    {
      positionClass: "bottom",
      text: "Chemotherapy and Radiation Therapy: Customized chemotherapy and radiation treatments.",
    },
    {
      positionClass: "top",
      text: "Targeted and Immunotherapy: Innovative therapies focusing on cancer cells and boosting the immune system.",
    },
    {
      positionClass: "top",
      text: "Fertility Preservation and Genetic Counseling: Options for preserving fertility and genetic testing for those at high risk.",
    },
    {
      positionClass: "top",
      text: "Palliative Care and Rehabilitation: Managing symptoms and aiding recovery post-treatment.",
    },
  ],
};

export let OncoGynecologyData = {
  mainHeading: "OUR OFFERINGS",
  description: [
    "Our services encompass prevention, treatment, and holistic support for women battling gynecologic cancers.",
  ],
  services: [
    {
      key: 1,
      title: "Screening and Diagnosis:",
      service:
        "Advanced methods like Pap smears, HPV tests, and imaging for early cancer detection.",
    },
    {
      key: 2,
      title: "Surgical Treatments:",
      service:
        "Minimally invasive surgeries, hysterectomies, and fertility-sparing procedures.",
    },
    {
      key: 3,
      title: "Chemotherapy and Radiation Therapy:",
      service: "Customized chemotherapy and radiation treatments. ",
    },
    {
      key: 4,
      title: "Targeted and Immunotherapy:",
      service:
        "Innovative therapies focusing on cancer cells and boosting the immune system.",
    },
    {
      key: 5,
      title: "Fertility Preservation and Genetic Counseling:",
      service:
        "Options for preserving fertility and genetic testing for those at high risk.",
    },
    {
      key: 6,
      title: "Palliative Care and Rehabilitation:",
      service: "Managing symptoms and aiding recovery post-treatment.",
    },
    {
      key: 7,
      title: "Psychological Support and Nutritional Counseling:",
      service:
        "Mental health support, dietary advice, and support groups for patients and families.",
    },
  ],
};

export let CommonBackgroundImage = {
  img: CoverImg5,
};

export let knowMoreData = {
  mainHeading: "Know more about different types of Cancers",
  services: [
    {
      key: 1,
      title: "Cervical Cancer",
      service: `This cancer originates in the cervix, the lower part of the uterus. Treatment options may include surgery, radiation therapy, chemotherapy, and immunotherapy`,
    },
    {
      key: 2,
      title: "Ovarian Cancer",
      service:
        "Ovarian cancer develops in the ovaries. Treatment often involves surgery, chemotherapy, and targeted therapies.",
    },
    {
      key: 3,
      title: "Uterine Cancer",
      service: `Uterine cancer can affect the lining of the uterus (endometrial cancer) or the uterine muscles (sarcoma). Treatment may include surgery, radiation therapy, and chemotherapy.`,
    },
    {
      key: 4,
      title: "Vaginal Cancer",
      service:
        "This rare cancer affects the vaginal lining. Treatment options may include surgery, radiation therapy, and chemotherapy.",
    },
    {
      key: 5,
      title: "Vulvar Cancer",
      service:
        "Vulvar cancer develops on the external genitalia. Treatment may involve surgery, radiation therapy, and chemotherapy.",
    },
    {
      key: 6,
      title: "Gestational Trophoblastic Disease (GTD)",
      service:
        "GTD encompasses a group of rare tumors that develop from placental tissue during pregnancy. Treatment typically includes surgery and chemotherapy.",
    },
    {
      key: 7,
      title: "Fallopian Tube Cancer",
      service:
        "Cancer of the fallopian tubes is rare but can occur. Treatment options are similar to ovarian cancer and may involve surgery and chemotherapy.",
    },
    {
      key: 8,
      title: "Pre-cancerous Conditions",
      service:
        "Focus is on identifying and managing pre-cancerous conditions such as cervical dysplasia, which may progress to cervical cancer if left untreated.",
    },
  ],
};

export let MeetDoctorsData = {
  mainHeading: "Meet the Doctors",
  subHeading:
    "Our Specialists are only the Best and Most Experienced Doctors who make it all happen  and ensure that you enjoy the most beautiful experience of your life.",
  description: [
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields",
  ],
  commonDesign: commonDesign,
  ifquestion: true,
  question: "Got a question? Leave no room for doubt",
  button: "Contact Us",
  doctors: [
    {
      img: TanujaUchil,
      name: "Dr. Tanuja Uchil",
      // role: "Role: Radiologist and Ultrasonologist",
      experience: [
        "MBBS, DGO, DNB - Obstetrics & Gynecology, MNAMS - Obstetrician and Gynecologist",
      ],
      description: [
        "Dr. Tanuja Uchil serves as a full-time consultant in Obstetrics and Gynecology at OMA Hospital. Dr. Uchil's journey in the field of medicine is characterized by excellence, expertise, and a genuine commitment to women's health.",
        "Educational Background: Dr. Uchil's academic journey commenced with her MBBS degree from Seth G S Medical College & KEM Hospital in 1997 followed by her role as a resident medical officer specializing in Obstetrics and Gynecology at the Nowrosjee Wadia Maternity Hospital and pursuing the degree of MD in Obstetrics & Gynecology from Seth G S Medical College & KEM Hospital, University of Mumbai.",
        "",
      ],
    },
  ],
};

export let AmbienceData = {
  heading: "Bring your world to you",
  description:
    "OMA was founded on the idea that giving birth to a child is a celebration. We know how hard it can be when you're having trouble getting pregnant. So, our team of specialists double up as guides and people you can confide in. Not to mention our advanced technological capabilities that are bound to help you achieve your memorable milestone.",
};

export let AmbienceImages = [
  {
    src: FertilityImg1,
  },
  {
    src: FertilityImg2,
  },
  {
    src: FertilityImg3,
  },
];
