import React, { useState } from "react";
import axios from "axios";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./style.scss";
import PopUpModal from "../PopUpModal";
import sorryIcon from "../../assets/img/sorryIcon.png";
import popupiconTick from "../../assets/img/popupiconTick.png";

const FertilityTreatmentTabs = () => {
  const [change, setChange] = useState(true);
  let [maleFertility, setmaleFertility] = useState({
    name_male: "",
    email_male: "",
  });
  let [femaleFertility, setfemaleFertility] = useState({
    name_female: "",
    email_female: "",
  });
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  let alertData = {
    icon: sorryIcon,
    expression: "SORRY!",
    message:
      "The form filled is incorrect/incomplete. Kindly check and fill in the form.",
    subexpression: "",
    submessage: "",
    footnote: "Please check all the details before procedding ahead.",
  };

  let popupdata = {
    icon: popupiconTick,
    expression: "THANK YOU!",
    message:
      "Your Query has been successfully submitted.Kindly expect our reply in next 24hrs",
    subexpression: "",
    submessage: "",
    footnote:
      "In case of delayed response contact we us on aour landline. We will try and resolve your query at earliest.",
  };

  // const clickableTabs1 = () => {
  //   setTab1((prev) => !prev);
  // };
  const sendEmailforFemale = (e) => {
    e.preventDefault();
    let body = {
      subject: "Fertitlity Treatment for Female",
      para: "Form for Female",
      mailData: [
        { name_female: { label: "Name", value: femaleFertility.name_female } },
        {
          email_female: { label: "Email", value: femaleFertility.email_female },
        },
      ],
    };
    if (
      (femaleFertility.name_female === null ||
        femaleFertility.name_female === "",
      femaleFertility.email_female === null ||
        femaleFertility.email_female === "")
    ) {
      setAlert(true);
    } else {
      axios
        .post(process.env.REACT_APP_API_CALL, body)
        .then((res) => {
          setShow(true);
        })
        .catch(() => {
          console.log("not sent");
        });
    }
  };

  const sendEmailforMale = (e) => {
    e.preventDefault();
    let body = {
      subject: "Fertitlity Treatment for Male",
      para: "Form for Male",
      mailData: [
        { name_male: { label: "Name", value: maleFertility.name_male } },
        {
          email_male: { label: "Email", value: maleFertility.email_male },
        },
      ],
    };
    if (
      (maleFertility.name_male === null || maleFertility.name_male === "",
      maleFertility.email_male === null || maleFertility.email_male === "")
    ) {
      setAlert(true);
    } else {
      axios
        .post(process.env.REACT_APP_API_CALL, body)
        .then((res) => {
          setShow(true);
        })
        .catch(() => {
          console.log("not sent");
        });
    }
  };
  return (
    <section>
      <div className="fertility-form-container">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={change ? `active` : `inactive`}
              onClick={() => setChange(true)}
            >
              <h6 className="fertility-form-tab-header">
                SEEKING FEMALE INFERTILITY TREATMENT
              </h6>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              // className="Linkactive"
              className={change ? `inactive` : `active`}
              onClick={() => setChange(false)}
            >
              <h6 className="fertility-form-tab-header">
                SEEKING MALE INFERTILITY TREATMENT
              </h6>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="fertility-tab-box" activeTab="1">
          {change === true ? (
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <Form
                    className="form-container"
                    onSubmit={sendEmailforFemale}
                  >
                    <div className="form-inputs">
                      <FormGroup floating>
                        <Input
                          name="name_male"
                          placeholder="Name"
                          type="text"
                          className="form-inputs-fields"
                          onChange={(e) => {
                            setfemaleFertility((prev) => ({
                              ...prev,
                              name_female: e.target.value,
                            }));
                          }}
                        />
                        <Label for="placeholder">Name</Label>
                      </FormGroup>{" "}
                      <FormGroup floating>
                        <Input
                          name="email_male"
                          placeholder="Email"
                          type="email"
                          className="form-inputs-fields"
                          onChange={(e) => {
                            setfemaleFertility((prev) => ({
                              ...prev,
                              email_female: e.target.value,
                            }));
                          }}
                        />
                        <Label for="placeholder">Email Id</Label>
                      </FormGroup>{" "}
                    </div>
                    <div className="form-submit-button">
                      {/* <Button className="form-submit-btn">Submit</Button> */}
                      <button className="form-submit-btn" type="submit">
                        Submit
                      </button>
                    </div>
                    <PopUpModal
                      show={alert}
                      setShow={setAlert}
                      data={alertData}
                    />
                    <PopUpModal
                      show={show}
                      setShow={setShow}
                      data={popupdata}
                    />
                  </Form>
                </Col>
              </Row>
            </TabPane>
          ) : (
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <Form className="form-container" onSubmit={sendEmailforMale}>
                    <div className="form-inputs">
                      <FormGroup floating>
                        <Input
                          name="name_female"
                          placeholder="Name"
                          type="text"
                          className="form-inputs-fields"
                          onChange={(e) => {
                            setmaleFertility((prev) => ({
                              ...prev,
                              name_male: e.target.value,
                            }));
                          }}
                        />
                        <Label for="placeholder">Name</Label>
                      </FormGroup>{" "}
                      <FormGroup floating>
                        <Input
                          name="email_female"
                          placeholder="Email"
                          type="email"
                          className="form-inputs-fields"
                          onChange={(e) => {
                            setmaleFertility((prev) => ({
                              ...prev,
                              email_male: e.target.value,
                            }));
                          }}
                        />
                        <Label for="placeholder">Email Id</Label>
                      </FormGroup>{" "}
                    </div>
                    <div className="form-submit-button">
                      {/* <Button className="form-submit-btn">Submit</Button> */}
                      <button className="form-submit-btn" type="submit">
                        Submit
                      </button>
                    </div>{" "}
                    <PopUpModal
                      show={alert}
                      setShow={setAlert}
                      data={alertData}
                    />
                    <PopUpModal
                      show={show}
                      setShow={setShow}
                      data={popupdata}
                    />
                  </Form>
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </div>
    </section>
  );
};

export default FertilityTreatmentTabs;
