import React, { useState } from "react";
import "./style.scss";

const OurEmpanelled = ({ data }) => {
  const [change, setChange] = useState(false);

  return (
    <section className="section-wrapper bottom-common-effect">
      <div className="container">
        <div className="page-titles">{data.Header}</div>

        <div className="empanelled-grid">
          <div className="insurance-companies">
            {data.Content.map((d) => {
              return (
                <div className="insurance-card">
                  <div className="insurance-card-heading">{d.title}</div>
                  <div className="insurance-card-body">
                    <ul>
                      {d.data.map((subdata) => {
                        return <li>{subdata}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="insurance-companies-mobile">
            <div className="insurance-card">
              <div
                className={
                  change
                    ? "insurance-card-heading  inactive"
                    : "insurance-card-heading"
                }
                onClick={() => setChange(false)}
              >
                {data.Content[0].title}
              </div>
              <div
                className={
                  change
                    ? "insurance-card-heading"
                    : "insurance-card-heading inactive"
                }
                onClick={() => setChange(true)}
              >
                {data.Content[1].title}
              </div>
            </div>

            <div className="insurance-card-body-mobile">
              {change ? (
                <ul>
                  {data.Content[0].data.map((subdata) => {
                    return <li>{subdata}</li>;
                  })}
                </ul>
              ) : (
                <ul>
                  {data.Content[1].data.map((subdata) => {
                    return <li>{subdata}</li>;
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurEmpanelled;
