import React from "react";
import omaMaternity from "../../assets/img/OMA_Maternity.png";
import Radiology from "../../assets/img/OMA_Radiology.png";
import Ivf from "../../assets/img/OMA_IVF.png";
import Nicu from "../../assets/img/OMA_NICU.png";
import Gynecology from "../../assets/img/OMA_Gynecology.png";
import Aesthetic from "../../assets/img/OMA_Aesthetic.png";
import Fertility from "../../assets/img/OMA_Fertility.png";
import Aboutyou from "../../assets/img/About_us.png";
import "./style.scss";

const AboutYou = () => {
  return (
    <section className="section-wrapper about-you-section">
      <div className="container">
        <div className="content-wrapper">
          <div className="page-titles page-titles-desktop">
            Your Women's Health Champion: We've Got You
          </div>
          <div className="page-titles page-titles-responsive">
            Your Women's Health
            <br />
            Champion: We've Got You
          </div>
          <div className="about-you-grid-container">
            <div className="col-one">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={omaMaternity} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">Maternity</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Fertility} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div
                  className="about-you-titles"
                  style={{ textAlign: "right" }}
                >
                  Onco-Gynecology
                </div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img
                    className="grid-img"
                    src={Radiology}
                    alt=""
                    style={{ width: "230px", height: "160px" }}
                  ></img>
                  <div className="circle-shadow" />
                </div>
                <div
                  className="about-you-titles"
                  style={{ textAlign: "right" }}
                >
                  Radiology & Diagnostics
                </div>
              </div>
            </div>
            <div className="col-two">
              <div className="flex fd-col">
                <div className="img-wrapper">
                  <img className="grid-img" src={Gynecology} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles center">Gynecology</div>
              </div>
              <div>
                <img className="grid-main-img" src={Aboutyou} alt=""></img>
                <div className="circle-shadow" />
              </div>
            </div>
            <div className="col-three">
              <div className="flex">
                <div className="img-wrapper">
                  <img
                    className="grid-img"
                    src={Ivf}
                    alt=""
                    style={{ width: "160px", height: "150px" }}
                  ></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">IVF & Fertility</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Aesthetic} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">
                  Aesthetic Regenerative &
                  <br />
                  Functional Gynecology
                </div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Nicu} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">Neonatology</div>
              </div>
            </div>
          </div>

          <div className="about-you-grid-container-responsive">
            <div className="row-one">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={omaMaternity} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">Maternity</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Ivf} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">IVF Center</div>
              </div>
            </div>
            <div className="row-two">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Nicu} alt="" />
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">NICU</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img nicu-img" src={Gynecology} alt="" />
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles center">Gynecology</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img rad-img" src={Radiology} alt="" />
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">Radiology</div>
              </div>
            </div>
            <div className="row-three">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Aesthetic} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">
                  Aesthetic
                  <br />
                  Gynecology
                </div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Fertility} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">
                  Fertility
                  <br />
                  Treatment
                </div>
              </div>
            </div>
            <img className="grid-main-img" src={Aboutyou} alt=""></img>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="content-wrapper">
          <div className="page-titles page-titles-desktop">
            Your Women's Health Champion: We've Got You
          </div>
          <div className="page-titles page-titles-responsive">
            Your Women's Health
            <br />
            Champion: We've Got You
          </div>
          <div className="about-you-grid-container">
            <div className="col-one">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={omaMaternity} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">Maternity</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Radiology} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">Radiology</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Ivf} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">IVF & Fertility</div>
              </div>
            </div>
            <div className="col-two">
              <div className="flex fd-col">
                <div className="img-wrapper">
                  <img className="grid-img" src={Nicu} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles center">NICU</div>
              </div>
              <div>
                <img className="grid-main-img" src={Aboutyou} alt=""></img>
                <div className="circle-shadow" />
              </div>
            </div>
            <div className="col-three">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Gynecology} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">Gynaecology</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Aesthetic} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">
                  Aesthetic & Regenerative Gynecology
                </div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Fertility} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">Fertility Treatments</div>
              </div>
            </div>
          </div>

          <div className="about-you-grid-container-responsive">
            <div className="row-one">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={omaMaternity} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">Maternity</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Ivf} alt=""></img>
                  <div className="circle-shadow" />
                </div>
                <div className="about-you-titles">IVF Center</div>
              </div>
            </div>
            <div className="row-two">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Gynecology} alt="" />
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">Gynecology</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img nicu-img" src={Nicu} alt="" />
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles center">NICU</div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img rad-img" src={Radiology} alt="" />
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">Radiology</div>
              </div>
            </div>
            <div className="row-three">
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Aesthetic} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">
                  Aesthetic
                  <br />
                  Gynecology
                </div>
              </div>
              <div className="flex">
                <div className="img-wrapper">
                  <img className="grid-img" src={Fertility} alt=""></img>
                  <div className="circle-shadow alignment" />
                </div>
                <div className="about-you-titles">
                  Fertility
                  <br />
                  Treatment
                </div>
              </div>
            </div>
            <img className="grid-main-img" src={Aboutyou} alt=""></img>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default AboutYou;
