import React, { useState } from "react";
import "./style.scss";
import { Row, Col } from "reactstrap";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";

const Ambience = ({ data, items, noofcolumns, maternity, gynac }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [slideToShow, setSlideToShow] = useState(3);
  const groups = [];
  const { innerWidth: width } = window;

  let Column = () => {
    if (noofcolumns === "1") return "12";
    else if (noofcolumns === "2") return "6";
    else return "4";
  };
  // const items = [
  //   {
  //     src: Image1,
  //   },
  //   {
  //     src: Image2,
  //   },
  //   {
  //     src: Image3,
  //   },
  //   {
  //     src: Image3,
  //   },
  //   {
  //     src: Image3,
  //   },
  //   {
  //     src: Image1,
  //   },
  //   {
  //     src: Image2,
  //   },
  //   {
  //     src: Image1,
  //   },
  //   {
  //     src: Image2,
  //   },
  // ];

  for (let i = 0; i < items.length; i += slideToShow) {
    groups.push(items.slice(i, i + slideToShow));
  }

  useEffect(() => {
    if (width <= 767) {
      setSlideToShow(1);
    } else if (width < 992) {
      setSlideToShow(2);
    }
  }, [width]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === groups.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? groups.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = groups.map((group, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <div className="facility-image-container">
          {group.map((item) => (
            <div className="facility-image">
              <div
                style={{
                  backgroundImage: `url(${item.src})`,
                }}
              />
            </div>
          ))}
        </div>
      </CarouselItem>
    );
  });
  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    dots: false,
  };
  return (
    <>
      <section className="section-wrapper ambience-section">
        <div className="container">
          <div className="content-wrapper">
            <div className="page-titles">{data.heading}</div>
            {/* <Slider
              className="slider-ambience"
              dots={true}
              slidesToShow={3}
              slidesToScroll={2}
              autoplaySpeed={3000}
            >
              {items.map((e, index) => {
                return (
                  <div className="facility-image-container">
                    <div className="facility-image">
                      <div
                        style={{
                          backgroundImage: `url(${e.src})`,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </Slider> */}
            {/* <div className="facility-image-container">
              <div className="facility-image">
                <div
                  style={{
                    backgroundImage: `url(${Image2})`,
                  }}
                />
              </div>
              <div className="facility-image">
                <div
                  style={{
                    backgroundImage: `url(${Image3})`,
                  }}
                />
              </div>
              <div className="facility-image">
                <div
                  style={{
                    backgroundImage: `url(${Image1})`,
                  }}
                />
              </div>
            </div> */}
            <div className="ambience-carousel-container">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                {/* <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                /> */}
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                  className="opac"
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                  className="opac"
                />
              </Carousel>
            </div>
            {maternity && <p className="page-content">{data.description}</p>}
            {gynac && (
              <div className="content-wrapper" style={{ marginTop: "30px" }}>
                {/* {data.mainHeading && (
                  <div className="page-titles">{data?.mainHeading}</div>
                )} */}
                <div className="subheaders">
                  {data.subHeading1 && (
                    <div className="subheading-paras">{data?.subHeading1}</div>
                  )}
                </div>
                <div className="our-services-data-gynac">
                  <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                    {data?.services1.map((itm) => {
                      return (
                        <Col md={Column()} sm="12" xs="12" key={itm?.key}>
                          <div className="our-services-wrapper-gynac">
                            <div className="small-circle-wrapper"></div>
                            <div className="our-services-desc-gynac">
                              <div className="our-services-description-gynac">
                                {itm?.service}
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>

                <div className="subheaders">
                  {data.subHeading2 && (
                    <div className="subheading-paras">{data?.subHeading2}</div>
                  )}

                  <div className="our-services-data-gynac">
                    <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
                      {data?.services2.map((itm) => {
                        return (
                          <Col md={Column()} sm="12" xs="12" key={itm?.key}>
                            <div className="our-services-wrapper-gynac">
                              <div className="small-circle-wrapper"></div>
                              <div className="our-services-desc-gynac">
                                <div className="our-services-description-gynac">
                                  {itm?.service}
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ambience;
