import React from "react";
import "./style.scss";
import "../../assets/scss/_variable.scss";
import { Row, Col } from "reactstrap";
import { services } from "../../pages/AestheticGynecology/data";

const OurServices = ({ noofcolumns, data, pinkBg }) => {
  let Column = () => {
    if (noofcolumns === "1") return "12";
    else if (noofcolumns === "2") return "6";
    else return "4";
  };

  return (
    <section
      className={`section-wrapper bottom-common-effect about-detail-section ${
        pinkBg ? "pink-bg" : ""
      }`}
    >
      <div className="container">
        <div className="content-wrapper">
          {data.mainHeading && (
            <div className="page-titles">{data?.mainHeading}</div>
          )}

          {data.description && (
            <p className="template-content">{data.description}</p>
          )}

          <div className="subheaders">
            {data.subHeading1 && (
              <div className="subheading-paras">{data?.subHeading1}</div>
            )}
            {data.subHeading2 && (
              <div className="subheading-paras bold">{data?.subHeading2}</div>
            )}
          </div>
          <div className="our-services-data">
            <Row>
              {data?.services.map((itm) => {
                return (
                  <Col md={Column()} sm="12" xs="12" key={itm?.key}>
                    <div className="our-services-wrapper">
                      <div className="circle-wrapper"></div>
                      <div className="our-services-desc">
                        {itm.title && (
                          <div className="our-services-description bold">
                            {itm?.title}
                          </div>
                        )}
                        <div className="our-services-description">
                          {itm?.service}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          {/* <div className="common-design-img">
            <img src={CommonDesign} />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default OurServices;
