// import AboutResource from "../../assets/img/resourceOffering.png";
import AboutResource from "../../assets/img/resourceOffering1.png";
import PackageCard1 from "../../assets/img/packageCard1.png";
import PackageCard2 from "../../assets/img/packageCard22.png";
import PackageCard3 from "../../assets/img/packageCard33.png";
import PackageCard4 from "../../assets/img/packageCard4.png";
import PackageCard5 from "../../assets/img/packageCard44.png";
// import PackageCard5 from "../../assets/img/packageCard5.png";
import PackageCard6 from "../../assets/img/packageCard66.png";
import bgImg from "../../assets/img/bgCoveringResources.png";
import mediaImg1 from "../../assets/img/mediaImg1.png";
import mediaImg2 from "../../assets/img/mediaImg2.png";
import mediaImg3 from "../../assets/img/mediaImg3.png";

export let PackageData = {
  mainHeading: "CHOOSE A PACKAGE WHICH BEST SUITS FOR YOU",
  cards: [
    {
      img: PackageCard1,
      title: "Signature Package",
      details:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis nihil dicta minus laudantium perferendis consequatur officia praesentium ea.Dolore praesentium magni nam possimus. Odio sunt non quibusdam quisquamvoluptates a ab debitis qui, minus, sint quam impedit hic? Labore,suscipit facere repudiandae voluptatum pariatur esse aspernaturpraesentium earum unde illum eveniet, nesciunt expedita itaque nihilnemo doloremque vitae porro ex perspiciatis, neque nostrum asperioressint? Eos, quae. Porro illum voluptatibus, eos itaque quaerat facereamet eveniet quod eligendi necessitatibus, ad at voluptas ex esse illolabore recusandae. Dolore illo vero, tempora molestias modiconsequuntur, sequi asperiores dolor quo adipisci tempor",
    },
    {
      img: PackageCard2,
      title: "Premium Package",
      details:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis nihil dicta minus laudantium perferendis consequatur officia praesentium ea.Dolore praesentium magni nam possimus. Odio sunt non quibusdam quisquamvoluptates a ab debitis qui, minus, sint quam impedit hic? Labore,suscipit facere repudiandae voluptatum pariatur esse aspernaturpraesentium earum unde illum eveniet, nesciunt expedita itaque nihilnemo doloremque vitae porro ex perspiciatis, neque nostrum asperioressint? Eos, quae. Porro illum voluptatibus, eos itaque quaerat facereamet eveniet quod eligendi necessitatibus, ad at voluptas ex esse illolabore recusandae. Dolore illo vero, tempora molestias modiconsequuntur, sequi asperiores dolor quo adipisci tempor",
    },
    {
      img: PackageCard3,
      title: "Luxury Package",
      details:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis nihil dicta minus laudantium perferendis consequatur officia praesentium ea.Dolore praesentium magni nam possimus. Odio sunt non quibusdam quisquamvoluptates a ab debitis qui, minus, sint quam impedit hic? Labore,suscipit facere repudiandae voluptatum pariatur esse aspernaturpraesentium earum unde illum eveniet, nesciunt expedita itaque nihilnemo doloremque vitae porro ex perspiciatis, neque nostrum asperioressint? Eos, quae. Porro illum voluptatibus, eos itaque quaerat facereamet eveniet quod eligendi necessitatibus, ad at voluptas ex esse illolabore recusandae. Dolore illo vero, tempora molestias modiconsequuntur, sequi asperiores dolor quo adipisci tempor",
    },
    {
      img: PackageCard4,
      title: "Prenatal Package",
      details:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis nihil dicta minus laudantium perferendis consequatur officia praesentium ea.Dolore praesentium magni nam possimus. Odio sunt non quibusdam quisquamvoluptates a ab debitis qui, minus, sint quam impedit hic? Labore,suscipit facere repudiandae voluptatum pariatur esse aspernaturpraesentium earum unde illum eveniet, nesciunt expedita itaque nihilnemo doloremque vitae porro ex perspiciatis, neque nostrum asperioressint? Eos, quae. Porro illum voluptatibus, eos itaque quaerat facereamet eveniet quod eligendi necessitatibus, ad at voluptas ex esse illolabore recusandae. Dolore illo vero, tempora molestias modiconsequuntur, sequi asperiores dolor quo adipisci tempor",
    },
    {
      img: PackageCard5,
      title: "IVF Package",
      details:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis nihil dicta minus laudantium perferendis consequatur officia praesentium ea.Dolore praesentium magni nam possimus. Odio sunt non quibusdam quisquamvoluptates a ab debitis qui, minus, sint quam impedit hic? Labore,suscipit facere repudiandae voluptatum pariatur esse aspernaturpraesentium earum unde illum eveniet, nesciunt expedita itaque nihilnemo doloremque vitae porro ex perspiciatis, neque nostrum asperioressint? Eos, quae. Porro illum voluptatibus, eos itaque quaerat facereamet eveniet quod eligendi necessitatibus, ad at voluptas ex esse illolabore recusandae. Dolore illo vero, tempora molestias modiconsequuntur, sequi asperiores dolor quo adipisci tempor",
    },
    {
      img: PackageCard6,
      title: "Multi-Cycle UVF Package",
      details:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis nihil dicta minus laudantium perferendis consequatur officia praesentium ea.Dolore praesentium magni nam possimus. Odio sunt non quibusdam quisquamvoluptates a ab debitis qui, minus, sint quam impedit hic? Labore,suscipit facere repudiandae voluptatum pariatur esse aspernaturpraesentium earum unde illum eveniet, nesciunt expedita itaque nihilnemo doloremque vitae porro ex perspiciatis, neque nostrum asperioressint? Eos, quae. Porro illum voluptatibus, eos itaque quaerat facereamet eveniet quod eligendi necessitatibus, ad at voluptas ex esse illolabore recusandae. Dolore illo vero, tempora molestias modiconsequuntur, sequi asperiores dolor quo adipisci tempor",
    },
  ],
};

export let AboutOfferingData = {
  mainHeading: "Resources",
  subHeadeing: "ENSURING A STRESS-FREE MEDICAL EXPERIENCE",
  description: [
    "There are times when money takes precedence over our healthcare needs. To ensure that is never the case with you, we have collaborated with several third-party medical claim resources. This is to ensure your medical journey is seamless and not bound by money.",
    "To further streamline your medical experience, we offer a range of convenient package options. Each package is customizable to your specific wants and needs. Whatever plan you choose, you can be rest assured that Oma will go above and beyond to make your experience a pleasant one.",
  ],
  img: AboutResource,
};

export let CommonBackgroundImage = {
  img: bgImg,
};

export let OurEmpanelledData = {
  Header: "Our Empanelled Insurance Companies",
  Content: [
    {
      id: 1,
      title: "Trusted Insurance Providers",
      data: [
        "HDFC ERGO General Insurance Co. Ltd.",
        "ICICI Lombard General Insurance Company Pvt. Ltd.",
      ],
    },
    {
      id: 2,
      title: "Empanelled Third Party Agents",
      data: ["Gmoney"],
    },
  ],
};

export let mediaData = {
  mainHeading: "OMA's Media Presence",
  desc: "Learn more about our recent successes, medical services, healthcare options, journals, case studies and physicians here.",
  mediaCircleData: [
    {
      img: mediaImg1,
      desc: "Know how hypertension affects pregnancy and prepare for a safe delivery",
    },
    {
      img: mediaImg2,
      desc: "High blood pressure during pregnancy can be dangerous - all about preeclampsia",
    },
    {
      img: mediaImg3,
      desc: "Don't miss out on vitamin D if you have PCOS - here's why",
    },
  ],
};
