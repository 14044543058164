import React from "react";
import "./style.scss";

const ComplexCommon = ({ data, circleCount }) => {
  return (
    <section className="section-wrapper circle-dot-section" id="offerings">
      <div className="container">
        <div className="content-wrapper">
          <div className="page-titles complex-circle-heading">
            {data.mainHeading}
          </div>
          <p className="template-content">{data.description}</p>
          <div className="circle-offering-wrapper desktop-view">
            <div className="circle-offering">
              <img src={data.circleImg} alt="" className="component-img" />
            </div>
            <ul className={`oma-common-circle ${circleCount}`}>
              {data.circleData.map((item, index) => {
                return (
                  <li className={item.class} key={index}>
                    <span className={item.textSpan}>
                      {item.headingText && (
                        <span className={item.headingClass}>
                          {item.headingText}
                        </span>
                      )}
                      {item.text}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="circle-offering-wrapper-responsive">
            <div className="list-container-top">
              {data.responsiveCircleData.map((item) => {
                return (
                  item.positionClass === "top" && (
                    <>
                      <div className="dot-container-responsive-top"></div>
                      <div
                        className={`circle-text-responsive top-text ${item.headerText}`}
                      >
                        {item.headingText && (
                          <span className={item.headingClass}>
                            {item.headingText}
                          </span>
                        )}
                        {item.text}
                      </div>
                    </>
                  )
                );
              })}
            </div>

            <div className="oma-common-circle-responsive">
              <div className="circle-offering-responsive">
                <img src={data.circleImg} alt="" className="component-img" />
              </div>
            </div>
            <div className="list-container-bottom">
              {data.responsiveCircleData.map((item) => {
                return (
                  item.positionClass === "bottom" && (
                    <>
                      <div className="dot-container-responsive-bottom"></div>
                      <div
                        className={`circle-text-responsive bottom-text ${item.headerText}`}
                      >
                        {item.headingText && (
                          <span className={item.headingClass}>
                            {item.headingText}
                          </span>
                        )}
                        {item.text}
                      </div>
                    </>
                  )
                );
              })}
            </div>
          </div>
          <p className="content-desc">{data.contentDesc}</p>
        </div>
      </div>
    </section>
  );
};

export default ComplexCommon;
