// import AboutAestheticGynecology from "../../assets/img/AboutAestheticGynecology.png";
import AboutAestheticGynecology from "../../assets/img/AboutAestheticGynecology1.png";
// import CoverImg3 from "../../assets/img/CoverImg3.png";
import CoverImg3 from "../../assets/img/CoverImg32.png";
import TanujaUchil from "../../assets/img/TanujaUchil.png";
// import HarshMehta from "../../assets/img/HarshMehta.png";
// import AnitaSharma from "../../assets/img/AnitaSharma.png";

// import ComponentImg from "../../assets/img/ivf-circle.png";

export let AboutOfferingData = {
  mainHeading: "AESTHETIC REGENERATIVE & FUNCTIONAL GYNECOLOGY",
  subHeadeing: "Achieve the look and feel you've always desired",
  description: [
    "The field of aesthetic Gynecology has experienced explosive growth in recent years as more and more women discover the health and beauty advantages it offers. It's no secret that modern women are increasingly self-conscious about their physical appearance and are turning to innovative medical treatments to help them achieve their goals.",
    "At Oma, we offer state-of-the-art Aesthetic Gynecology Services because we are confident that we can help our female patients regain or build their confidence in their femininity and attractiveness through a variety of non-surgical and surgical procedures.",
    "At Oma Hospital, we understand the importance of selecting a skilled and knowledgeable specialist for aesthetic and regenerative Gynecology procedures. Our dedicated healthcare experts led by Dr. Tanuja specialize in this field, ensuring that you receive the highest level of care. Before any treatment, we conduct a comprehensive consultation to understand your unique concerns, goals, and overall health. Your safety, comfort, and satisfaction are our utmost priorities in the realm of Gynecology.",
  ],
  img: AboutAestheticGynecology,
};

// export let aestheticGynecologyCircleData = {
//   mainHeading: "OUR OFFERINGS IN THE FIELD OF AESTHETIC GYNAECOLOGY",
//   circleImg: ComponentImg,
//   circleData: [
//     {
//       class: "dot-container left-alignT ag-right-hover",
//       textSpan: "ag-circle-text",
//       text: "Scar Revision: Enhance the appearance of surgical scars.",
//     },
//     {
//       class: "dot-container left-alignT ag-right-hover",
//       textSpan: "ag-circle-text",
//       text: "Intimate Skin Lightening: Lighten genital skin using laser therapy and topicals.",
//     },
//     {
//       class: "dot-container left-alignT ag-right-hover",
//       textSpan: "ag-circle-text",
//       text: "Vaginal Health and Wellness Consultations: Comprehensive care, sexual wellness, and preventative advice.",
//     },
//     {
//       class: "dot-container left-alignT ag-right-hover",
//       textSpan: "ag-circle-text",
//       // text: "Clitoral Hoodectomy",
//     },
//     {},
//     {},
//     {},
//     {
//       class: "dot-container right-alignT ag-left-hover",
//       textSpan: "ag-text-left-side ag-circle-text",
//       // text: "G-Spot Amplification",
//     },
//     {
//       class: "dot-container right-alignT ag-left-hover",
//       textSpan: "ag-text-left-side ag-circle-text",
//       text: "Platelet-Rich Plasma (PRP) Therapy: Revitalize tissues.",
//     },
//     {
//       class: "dot-container right-alignT ag-left-hover",
//       textSpan: "ag-text-left-side ag-circle-text",
//       text: "G-Spot Amplification: Enhance sexual pleasure.",
//     },
//     {
//       class: "dot-container right-alignT ag-left-hover",
//       textSpan: "ag-text-left-side ag-circle-text",
//       text: "Clitoral Hood Reduction: Reduce excess skin.",
//     },
//     {
//       class: "dot-container right-alignT ag-left-hover",
//       textSpan: "ag-text-left-side ag-circle-text",
//       text: "Labial Rejuvenation (Labiaplasty): Reshape and resize labia.",
//     },
//     {
//       class: "dot-container right-alignT ag-left-hover",
//       textSpan: "ag-text-left-side ag-circle-text",
//       text: "Laser Vaginal Rejuvenation: Minimally invasive laser treatment.",
//     },
//     {
//       class: "ag-list-header right-alignT ag-left-hover",
//       textSpan: "header-text-1 ag-text-left-side",
//       text: "Vaginal Rejuvenation: Restore vaginal appearance, tone, and function.",
//     },
//     {},
//     {},
//     {},
//     {
//       // class: "ag-list-header left-alignT ag-right-hover",
//       // textSpan: "header-text-2",
//       // text: "SURGICAL OPTIONS",
//     },
//     {
//       class: "dot-container left-alignT ag-right-hover",
//       textSpan: "ag-circle-text",
//       text: "Stress Urinary Incontinence Treatment: Address urinary incontinence with non-surgical options.",
//     },
//     {
//       class: "dot-container left-alignT ag-right-hover",
//       textSpan: "ag-circle-text",
//       text: "Non-Surgical Vaginal Tightening: Improve tone and tightness without surgery.",
//     },
//   ],

//   responsiveCircleData: [
//     {
//       headerText: "ag-heading-text",
//       positionClass: "bottom",
//       text: "SURGICAL OPTIONS",
//     },
//     {
//       positionClass: "bottom",
//       text: "Vaginal Tightening with\n Single Thread technique",
//     },
//     {
//       positionClass: "bottom",
//       text: "Labia Majora\n Augmentation by fat grafting",
//     },
//     {
//       positionClass: "bottom",
//       text: "Scar Revision: Enhance the appearance of surgical scars.",
//     },
//     {
//       positionClass: "bottom",
//       text: "Labia Minora /\n Majora Plasty",
//     },
//     {
//       positionClass: "bottom",
//       text: "Clitoral Hoodectomy",
//     },
//     {
//       headerText: "ag-heading-text",
//       positionClass: "top",
//       text: "NON-SURGICAL OPTIONS",
//     },
//     {
//       positionClass: "top",
//       text: "Vaginal Rejuvenation with Laser",
//     },
//     {
//       positionClass: "top",
//       text: "Vaginal Rejuvenation with\n Radiofrequency",
//     },
//     {
//       positionClass: "top",
//       text: "Laser vulvar skin Tightening\n / Whitening",
//     },
//     {
//       positionClass: "top",
//       text: "Platelet-Rich Plasma (PRP) Therapy: Revitalize tissues.",
//     },
//     {
//       positionClass: "top",
//       text: "G-Spot Amplification",
//     },
//   ],
//   contentDesc:
//     "Please feel free to contact at our reception at Gynaecology OPD",
// };

export let AestheticGynecologyData = {
  mainHeading: "OUR OFFERINGS IN THE FIELD OF AESTHETIC GYNECOLOGY",

  services: [
    {
      key: 1,
      title: "Vaginal Rejuvenation:",
      service: "Restore vaginal appearance, tone, and function.",
    },
    {
      key: 2,
      title: "Laser Vaginal Rejuvenation:",
      service: "Minimally invasive laser treatment.",
    },
    {
      key: 3,
      title: "Labial Rejuvenation (Labiaplasty):",
      service: "Reshape and resize labia.",
    },
    {
      key: 4,
      title: "Clitoral Hood Reduction:",
      service: "Reduce excess skin.",
    },
    {
      key: 5,
      title: "G-Spot Amplification:",
      service: "Enhance sexual pleasure.",
    },
    {
      key: 6,
      title: "Platelet-Rich Plasma (PRP) Therapy:",
      service: "Revitalize tissues.",
    },
    {
      key: 7,
      title: "Stress Urinary Incontinence Treatment:",
      service: "Address urinary incontinence with non-surgical options. ",
    },
    {
      key: 8,
      title: "Non-Surgical Vaginal Tightening:",
      service: "Improve tone and tightness without surgery.",
    },
    {
      key: 9,
      title: "Scar Revision:",
      service: " Enhance the appearance of surgical scars. ",
    },
    {
      key: 10,
      title: "Intimate Skin Lightening:",
      service: "Lighten genital skin using laser therapy and topicals.",
    },
    {
      key: 11,
      title: "Vaginal Health and Wellness Consultations: ",
      service: "Comprehensive care, sexual wellness, and preventative advice.",
    },
  ],
};
export let CommonBackgroundImage = {
  img: CoverImg3,
};

export let services = {
  mainHeading: "All you need to know about Aesthetic Gynaecology",
  subHeading: [
    "Cosmetic gynecology is the treatment of vaginal related conditions. It includes both surgical and non-surgical options. It is still relatively new in India",

    "Some of the more common procedures associated with cosmetic gynecology include:",
  ],
};

export let servicesData = {
  mainHeading: "All you need to know about Aesthetic Gynaecology",
  subHeading1:
    "This relatively new field of medicine allows women to change their private parts for aesthetic or functional reasons.",
  subHeading2:
    "Procedures commonly performed in the field of cosmetic Gynecology include,",
  services: [
    {
      key: 1,
      title: "Vaginal Rejuvenation",
      service: `A reconstructive plastic surgery and cosmetic procedure that treats the vaginal canal, mucous membrane, and other vulvovaginal areas of the uterus. Also known as Vaginoplasty, the process "tightens up" a vagina loosened by natural childbirth or aging.`,
    },
    {
      key: 2,
      title: "Clitoral Hood Reduction",
      service:
        "A clitoral hood reduction removes excess skin from the clitoris's skin folds. It is usually performed in conjunction with a labiaplasty. When the labia minora are tightened, the extra folds can cause a bulge, and a clitoral hood reduction can help restore the symmetry of the female genitalia.",
    },
    {
      key: 3,
      title: "Labia Minora Reduction",
      service: `A Labia Minora Labiaplasty, which means "reduction of excess skin along the side of the clitoris," is the second most common genital cosmetic surgery procedure among women. They choose to undergo this procedure for purely aesthetic or sexual well-being reasons.`,
    },
    {
      key: 4,
      title: "Hymenoplasty",
      service:
        "Reconstructing a damaged hymen is called hymenoplasty. The hymen usually breaks after a sexual encounter or other strenuous activities. Scarless reconstruction is the goal of this aesthetic surgery.",
    },
    {
      key: 5,
      title: "Labia Majora Reduction",
      service:
        "Labia majora is the vagina's outermost layer. It consists of skin, fat, and blood vessels. The purpose of this reduction procedure is for women who develop a bulge which causes discomfort in swimsuits and form-fitting clothing and dissatisfaction with their appearance.",
    },
    {
      key: 6,
      title: "G-Spot Augmentation",
      service:
        "To have a more intense sexual experience, women can get a G-spot Augmentation. It is a fairly simple and non-invasive medical procedure performed by doctors. The process involves expanding the G-spot’s or otherwise known as Grafenberg Spot’s sensitivity and size.",
    },
  ],
};

export let AsGynecologyDoctorsData = {
  mainHeading: "Meet the Doctors",
  subHeading: "",
  // description: [
  //   "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields",
  // ],
  ifquestion: true,
  question: "Got a question? Leave no room for doubt.",
  button: "Contact Us",
  doctors: [
    {
      img: TanujaUchil,
      name: "Dr. Tanuja Uchil",
      // role: "Role: Radiologist and Ultrasonologist",
      experience: [
        "MBBS, DGO, DNB - Obstetrics & Gynecology, MNAMS - Obstetrician and Gynecologist",
      ],
      description: [
        "Dr. Tanuja Uchil serves as a full-time consultant in Obstetrics and Gynecology at OMA Hospital. Dr. Uchil's journey in the field of medicine is characterized by excellence, expertise, and a genuine commitment to women's health.",
        "Educational Background: Dr. Uchil's academic journey commenced with her MBBS degree from Seth G S Medical College & KEM Hospital in 1997 followed by her role as a resident medical officer specializing in Obstetrics and Gynecology at the Nowrosjee Wadia Maternity Hospital and pursuing the degree of MD in Obstetrics & Gynecology from Seth G S Medical College & KEM Hospital, University of Mumbai.",
        "",
      ],
    },
  ],
};
