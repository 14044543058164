import React from "react";
import { Col, Row } from "reactstrap";
import "./style.scss";

const MediaVideo = ({ data }) => {
  return (
    <section className="section-wrapper bottom-common-effect">
      <div className="container">
        <div className="media-main-container">
          <div className="media-top">
            <div className="page-titles">{data.mainHeading}</div>
            <div className="template-content">{data.desc}</div>
          </div>
          <div className="media-bottom">
            <Row>
              {data.mediaCircleData.map((item) => {
                return (
                  <>
                    <Col md="4" sm="12" className="media-col">
                      <div className="media-item">
                        <div className="media-description">
                          <h5>{item.desc}</h5>
                          <div className="media-view-now">read more</div>
                        </div>
                        <img src={item.img} alt="" className="media-img" />
                      </div>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MediaVideo;
