import commonDesign from "../../assets/img/commonDesign.png";
// import AboutRadiology from "../../assets/img/AboutRadiology.png";
import AboutRadiology from "../../assets/img/AboutRadiology1.png";
// import TanujaUchil from "../../assets/img/TanujaUchil.png";
// import HarshMehta from "../../assets/img/HarshMehta.png";
// import AnitaSharma from "../../assets/img/AnitaSharma.png";
// import ComponentImg from "../../assets/img/nicu-circle.png";

import RadiologyImg1 from "../../assets/img/RadiologyBanner1.jpg";
import RadiologyImg2 from "../../assets/img/RadiologyBanner2.jpg";
import DefaultAvatar from "../../assets/img/DefaultAvatar.jpeg";

export let AboutOfferingData = {
  mainHeading: "RADIOLOGY & DIAGNOSTICS",
  subHeadeing: "EXPERIENCE PRECISION WITH UTMOST SAFETY ",
  description: [
    "To ensure that your child is exposed to as little radiation as possible during an imaging test, Oma adheres to all applicable safety standards in the field. Our state-of-the-art radiological technology has allowed us to provide superior care to all of our patients.",
    "Our advanced and modern capabilities provide specialists with a clear view of your internal organs and systems to ensure no hiccups come in the way of your little one's arrival. At Oma, it is also a possibility to see a 3D image of your baby before he/she is born, giving you a sneak peek of the wonderful life that is to come.",
  ],
  img: AboutRadiology,
};

// export let radiologyCircleData = {
//   mainHeading: "EXPLORE THE VARIED IMAGING SERVICES OF OMA",
//   circleImg: ComponentImg,
//   circleData: [
//     {
//       class: "dot-container left-alignT",
//       textSpan: "header-hover",
//       headingClass: "radiology-header",
//       headingText: "MRI (Magnetic Resonance Imaging):",
//       text: "Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
//     },
//     {
//       // class: "dot-container left-alignT",
//       // textSpan: "header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "MRI (Magnetic Resonance Imaging):",
//       // text: "2",
//     },
//     {
//       // class: "dot-container left-alignT",
//       // textSpan: "header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "MRI (Magnetic Resonance Imaging):",
//       // text: "3",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "header-hover",
//       headingClass: "radiology-header",
//       headingText: "MRI (Magnetic Resonance Imaging):",
//       text: "Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
//     },
//     {
//       // class: "dot-container left-alignT",
//       // textSpan: "header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "MRI (Magnetic Resonance Imaging):",
//       // text: "5",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "ag-text-left-side header-hover",
//       headingClass: "radiology-header",
//       headingText: "CT (Computed Tomography):",
//       text: "Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
//     },
//     {
//       // class: "dot-container right-alignT",
//       // textSpan: "ag-text-left-side header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "CT (Computed Tomography):",
//       // text: "7",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "ag-text-left-side header-hover",
//       headingClass: "radiology-header",
//       headingText: "X-RAY:",
//       text: "Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
//     },
//     {
//       // class: "dot-container right-alignT",
//       // textSpan: "ag-text-left-side header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "X-RAY:",
//       // text: "9",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "ag-text-left-side header-hover",
//       headingClass: "radiology-header",
//       headingText: "X-RAY:",
//       text: "Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
//     },
//     {
//       // class: "dot-container right-alignT",
//       // textSpan: "ag-text-left-side header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "X-RAY:",
//       // text: "11",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "ag-text-left-side header-hover",
//       headingClass: "radiology-header",
//       headingText: "Ultrasound:",
//       text: " Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
//     },
//     {
//       // class: "dot-container left-alignT",
//       // textSpan: "header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "Ultrasound:",
//       // text: "13",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "header-hover",
//       headingClass: "radiology-header",
//       headingText: "Ultrasound:",
//       text: "Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
//     },
//     {
//       // class: "dot-container left-alignT",
//       // textSpan: "header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "Ultrasound:",
//       // text: "15",
//     },
//     {
//       // class: "dot-container left-alignT",
//       // textSpan: "header-hover",
//       // headingClass: "radiology-header",
//       // headingText: "Ultrasound:",
//       // text: "16",
//     },
//     // {
//     //   class: "dot-container left-alignT",
//     //   textSpan: "header-hover",
//     //   headingClass: "radiology-header",
//     //   headingText: "Ultrasound:",
//     //   text: "17",
//     // },

//   ],
//   responsiveCircleData: [
//     {
//       positionClass: "bottom",
//       headingClass: "radiology-header-mobile",
//       headingText: "Ultrasound",
//       text: "Ultrasound at high frequencies is used for this prenatal imaging. It is also used frequently in gynaecology, obstetrics, and paediatrics.",
//     },
//     {
//       positionClass: "bottom",
//       headingClass: "radiology-header-mobile",
//       headingText: "MRI (Magnetic Resonance Imaging)",
//       text: "High-quality, radiation-free imaging of internal organs is made possible by this method. Unless absolutely necessary, we avoid pregnant women from getting an MRI.",
//     },
//     {
//       positionClass: "top",
//       headingClass: "radiology-header-mobile",
//       headingText: "CT (Computed Tomography)",
//       text: "Medical imaging in this format provides cross-sectional images of the body, facilitating the diagnosis and localisation of the disease.",
//     },
//     {
//       positionClass: "top",
//       headingClass: "radiology-header-mobile",
//       headingText: "X-RAY",
//       text: "A deeper look at the cavities, bones or other foreign objects through the tissue is made possible by this type of medical imaging. To obtain a real-time video, contrast agents may be utilised by our specialists",
//     },
//   ],
// };

export let RadiologyData = {
  mainHeading: "EXPLORE THE VARIED IMAGING SERVICES OF OMA",
  // description: [
  //   "IVF refers to a procedure where the woman's eggs are removed from her ovaries and fertilised with the man's sperm in a laboratory. The embryos formed are then put back into the uterus to achieve a pregnancy. This procedure has multiple steps & variables and we are there with you, at every step.",
  // ],
  services: [
    {
      key: 1,
      title: "Breast Ultrasound: ",
      service:
        "Used alongside mammography for further breast evaluation, especially for dense breast tissue or post-mammogram assessment.",
    },
    {
      key: 2,
      title: "Pelvic Ultrasound:",
      service:
        " Examines pelvic organs, such as the uterus and ovaries, aiding in the diagnosis of conditions like fibroids and ovarian cysts.",
    },
    {
      key: 3,
      title: "Transvaginal Ultrasound:",
      service:
        "Specialized exam for detailed imaging of the uterus and ovaries, commonly used for gynecological issues.",
    },
    {
      key: 4,
      title: "Ultrasound for Pregnancy:",
      service:
        "Obstetric ultrasounds monitor fetal health and development during pregnancy, including specialized scans for high-risk pregnancies. ",
    },
    {
      key: 5,
      title: "Fetal Monitoring: ",
      service:
        " Advanced monitoring during labor and delivery to ensure both maternal and fetal well-being.",
    },
    {
      key: 6,
      title: "Biopsies and Pathology Services:",
      service:
        "Tissue sample collection for further evaluation by pathologists if abnormalities are detected during diagnostics.",
    },
    {
      key: 7,
      title: "Genetic Testing and Counseling: ",
      service:
        "Genetic testing and counseling for women and couples considering pregnancy or facing high-risk pregnancies.",
    },
  ],
};
export let MeetDoctorsData = {
  mainHeading: "Meet the Specialists",
  subHeading:
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields.",
  description: [
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields.",
  ],
  commonDesign: commonDesign,
  ifquestion: true,
  question: "Got a question? Leave no room for doubt.",
  button: "Contact Us",
  // doctors: [
  //   {
  //     img: TanujaUchil,
  //     name: "Dr. Madan Manmohan",
  //     about:
  //       "MBBS, DGO, MD(OBG) Senior Consultant Obstetrician & Gynaecologist",
  //   },
  //   {
  //     img: HarshMehta,
  //     name: "Dr. Amit Sahu",
  //     about:
  //       "MBBS, MD, DGO, MRCOG(UK), FRCOG Consultant Obstetrician and Gynaecologist",
  //   },
  //   {
  //     img: AnitaSharma,
  //     name: "Dr. Rohan Sawant",
  //     about:
  //       "MBBS, DGO, DNB - Obstetrics & Gynecology,MNAMS - Obstetrician and Gynecologist",
  //   },
  // ],
  doctors: [
    {
      img: DefaultAvatar,
      name: "Dr. Manmohan Madan",
      role: "Radiologist and Ultrasonologist",
      experience: [
        "MBBS (1979) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai in 1979.",
        "Diploma in Medical Radio-Diagnosis (1982) -  Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
        "MD - Radio Diagnosis/Radiology (1984) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
      ],
      description: [
        "Dr. Manmohan Madan is an experienced Radiologist and Ultrasonologist with over four decades of expertise. He holds an MD in Radio Diagnosis and has been a key contributor to medical imaging services. Dr. Madan's commitment to diagnostic accuracy and patient care has made him a respected figure in the field of radiology.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Amit Sahu",
      role: "Consultant Interventional Radiologist",
      experience: ["MD FVIR FINR"],
      description: [
        "Dr. Amit Sahu is a fellowship-trained Interventional Radiologist having an experience of over 12 years. He has undergone training in Vascular and interventional Radiology and Interventional Neuroradiology from Seth GS Medical College & King Edward Memorial (KEM) Hospital in Mumbai and the Johns Hopkins Hospital in Baltimore, USA.",
        "He has extensive experience in managing and treating patients related to Neuro, Body & Peripheral Interventional Superspecialist. He has an experience of performing over thousands of endovascular procedures to his credit.",
        "Scope Of Services:",
        "He is highly proficient in performing Interventional Procedures centered around women-related vascular problems, and male and female infertility apart from other Neuro & Peripheral Vascular Interventions.",
        "Varicose Veins: EVLT (Laser), Sclerotherapy, Venaseal",
        "Deep Vein Thrombosis: Catheter Thrombolysis, IVC Filter Placement",
        "Uterine Artery Fibroid Embolization",
        "Uterine Artery Embolization in Adenomyosis, Uterine AV Malformations, Post Partum Bleeding",
        "Pelvic Congestion Syndrome: Ovarian Vein Embolization",
        "Vascular Malformations: Embolization, Sclerotherapy",
        "Male Infertility: Varicocele Embolization",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Rohit Sawant",
      role: "Radiologist and Ultrasonologist",
      experience: [
        "MBBS (1979) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai in 1979.",
        "Diploma in Medical Radio-Diagnosis (1982) -  Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
        "MD - Radio Diagnosis/Radiology (1984) - Lokmanya Tilak Municipal Medical College, Sion, Mumbai",
      ],
      description: [
        "Dr. Manmohan Madan is an experienced Radiologist and Ultrasonologist with over four decades of expertise. He holds an MD in Radio Diagnosis and has been a key contributor to medical imaging services. Dr. Madan's commitment to diagnostic accuracy and patient care has made him a respected figure in the field of radiology.",
      ],
    },

    // {
    //   img: AnitaSharma,
    //   name: "Dr. Neeta Nathani",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Neeta Nathani has been serving the healthcare needs of children in Mumbai for over three decades. She runs Nathani Clinic in Chembur and serves as an attending pediatrician at Surya Hospital, Kolekar Hospital, and Zen Hospital in Chembur. Dr. Nathani is not only a trusted pediatrician but also an educator who conducts teaching sessions for family practitioners and delivers CME lectures at various conferences. Her commitment to pediatric care and medical education is commendable.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Amit Sahu",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Amit Sahu is a fellowship-trained Interventional Radiologist having an experience of over 12 years. He has undergone training in Vascular and interventional Radiology and Interventional Neuroradiology from Seth GS Medical College & King Edward Memorial (KEM) Hospital in Mumbai and the Johns Hopkins Hospital in Baltimore, USA.",
    //     "He has extensive experience in managing and treating patients related to Neuro, Body & Peripheral Interventional Superspecialist. He has an experience of performing over thousands of endovascular procedures to his credit.",
    //     "Scope Of Services: ",
    //     "He is highly proficient in performing Interventional Procedures centered around women-related vascular problems, and male and female infertility apart from other Neuro & Peripheral Vascular Interventions.",
    //   ],
    // },
    // {
    //   img: HarshMehta,
    //   name: "Dr. Prasad Mahadevan",
    //   role: "abc",
    //   about:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    //   description: [
    //     "Dr. Prasad Mahadevan is a highly skilled gynecologist with a passion for women's health. With over three decades of experience, he is known for his expertise in obstetrics and Gynecology. Dr. Mahadevan's commitment to patient well-being, along with his involvement in various medical associations, highlights his dedication to women's healthcare.",
    //   ],
    // },
  ],
};

export let AmbienceData = {
  heading: "We believe in clarity above all else",
  description:
    "Oma is well-prepared to provide precise diagnostics and radiological services to patients thanks to its cutting-edge technology and highly trained personnel.",
};

export let AmbienceImages = [
  {
    src: RadiologyImg1,
  },
  {
    src: RadiologyImg2,
  },
];
