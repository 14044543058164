// import AboutIvf from "../../assets/img/AboutIvf.png";
import AboutIvf from "../../assets/img/AboutIvf1.png";
// import CoverImg4 from "../../assets/img/CoverImg4.png";
import CoverImg4 from "../../assets/img/CoverImg41.png";
import commonDesign from "../../assets/img/commonDesign.png";
import TanujaUchil from "../../assets/img/TanujaUchil.png";
// import HarshMehta from "../../assets/img/HarshMehta.png";
// import AnitaSharma from "../../assets/img/AnitaSharma.png";
// import ComponentImg from "../../assets/img/ivf-circle.png";

import IvfImg1 from "../../assets/img/IvfBanner1.jpg";
import IvfImg2 from "../../assets/img/IvfBanner2.jpg";
import IvfImg3 from "../../assets/img/IvfBanner3.jpg";

export let AboutOfferingData = {
  mainHeading: "IVF & FERTILITY ",
  subHeadeing: "DELIVERING BUNDLES OF JOY",
  description: [
    "Welcome to the heart of innovation at Oma Hospital IVF Centre – our cutting-edge Embryology Lab. Nestled within a climate-controlled sterile room, this laboratory is the epitome of precision and excellence in reproductive medicine.",
    // "At Oma Hospital, we take pride in our commitment to leveraging the latest advancements in technology for your fertility journey. Our Embryology Lab boasts high-end microscopes from Leica, Germany, ensuring unparalleled clarity and accuracy in every procedure. The micro manipulator from Eppendorf, Germany, complements our state-of-the-art equipment, providing the delicate touch required for intricate processes.",
    // "Step into an environment crafted with meticulous detail – medical-grade stainless steel walls, fortified with an anti-bacterial coating, create the perfect backdrop for Embryology and Andrology procedures. This sterile haven ensures that every step of your fertility journey adheres to the highest standards of hygiene and safety.",
    // "The core of our lab features the Trigas benchtop incubator and IVF workstation with a built-in anti-vibration table, both sourced from Origio, Denmark. These exceptional tools create an optimal environment for embryo development, setting the stage for success in your fertility endeavors.",
    // "For the critical moments of Ovum pick-up and embryo transfer, Oma Hospital IVF Centre provides a dedicated Sterile Operating Theater. This specialized space is equipped with an oocyte retrieval pump from Origio, Denmark, ensuring precision and sterility in these pivotal procedures.",
    // "At Oma Hospital IVF Centre, we understand that the journey to parenthood is unique and deeply personal. That's why our Embryology Lab is not just a space; it's a testament to our unwavering commitment to your dreams. Trust us to transform the latest in reproductive technology into the stepping stones that lead you to the family you've always envisioned. Welcome to the future of fertility at Oma Hospital IVF Centre – where innovation meets compassion.",
  ],
  img: AboutIvf,
};

export let AboutOfferingSubData = {
  heading1:
    "At OMA Hospital, we take pride in our commitment to leveraging the latest advancements in technology for your fertility journey",
  // heading2: "Which is all About YOU",
  description1:
    "Our Embryology Lab boasts high-end microscopes from Leica, Germany, ensuring unparalleled clarity and accuracy in every procedure. The micro manipulator from Eppendorf, Germany, complements our state-of-the-art equipment, providing the delicate touch required for intricate processes.",
  description2:
    "Step into an environment crafted with meticulous detail – medical-grade stainless steel walls, fortified with an anti-bacterial coating, create the perfect backdrop for Embryology and Andrology procedures. This sterile haven ensures that every step of your fertility journey adheres to the highest standards of hygiene and safety.",
  description3:
    "The core of our lab features the Trigas benchtop incubator and IVF workstation with a built-in anti-vibration table, both sourced from Origio, Denmark. These exceptional tools create an optimal environment for embryo development, setting the stage for success in your fertility endeavors.",
  description4:
    "For the critical moments of Ovum pick-up and embryo transfer, Oma Hospital IVF Centre provides a dedicated Sterile Operating Theater. This specialized space is equipped with an oocyte retrieval pump from Origio, Denmark, ensuring precision and sterility in these pivotal procedures.",
  description5:
    "At Oma Hospital IVF Centre, we understand that the journey to parenthood is unique and deeply personal. That's why our Embryology Lab is not just a space; it's a testament to our unwavering commitment to your dreams. Trust us to transform the latest in reproductive technology into the stepping stones that lead you to the family you've always envisioned. Welcome to the future of fertility at Oma Hospital IVF Centre – where innovation meets compassion.",
};

// export let ivfCircleData = {
//   mainHeading: "OUR OFFERINGS",
//   description: [
//     "IVF refers to a procedure where the woman's eggs are removed from her ovaries and fertilised with the man's sperm in a laboratory. The embryos formed are then put back into the uterus to achieve a pregnancy. This procedure has multiple steps & variables and we are there with you, at every step.",
//   ],

//   circleImg: ComponentImg,
//   circleData: [
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       headingClass: "radiology-header",
//       headingText: "Intracytoplasmic Sperm Injection (ICSI):",
//       text: " Injecting a single sperm into an egg during IVF for male factor fertility issues.",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       headingClass: "radiology-header",
//       headingText: "Donor Services:",
//       text: " Programs for donor eggs, sperm, or embryos when facing gamete challenges.",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       headingClass: "radiology-header",
//       headingText: "Counselling and Support:",
//       text: " Emotional and psychological support services.",
//     },
//     {},
//     {},
//     {},
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       headingClass: "radiology-header",
//       headingText: "Fertility Preservation: ",
//       text: "Preserving fertility before medical treatments that may affect it.",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       headingClass: "radiology-header",
//       headingText: "Egg and Sperm Freezing: ",
//       text: " Preservation of eggs or sperm for future fertility needs.",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       headingClass: "radiology-header",
//       headingText: "In Vitro Fertilization (IVF): ",
//       text: " Fertilizing eggs and sperm outside the body and transferring embryos into the uterus for various fertility issues.",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       headingClass: "radiology-header",
//       headingText: "Ovulation Induction: ",
//       text: " Medications to stimulate ovaries and regulate ovulation timing.",
//     },
//     {
//       class: "dot-container right-alignT",
//       textSpan: "circle-text text-left-side",
//       headingClass: "radiology-header",
//       headingText: "Initial Consultation:  ",
//       text: "Comprehensive evaluation of medical history and lifestyle factors to plan treatment.",
//     },
//     {},
//     {},
//     {},
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       headingClass: "radiology-header",
//       headingText: "Fertility Testing: ",
//       text: " Diagnostic tests like hormone assessments, semen analysis, ultrasounds, HSG, and hysteroscopy to identify causes of infertility.",
//     },
//     {
//       class: "dot-container left-alignT",
//       textSpan: "circle-text",
//       headingClass: "radiology-header",
//       headingText: "Intrauterine Insemination (IUI):",
//       text: " Placing sperm directly into the uterus to enhance fertilization chances.",
//     },
//   ],
//   responsiveCircleData: [
//     {
//       positionClass: "bottom",
//       text: "Embryo Preservation\n by Freezing",
//     },
//     {
//       positionClass: "bottom",
//       text: "Sperm Vitrification",
//     },
//     {
//       positionClass: "bottom",
//       text: "Cancer Fertility\n Preservation",
//     },
//     {
//       positionClass: "bottom",
//       text: "Gynecological\n Procedures",
//     },
//     {
//       positionClass: "bottom",
//       text: "Blastocyst Culture",
//     },
//     {
//       positionClass: "top",
//       text: "Percutaneous Epididymal Sperm\n Aspiration (PESA)",
//     },
//     {
//       positionClass: "top",
//       text: "Intrauterine\n Insemination (IUI)",
//     },
//     {
//       positionClass: "top",
//       text: "Testicular Sperm Aspiration\n (TESA)",
//     },
//     {
//       positionClass: "top",
//       text: "Intracytoplasmic Sperm\n Injection (ICSI)",
//     },
//     {
//       positionClass: "top",
//       text: "In-Vitro Fertilization",
//     },
//   ],
// };

export let ivfData = {
  mainHeading: "OUR OFFERINGS",
  description: [
    "IVF refers to a procedure where the woman's eggs are removed from her ovaries and fertilised with the man's sperm in a laboratory. The embryos formed are then put back into the uterus to achieve a pregnancy. This procedure has multiple steps & variables and we are there with you, at every step.",
  ],
  services: [
    {
      key: 1,
      title: "Initial Consultation:",
      service:
        "Comprehensive evaluation of medical history and lifestyle factors to plan treatment.",
    },
    {
      key: 2,
      title: "Fertility Testing:",
      service:
        "Diagnostic tests like hormone assessments, semen analysis, ultrasounds, HSG, and hysteroscopy to identify causes of infertility. ",
    },
    {
      key: 3,
      title: "Ovulation Induction:",
      service:
        "Medications to stimulate ovaries and regulate ovulation timing. ",
    },
    {
      key: 4,
      title: "Intrauterine Insemination (IUI):",
      service:
        "Placing sperm directly into the uterus to enhance fertilization chances.",
    },
    {
      key: 5,
      title: "In Vitro Fertilization (IVF):",
      service:
        "Fertilizing eggs and sperm outside the body and transferring embryos into the uterus for various fertility issues.",
    },
    {
      key: 6,
      title: "Intracytoplasmic Sperm Injection (ICSI):",
      service:
        "Injecting a single sperm into an egg during IVF for male factor fertility issues.",
    },
    {
      key: 7,
      title: "Egg and Sperm Freezing:",
      service: "Preservation of eggs or sperm for future fertility needs. ",
    },
    {
      key: 8,
      title: "Donor Services:",
      service:
        "Programs for donor eggs, sperm, or embryos when facing gamete challenges.",
    },
    {
      key: 9,
      title: "Fertility Preservation:",
      service:
        " Preserving fertility before medical treatments that may affect it.",
    },
    {
      key: 10,
      title: "Counselling and Support:",
      service: "Emotional and psychological support services.",
    },
    {
      key: 11,
      title: "Nutritional and Lifestyle Guidance:",
      service: "Guidance on lifestyle changes to enhance fertility.",
    },
    {
      key: 12,
      title: "Follow-Up Care:",
      service:
        " Monitoring and care post-fertility treatments for a healthy pregnancy.",
    },
  ],
};

export let CommonBackgroundImage = {
  img: CoverImg4,
};

export let IvfSuccessAndCostData = {
  mainHeading: "SUCCESS RATE & COST FACTORS",
  description: [
    "When choosing a fertility clinic, the success rate and cost of the IVF procedure should be your top priorities. And at OMA, we outline how we can offer top-notch fertility care while also respecting one’s financial circumstances.",
    "IVF is a broad term and we offer our solutions based on your needs and diagnosis. The cost and likelihood of success of IVF are determined by three main factors.",
  ],

  IvfCircleData: [
    {
      label: "YOUR AGE",
      description: [
        "Highest Success Rate",
        "Female: 24 to 34 years",
        "Male: 24 to 40 years",
      ],
    },

    {
      label: "Need for choosing IVF",
      description: [
        "Blocked or Damaged Fallopian Tubes, Male Infertility, Advanced Maternal Age, Endometriosis, Genetic, Disorders, Previous Unsuccessful Treatments, Fertility Preservation, Recurrent Miscarriages, Unexplained Infertility.",
      ],
    },

    // {
    //   label: "OTHER FACTORS",
    //   description: [
    //     "In Males: Infertility can affect embryos, poor sperm count, motility, morphology",
    //     "In Females: Low egg count, tubal disorder, and many others",
    //   ],
    // },
  ],
};

export let MeetDoctorsData = {
  mainHeading: "Meet the Doctors",
  subHeading:
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields.",
  description: [
    "For your peace of mind and optimal care, we employ only the finest and most highly trained specialists in their fields.",
  ],
  commonDesign: commonDesign,
  ifquestion: true,
  question: "Got a question? Leave no room for doubt.",
  button: "Contact Us",
  // doctors: [
  //   {
  //     img: TanujaUchil,
  //     name: "Dr. Pankaj Nair",
  //     about:
  //       "MBBS, DGO, MD(OBG) Senior Consultant Obstetrician & Gynaecologist",
  //   },
  //   // {
  //   //   img: HarshMehta,
  //   //   name: "Dr. Harsh Mehta",
  //   //   about:
  //   //     "MBBS, MD, DGO, MRCOG(UK), FRCOG Consultant Obstetrician and Gynaecologist",
  //   // },
  //   // {
  //   //   img: AnitaSharma,
  //   //   name: "Dr. Anita Sharma",
  //   //   about:
  //   //     "MBBS, DGO, DNB - Obstetrics & Gynecology,MNAMS - Obstetrician and Gynecologist",
  //   // },
  // ],
  doctors: [
    {
      img: TanujaUchil,
      name: "Dr. Tanuja Uchil",
      // role: "Role: Radiologist and Ultrasonologist",
      experience: [
        "MBBS, DGO, DNB - Obstetrics & Gynecology, MNAMS - Obstetrician and Gynecologist",
      ],
      description: [
        "Dr. Tanuja Uchil serves as a full-time consultant in Obstetrics and Gynecology at OMA Hospital. Dr. Uchil's journey in the field of medicine is characterized by excellence, expertise, and a genuine commitment to women's health.",
        "Educational Background: Dr. Uchil's academic journey commenced with her MBBS degree from Seth G S Medical College & KEM Hospital in 1997 followed by her role as a resident medical officer specializing in Obstetrics and Gynecology at the Nowrosjee Wadia Maternity Hospital and pursuing the degree of MD in Obstetrics & Gynecology from Seth G S Medical College & KEM Hospital, University of Mumbai.",
        "",
      ],
    },
  ],
};

export let AmbienceData = {
  heading: "Advanced. Safe. Efficient. Perfect.",
  description:
    "Oma was founded on the idea that giving birth to a child is a celebration. We know how hard it can be when you're having trouble getting pregnant. So, our team of specialists double up as guides and people you can confide in. Not to mention our advanced technological capabilities that are bound to help you achieve your memorable milestone.",
};

export let AmbienceImages = [
  {
    src: IvfImg1,
  },
  {
    src: IvfImg2,
  },
  {
    src: IvfImg3,
  },
];
