import React from "react";
import CoreTeam from "../../component/CoreTeam";
import AboutOffering from "../../component/AboutOffering";
import BgCoverImg from "../../component/BgCoverImg";
import Ambience from "../../component/Ambience";
import {
  AboutOfferingData,
  oncoGynecologyCircleData,
  CommonBackgroundImage,
  MeetDoctorsData,
  AmbienceData,
  AmbienceImages,
  knowMoreData,
  OncoGynecologyData,
} from "./data";
import Footer from "../../common/Footer";
import ComplexCommon from "../../component/ComplexComponent";
import OurServices from "../../component/OurServices";

const Fertility = () => {
  return (
    <>
      <AboutOffering data={AboutOfferingData} />
      {/* <ComplexCommon data={oncoGynecologyCircleData} circleCount={"six"} /> */}
      <OurServices data={OncoGynecologyData} noofcolumns="2" pinkBg />
      <BgCoverImg data={CommonBackgroundImage} />
      <OurServices noofcolumns="2" data={knowMoreData} />
      <CoreTeam data={MeetDoctorsData} slidesToShow={1} />
      {/* <Ambience data={AmbienceData} items={AmbienceImages} /> */}
      <Footer />
    </>
  );
};
export default Fertility;
