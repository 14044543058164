import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const KnowMore = ({ modalShow, setModalShow, doctor }) => {
  const toggle = () => setModalShow(!modalShow);

  return (
    <Modal isOpen={modalShow} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>{doctor?.name}</ModalHeader>
      <ModalBody>
        {doctor?.description?.map((desc) => {
          return <p className="template-content">{desc}</p>;
        })}
      </ModalBody>
    </Modal>
  );
};

export default KnowMore;
