import React from "react";
import OurServices from "../../component/OurServices";
import { infraCircleData, knowMoreData } from "./data";
import ComplexCommon from "../../component/ComplexComponent";

const index = () => {
  return (
    <>
      <OurServices noofcolumns="2" data={knowMoreData} />
      <ComplexCommon data={infraCircleData} circleCount={"four"} />
    </>
  );
};

export default index;
