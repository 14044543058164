import React from "react";
import "./style.scss";
import {Row, Col} from "react-bootstrap"

const IvfSuccessAndCost = ({ data }) => {
  return (
    <section className="section-wrapper ivf-success-section">
      <div className="container">
        <div className="content-wrapper">
          <div className="template-heading">{data.mainHeading}</div>
          <p className="template-content">{data.description[0]}</p>
          <p className="template-content">{data.description[1]}</p>
          <div className="ivf-success-and-cost">
            <Row>
              <Col className="left-section">
                <h6 className="circle-heading-ivf-success-and-cost">
                  <strong>{data.IvfCircleData[0].label}</strong>
                </h6>
                <div className="description-ivf-success-and-cost">
                  <p className="para-ivf-success-and-cost">
                    {data.IvfCircleData[0].description[0]}
                  </p>
                  <p className="para-ivf-success-and-cost">
                    {data.IvfCircleData[0].description[1]}
                  </p>
                  <p className="para-ivf-success-and-cost">
                    {data.IvfCircleData[0].description[2]}
                  </p>
                </div>
              </Col>
              <Col>
                 <h6 className="circle-heading-ivf-success-and-cost">
                {data.IvfCircleData[1].label}{" "}
              </h6>
              <div className="description-ivf-success-and-cost">
                <p className="para-ivf-success-and-cost">
                  {data.IvfCircleData[1].description[0]}
                </p>
              </div>
                </Col>
            </Row>
          </div>
          {/* <div className="ivf-success-and-cost-circles"> */}
            {/* <span className="stat-circle-ivf-success-and-cost">
              <h6 className="circle-heading-ivf-success-and-cost">
                <strong>{data.IvfCircleData[0].label}</strong>
              </h6>
              <div className="circle-description-ivf-success-and-cost">
                <p className="circle-para-ivf-success-and-cost">
                  {data.IvfCircleData[0].description[0]}
                </p>
                <p className="circle-para-ivf-success-and-cost">
                  {data.IvfCircleData[0].description[1]}
                </p>
                <p className="circle-para-ivf-success-and-cost">
                  {data.IvfCircleData[0].description[2]}
                </p>
              </div>
            </span>
            <span className="stat-circle-ivf-success-and-cost">
              <h6 className="circle-heading-ivf-success-and-cost">
                {data.IvfCircleData[1].label}{" "}
              </h6>
              <div className="circle-description-ivf-success-and-cost">
                <p className="circle-para-ivf-success-and-cost">
                  {data.IvfCircleData[1].description[0]}
                </p>
              </div>
            </span> */}
            {/* <span className="stat-circle-ivf-success-and-cost">
              <h6 className="circle-heading-ivf-success-and-cost">
                {data.IvfCircleData[2].label}
              </h6>
              <div className="circle-description-ivf-success-and-cost">
                <p className="circle-para-ivf-success-and-cost">
                  {data.IvfCircleData[2].description[0]}
                </p>
                <p className="circle-para-ivf-success-and-cost">
                  {data.IvfCircleData[2].description[1]}
                </p>
              </div>
            </span> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
};

export default IvfSuccessAndCost;
