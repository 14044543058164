import React, { useState } from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import ModalPage from "./ModalPage";
import { useNavigate } from "react-router-dom";

const BlogPackage = ({ data }) => {
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);

  const nav = useNavigate();

  return (
    <section className="section-wrapper">
      <div className="container">
        <div className="page-titles">{data.mainHeading}</div>
        <div className="blog-cards-container">
          <Row>
            {data.cards.map((item, index) => {
              return (
                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  xs="12"
                  className="blog-col"
                  key={index}
                >
                  <div className="blog-card">
                    <div className="blog-card-top">
                      <span>
                        <img
                          src={item.img}
                          alt=""
                          className="blog-card-img"
                          style={{ borderRadius: "15px" }}
                        />
                      </span>
                    </div>
                    <div className="blog-card-bottom">
                      <div
                        className="blog-card-title"
                        onClick={() => {
                          nav("/connect#quick-touchpoint-section");
                          // setModalData(item);
                          // setShow(true);
                        }}
                      >
                        {item.title}
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
            <ModalPage show={show} modalData={modalData} setShow={setShow} />
          </Row>
        </div>
      </div>
    </section>
  );
};

export default BlogPackage;
