import React from "react";
import Layout from "./Layout";
import { Routes, Route } from "react-router-dom";
import AboutOMA from "./pages/AboutOMA";
import Home from "./pages/Home";
import Maternity from "./pages/Maternity";
import Gynecology from "./pages/Gynecology";
import AestheticGynecology from "./pages/AestheticGynecology";
import Ivf from "./pages/Ivf";
import OncoGynecology from "./pages/OncoGynecology";
import Radiology from "./pages/Radiology";
import Nicu from "./pages/Nicu";
import LabTest from "./pages/LabTest";
import Vaccination from "./pages/Vaccination";
import VirtualConsulting from "./pages/VirtualConsulting";
import Resources from "./pages/Resources";
import OMACommunity from "./pages/OMACommunity";
import LetsConnect from "./pages/LetsConnect";
import OurInfrastructure from "./pages/OurInfrastructure";
import FoundingDoctor from "./pages/FoundingDoctor";

const App = () => {
  // useEffect(() => {
  //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
  // }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutOMA />} />
        <Route path="/founding-doctor" element={<FoundingDoctor />} />
        <Route path="/maternity" element={<Maternity />} />
        <Route path="/gynecology" element={<Gynecology />} />
        <Route
          path="/aesthetic-&-regenerative-gynecology"
          element={<AestheticGynecology />}
        />
        <Route path="/ivf-&-fertility" element={<Ivf />} />
        <Route path="/onco-gynecology" element={<OncoGynecology />} />
        <Route path="/radiology-&-diagnostics" element={<Radiology />} />
        <Route path="/neonatology-department" element={<Nicu />} />
        {/* <Route path="/labtest" element={<LabTest />} /> */}
        {/* <Route path="/vaccination" element={<Vaccination />} /> */}
        {/* <Route path="/virtual" element={<VirtualConsulting />} /> */}
        <Route path="/resources" element={<Resources />} />
        {/* <Route path="/community" element={<OMACommunity />} /> */}
        <Route path="/connect" element={<LetsConnect />} />
        <Route path="/infrastructure" element={<OurInfrastructure />} />
      </Routes>
    </Layout>
  );
};

export default App;
