// import AboutGynecology from "../../assets/img/AboutGynecology.png";
import AboutGynecology from "../../assets/img/AboutGynecology1.png";
// import coverImg2 from "../../assets/img/CoverImg2.png";
import coverImg2 from "../../assets/img/CoverImg21.png";
import TanujaUchil from "../../assets/img/TanujaUchil.png";
// import HarshMehta from "../../assets/img/HarshMehta.png";
// import AnitaSharma from "../../assets/img/AnitaSharma.png";
import ComponentImg from "../../assets/img/complexgynecology.png";

import GynecologyImg1 from "../../assets/img/GynecologyBanner1.jpg";
// import GynecologyImg2 from "../../assets/img/GynecologyBanner2.jpg";
import GynecologyImg3 from "../../assets/img/GynecologyBanner3.jpg";
import GynecologyImage2 from "../../assets/img/GynecologyInfra2.png";
import DefaultAvatar from "../../assets/img/DefaultAvatar.jpeg";

export let AboutOfferingData = {
  mainHeading: "GYNECOLOGY",
  subHeadeing: "The best care for the best version of you",
  description: [
    "We at Oma cater to women of every life stage and care for a wide range of gynecological issues, including those related to adolescence, premarital counseling, early pregnancy, sexual health, breast care, hysterectomy, urinary incontinence, and more. Women's healthcare is not a one-size-fits-all subject. Every woman is different and so are their healthcare needs. Women's health is dynamic and influenced by various factors that shape their care requirements. We pay heed to every woman to understand their lifestyle, needs and desires to give them the focused care they demand.",
    "We are dedicated to providing the most modern care for our patients, based on the most recent research, and supplemented by the most innovative technology.",
  ],
  img: AboutGynecology,
};

export let CommonBackgroundImage = {
  img: coverImg2,
};

export let servicesData = {
  mainHeading: "Our Services",
  services: [
    {
      key: 1,
      service:
        "Well-Woman Exams: Routine preventive care including pelvic and breast exams, and Pap smears.",
    },
    {
      key: 2,
      service:
        "Family Planning and Contraception: Birth control counseling and options like IUDs and hormonal implants.",
    },
    {
      key: 3,
      service:
        "Gynecological Surgery: Minimally invasive and major surgeries, including hysterectomy and fibroid removal.",
    },
    {
      key: 4,
      service:
        "Menopause Management: Support for managing menopausal changes and hormone therapy.",
    },
    {
      key: 5,
      service:
        "Treatment of Gynecological Conditions: Diagnosis and treatment of conditions like endometriosis and PCOS.",
    },
    {
      key: 6,
      service:
        "Minimally Invasive Procedures: Procedures like laparoscopy and hysteroscopy for quicker recovery.",
    },
    {
      key: 7,
      service:
        "Gynecological Ultrasound: Imaging for pelvic organs and pregnancy monitoring.",
    },
    {
      key: 8,
      service:
        "Sexual Health and Dysfunction: Addressing sexual health issues and counseling.",
    },
    {
      key: 9,
      service:
        "Pelvic Floor Health: Treatment for urinary incontinence and prolapse.",
    },
    {
      key: 10,
      service:
        "Menstrual Disorders: Diagnosis and treatment of menstrual issues.",
    },
  ],
};

export let GynecologyDoctorsData = {
  mainHeading: "Meet the Doctors",
  description: [
    "Our Specialists are only the Best and Most Experienced Doctors who ensure that you are truly cared for and have a stress free  experience.",
  ],
  ifquestion: true,
  question: "Got a question? Leave no room for doubt. ",
  button: "Contact Us",
  doctors: [
    {
      img: TanujaUchil,
      name: "Dr. Tanuja Uchil",
      // role: "Role: Radiologist and Ultrasonologist",
      experience: [
        "MBBS, DGO, DNB - Obstetrics & Gynecology, MNAMS - Obstetrician and Gynecologist",
      ],
      description: [
        "Dr. Tanuja Uchil serves as a full-time consultant in Obstetrics and Gynecology at OMA Hospital. Dr. Uchil's journey in the field of medicine is characterized by excellence, expertise, and a genuine commitment to women's health.",
        "Educational Background: Dr. Uchil's academic journey commenced with her MBBS degree from Seth G S Medical College & KEM Hospital in 1997 followed by her role as a resident medical officer specializing in Obstetrics and Gynecology at the Nowrosjee Wadia Maternity Hospital and pursuing the degree of MD in Obstetrics & Gynecology from Seth G S Medical College & KEM Hospital, University of Mumbai.",
        "",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Prasad Mahadevan",
      role: "Gynecologist",
      experience: [
        "MBBS (1990) - Rajiv Gandhi University of Health Sciences, Bangalore",
        "DGO (1992) -  Kasturba Medical College",
        "MD - Obstetrics & Gynaecology (1993) - Kasturba Medical College in 1993",
      ],
      description: [
        "Dr. Prasad Mahadevan is a highly skilled gynecologist with a passion for women's health. With over three decades of experience, he is known for his expertise in obstetrics and Gynecology. Dr. Mahadevan's commitment to patient well-being, along with his involvement in various medical associations, highlights his dedication to women's healthcare.",
      ],
    },
    {
      img: DefaultAvatar,
      name: "Dr. Keshav Pai",
      role: "Gynecologist and Laparoscopic Surgeon",
      experience: ["M.B.B.S, MD / MS, DNB, D.G.O"],
      description: [
        "Dr. Keshav Pai is a distinguished gynecologist and laparoscopic surgeon with a strong academic background. He completed his post-graduation at LTMMC and LTMGH and received specialized training in laparoscopy in Belgium. Dr. Pai's special interests include fertility-enhancing surgery and minimally invasive procedures like laparoscopic hysterectomy. His contributions to research, medical publications, and his dedication to patient care make him a sought-after gynecologist.",
      ],
    },
  ],
};

// export let AmbienceData = {
//   heading: "Global Standards Meet Unparalleled Support",
//   description: [
//     "Oma has modern operating rooms to handle all kinds of treatments with meticulous attention to detail for patients well-being. These OTs have been designed to ensure that the patients are treated in the most hygienic and secure environment - eliminating the possibilities of infections during/after the procedure.",
//   ],

// //
// //
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         // text: "G-Spot Amplification",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Platelet-Rich Plasma (PRP) Therapy: Revitalize tissues.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "G-Spot Amplification: Enhance sexual pleasure.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Clitoral Hood Reduction: Reduce excess skin.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Labial Rejuvenation (Labiaplasty): Reshape and resize labia.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Laser Vaginal Rejuvenation: Minimally invasive laser treatment.",
// //       },
// //       {
// //         class: "ag-list-header right-alignT ag-left-hover",
// //         textSpan: "header-text-1 ag-text-left-side",
// //         text: "Vaginal Rejuvenation: Restore vaginal appearance, tone, and function.",
// //       },
// //       {},
// //       {},
// //       {},
// //       {
// //         // class: "ag-list-header left-alignT ag-right-hover",
// //         // textSpan: "header-text-2",
// //         // text: "SURGICAL OPTIONS",
// //       },
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         text: "Stress Urinary Incontinence Treatment: Address urinary incontinence with non-surgical options.",
// //       },
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         text: "Non-Surgical Vaginal Tightening: Improve tone and tightness without surgery.",
// //       },
// //     ],

// //     "1.OT boasts of being a ‘Certified Class 10000 clean room’",

// //     "2.The walls are made of stainless steel which further enhances the sterile and safe environment for surgical procedures.",

// //     "3. Exceptional air circulation technology ensures a constant supply of fresh air, instead of circulating stale air.",
// //     description: [
// //     "Not only the patients, these OTs have been designed to ensure that the surgeons have the most efficient & effective work-flow too, with:",
// //     ],

// //     circleData: [
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         text: "Scar Revision: Enhance the appearance of surgical scars.",
// //       },
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         text: "Intimate Skin Lightening: Lighten genital skin using laser therapy and topicals.",
// //       },
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         text: "Vaginal Health and Wellness Consultations: Comprehensive care, sexual wellness, and preventative advice.",
// //       },
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         // text: "Clitoral Hoodectomy",
// //       },
// //       {},
// //       {},
// //       {},
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         // text: "G-Spot Amplification",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Platelet-Rich Plasma (PRP) Therapy: Revitalize tissues.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "G-Spot Amplification: Enhance sexual pleasure.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Clitoral Hood Reduction: Reduce excess skin.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Labial Rejuvenation (Labiaplasty): Reshape and resize labia.",
// //       },
// //       {
// //         class: "dot-container right-alignT ag-left-hover",
// //         textSpan: "ag-text-left-side ag-circle-text",
// //         text: "Laser Vaginal Rejuvenation: Minimally invasive laser treatment.",
// //       },
// //       {
// //         class: "ag-list-header right-alignT ag-left-hover",
// //         textSpan: "header-text-1 ag-text-left-side",
// //         text: "Vaginal Rejuvenation: Restore vaginal appearance, tone, and function.",
// //       },
// //       {},
// //       {},
// //       {},
// //       {
// //         // class: "ag-list-header left-alignT ag-right-hover",
// //         // textSpan: "header-text-2",
// //         // text: "SURGICAL OPTIONS",
// //       },
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         text: "Stress Urinary Incontinence Treatment: Address urinary incontinence with non-surgical options.",
// //       },
// //       {
// //         class: "dot-container left-alignT ag-right-hover",
// //         textSpan: "ag-circle-text",
// //         text: "Non-Surgical Vaginal Tightening: Improve tone and tightness without surgery.",
// //       },
// //     ],
// //     "1.State-of-the-Art OT Lights from Steris, France",

// //     "2.OT Table from Maquet, Germany",

// //     "3.Anesthesia System from Belgium",

// //     "4.Ceiling supply pendants for hassle-free movement in the OT – From Mindray",

// // };
// }

export let AmbienceData = {
  heading: "Global Standards Meet Unparalleled Support.",
  mainHeading: "Global Standards Meet Unparalleled Support.",
  subHeading1:
    "Oma has modern operating rooms to handle all kinds of treatments with meticulous attention to detail for patients well-being. These OTs have been designed to ensure that the patients are treated in the most hygienic and secure environment - eliminating the possibilities of infections during/after the procedure.",

  subHeading2:
    "Not only the patients, these OTs have been designed to ensure that the surgeons have the most efficient & effective work-flow too, with:",

  services1: [
    {
      key: 1,
      service:
        "The walls are made of stainless steel which further enhances the sterile and safe environment for surgical procedures.",
    },
    {
      key: 2,
      service: `Exceptional air circulation technology ensures a constant supply of fresh air, instead of circulating stale air.`,
    },
    {
      key: 3,
      service: `OT boasts of being a ‘Certified Class 10000 clean room’`,
    },
  ],

  services2: [
    {
      key: 1,
      service: `State-of-the-Art OT Lights from Steris, France`,
    },
    {
      key: 2,
      service: "OT Table from Maquet, Germany",
    },
    {
      key: 3,
      service: `Anesthesia System from Belgium`,
    },
    {
      key: 4,
      service: `Ceiling supply pendants for hassle-free movement in the OT From Mindray`,
    },
  ],
};
export let AmbienceImages = [
  {
    src: GynecologyImg1,
  },
  {
    src: GynecologyImage2,
  },
  {
    src: GynecologyImg3,
  },
];

export let GynecologyCircleData = {
  mainHeading: "IT IS TIME TO SEE A GYNECOLOGIST IF YOU NOTICE",
  circleImg: ComponentImg,
  circleData: [
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      text: "Vaginal discharge with an unpleasant or unusual odour or discharge",
    },
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      text: "Pain or discomfort during intercourse",
    },
    {},
    {},
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      text: "Pelvic pain or pressure not caused by menstruation",
    },
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      text: "Abnormal vaginal bleeding, especially during or after sexual activity",
    },
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      text: "Frequent, urgent urination or burning during urination",
    },
    {},
    {
      class: "dot-container right-alignT",
      textSpan: "circle-text text-left-side",
      text: "Bleeding between periods",
    },
    {},
    {},
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      text: "Itching, burning, swelling, redness,\n or soreness in the vaginal area",
    },
    {},
    {
      class: "dot-container left-alignT",
      textSpan: "circle-text",
      text: "Increased vaginal discharge",
    },
  ],
  responsiveCircleData: [
    {
      positionClass: "bottom",
      text: "Itching, burning, swelling, redness,\n or soreness in the vaginal area",
    },
    {
      positionClass: "bottom",
      text: "Sores or lumps in the genital area /\n Increased Vaginal Discharge",
    },
    {
      positionClass: "bottom",
      text: "Vaginal discharge with an unpleasant or\n unusual odor, or of an unusual color",
    },
    {
      positionClass: "bottom",
      text: "Pain or discomfort during\n intercourse",
    },
    {
      positionClass: "top",
      text: "Pain or pressure in your pelvis that\n differs from menstrual cramps",
    },
    {
      positionClass: "top",
      text: "Abnormal vaginal bleeding, particularly\n during or after intercourse",
    },
    {
      positionClass: "top",
      text: "Frequent and urgent need to\n urinate, or a burning sensation\n during urination",
    },
    {
      positionClass: "top",
      text: "Bleeding between periods",
    },
  ],
  contentDesc:
    "Early diagnosis and treatment increase the likelihood of successful outcomes",
};
