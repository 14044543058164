import React from "react";
import OurServices from "../../component/OurServices";
import CoreTeam from "../../component/CoreTeam";
import AboutOffering from "../../component/AboutOffering";
import BgCoverImg from "../../component/BgCoverImg";
import Footer from "../../common/Footer";
import {
  AboutOfferingData,
  aestheticGynecologyCircleData,
  CommonBackgroundImage,
  AsGynecologyDoctorsData,
  AestheticGynecologyData,
  servicesData,
  services,
} from "./data";
import ComplexCommon from "../../component/ComplexComponent";

const AestheticGynecology = () => {
  return (
    <>
      <AboutOffering data={AboutOfferingData} />
      {/* <ComplexCommon
        data={aestheticGynecologyCircleData}
        circleCount={"twelve"}
      /> */}
      {/* <OurServices data={aestheticGynecologyData}  noofcolumns= "2"/> */}
      <OurServices
        noofcolumns="2"
        data={AestheticGynecologyData}
        items={services}
        pinkBg
      />
      <BgCoverImg data={CommonBackgroundImage} />
      <CoreTeam data={AsGynecologyDoctorsData} slidesToShow={1} />
      <Footer />
    </>
  );
};

export default AestheticGynecology;
