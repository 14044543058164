import React from "react";
import {
  BookingVirtualConsultingData,
  VirtualConsultingData,
  FaqsData,
} from "./data";
import BookingPage from "../../component/BookingPage";
import BookingForm from "../../component/BookingForm";
import HomeServicesCircle from "../../component/HomeServicesCircle";
import Faqs from "../../component/Faqs";
import Footer from "../../common/Footer";

const VirtualConsulting = () => {
  const pageForm = {
    title: "Book Your Service",
    formType: "VC",
    axios_url: "",
    subject:"Booking for Virtual Consulting"
  };

  return (
    <>
      <BookingPage
        data={BookingVirtualConsultingData}
        form={<BookingForm pageForm={pageForm} />}
      />
      <HomeServicesCircle
        data={VirtualConsultingData}
        circleCount={"virtual-consulting"}
        rightContainerHeight={"virtual-circle-height"}
      />
      <Faqs data={FaqsData} />
      <Footer />
    </>
  );
};

export default VirtualConsulting;
