import React from "react";
import "./style.scss";

const AboutOffering = ({ data, form, circleSize }) => {
  return (
    <section className="section-wrapper about-offering-section">
      <div className="container">
        <div className="content-wrapper">
          <div className="about-offering-main">
            <div className="about-offering-left">
              <div className="template-heading page-heading">
                {data.mainHeading}
              </div>
              <div className="template-heading">{data.subHeadeing}</div>
              {data.description.map((desc) => {
                return <p className="template-content">{desc}</p>;
              })}
            </div>
            <div className="about-offering-right">
              <img
                src={data.img}
                alt=""
                className={`about-offering-img ${circleSize}`}
              />
            </div>
          </div>
          <div className="fertility-form">{form}</div>
        </div>
      </div>
    </section>
  );
};

export default AboutOffering;
