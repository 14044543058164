export let connectPageData = [
  {
    title: "LET'S CONNECT",
    desc: "OMA IS A CLICK AWAY FOR WORLD-CLASS CARE",
  },
];

export let quickPointData = [
  {
    mainHeading: "CLICK. CONNECT. CARE",
    description: [
      "Experience unparalleled care with state-of-the-art equipment and technology right here at Oma. If you are looking to consult with one of our doctors or seeking help for a procedure, reach out to us and allow us to guide you in your medical journey.",
      "Fill out your details, and we will contact you shortly. Alternatively, feel free to call us.",
    ],
    contacts: ["7208973301", "9136956680"],
  },
];

export let openingsData = [
  {
    Domain: "Medical",
    data: [
      "Registrar/ Clinical Assistant/ Associate - Paediatric",
      "Senior Clinical Associate for Surgical Oncology",
      "Full Time Consultancy - Gynecology",
    ],
  },
  {
    Domain: "Nurses",
    data: ["WalkIn-interview for Nurses", "ICU In-Charge"],
  },
  {
    Domain: "Non-Medical",
    data: [
      "Supervisor / Officer - Purchase",
      "Lab Clerk",
      "Radiation Oncology coordinator",
    ],
  },
];

export let openingData = [
  {
    title: 'Being a part of "OMA Family"',
    desc: [
      "We are looking for individuals who shall help us further the vision of our core leaders.Individuals who are passionate about what they do, do it selflessly and are proud of the difference it makes in the lives of others.",
      "Individuals who let their work speak for them. Individuals for whom the pursuit of excellence never ends.",
    ],
  },
];
