import React from "react";
import "./style.scss";

const BgCoverImg = ({ data }) => {
  return (
    <section className="bg-cover-section">
      <img src={data.img} alt="" />
    </section>
  );
};

export default BgCoverImg;
