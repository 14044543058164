import React, { useState } from "react";
import "./style.scss";
import "../../assets/scss/_variable.scss";
import Slider from "react-slick";
import { Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import KnowMore from "../../common/Modal/CoreTeam/KnowMore";

const CoreTeam = ({
  data,
  slidesToShow,
  hideButton,
  KnowMoreFoundingDoctor,
  navlink,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [doctor, setDoctor] = useState({});

  const nav = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow || 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: slidesToShow || 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
    customPaging: (i) => <img src={data.doctors[i].img} alt="" />,
  };
  return (
    <>
      {/* <section
        className="section-wrapper core-team-section bottom-common-effect"
        id="{props.data[0].id}"
      > */}
      <section
        className="section-wrapper core-team-section bottom-common-effect"
        id="aboutus-core-team-page"
      >
        <div className="container">
          <div className="content-wrapper">
            <div className="page-titles">{data?.mainHeading}</div>
            {data?.description && (
              <p className="page-content">{data?.description}</p>
            )}
            <div
              className={
                data?.ifquestion
                  ? "coreTeam-members with-question"
                  : "coreTeam-members"
              }
            >
              <Slider {...settings}>
                {data?.doctors?.map((member, index) => {
                  return (
                    <div className="coreTeam-member" key={index}>
                      <img
                        src={member?.img}
                        alt=""
                        className="coreMember-image"
                      />
                      <h3 className="coreMember-name">{member?.name}</h3>
                      <h5 className="coreMember-role">{member?.role}</h5>
                      {/* <div className="coreMember-description">
                        {member?.about}
                      </div> */}
                      {member?.experience?.map((desc) => {
                        return <p className="coreMember-exp">{desc}</p>;
                      })}
                      <div className="linkedin-profile">
                        <Link to="/">
                          <span>
                            <i className="fa-brands fa-linkedin-in"></i>
                          </span>
                        </Link>
                      </div>

                      {!hideButton && (
                        <div className="know-more">
                          <button
                            className="know-more-button"
                            onClick={() => {
                              setDoctor(member);
                              setModalShow(true);
                            }}
                          >
                            Know More
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
            {data?.descriptionBottom && (
              <p className="page-content">{data?.descriptionBottom}</p>
            )}
            {data?.ifquestion ? (
              <div className="core-team-bottom">
                <div className="core-team-ask-question">
                  <div className="page-titles ask-question">
                    {data?.question}
                  </div>
                  <HashLink to="/connect/#connect-page-container">
                    <Button color="secondary">{data?.button}</Button>
                  </HashLink>
                </div>
              </div>
            ) : (
              <></>
            )}
            {KnowMoreFoundingDoctor && (
              <div className="know-more-wrapper-button">
                <button
                  className="know-more-button"
                  onClick={() => {
                    nav(navlink);
                    window.scroll(0, 0);
                  }}
                >
                  Know More
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      <KnowMore
        modalShow={modalShow}
        setModalShow={setModalShow}
        doctor={doctor}
      />
    </>
  );
};

export default CoreTeam;
