import React from "react";
import OurServices from "../../component/OurServices";
import CoreTeam from "../../component/CoreTeam";
import AboutOffering from "../../component/AboutOffering";
import BgCoverImg from "../../component/BgCoverImg";
import Ambience from "../../component/Ambience";
import ComplexCommon from "../../component/ComplexComponent";
import {
  AboutOfferingData,
  CommonBackgroundImage,
  AmbienceData,
  AmbienceImages,
  GynecologyDoctorsData,
  GynecologyCircleData,
  servicesData,
} from "./data";
import Footer from "../../common/Footer";

const Gynecology = () => {
  return (
    <>
      <AboutOffering data={AboutOfferingData} />
      <ComplexCommon data={GynecologyCircleData} circleCount={"eight"} />
      <BgCoverImg data={CommonBackgroundImage} />
      <OurServices data={servicesData} />
      <CoreTeam data={GynecologyDoctorsData} />
      <Ambience data={AmbienceData} items={AmbienceImages}  noofcolumns="2"  gynac={true}/>
      <Footer />
    </>
  );
};

export default Gynecology;
