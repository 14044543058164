export let appointment = {
  departments: [
    "Maternity",
    "Gynecology",
    "AestheticGynecology",
    "Ivf",
    "Fertility",
  ],
  doctors: {
    Maternity: ["M", "Mat"],
    Gynecology: ["G", "Gyne"],
    AestheticGynecology: ["Aest"],
    Ivf: ["drI"],
    Fertility: ["Tanuja", "Fertility"],
  },
  time_slots: {
    M: ["10-11", "11-12"],
    Mat: ["12-1", "1-2", "2-3"],
    G: ["3-4"],
    Gyne: ["4-5", "5-6", "6-7"],
    Aest: ["10-11", "11:00-12:00", "12:00-11:00"],
    drI: ["drI", "11:00-12:00", "12:00-11:00"],
    Tanuja: ["10:00-11:00", "11:00-12:00", "12:00-11:00"],
    Fertility: ["fertility", "11:00-12:00", "12:00-11:00"],
  },
  description: {
    M: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius nisi dolorum vitae quidem! Recusandae porro ut iure quae.",
    ],
    Mat: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius nisi dolorum vitae quidem! Recusandae porro ut iure quae.",
    ],
    G: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius nisi dolorum vitae quidem! Recusandae porro ut iure quae.",
    ],
    Gyne: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius nisi dolorum vitae quidem! Recusandae porro ut iure quae.",
    ],
    Aest: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius nisi dolorum vitae quidem! Recusandae porro ut iure quae.",
    ],
    drI: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius nisi dolorum vitae quidem! Recusandae porro ut iure quae.",
    ],
    Tanuja: [
      " MD. Obstetrics & Gynaecology, MBBS Gynaecologist,Infertility Specialist, Obstetrician, Laparoscopic Surgeon(Obs & Gyn) 25 Years Experience Overall (20 years as Specialist)",
    ],
    Fertility: [
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius nisi dolorum vitae quidem! Recusandae porro ut iure quae.",
    ],
  },
};
