import React from "react";
import { Col, Row } from "reactstrap";
import "./style.scss";

const Openings = (props) => {
  return (
    <section className="section-wrapper bottom-common-effect">
      <div className="container">
        <div className="openings-main-container">
          <div className="openings-top">
            <div className="page-titles">COME, BE A PART OF THE OMA FAMILY</div>
            <div className="oma-family-content">
              <div className="page-content">
                We, at OMA, are always on the lookout for talented individuals
                who can help advance the goals set by our core leaders.
              </div>
              <div className="page-content">
                If you are a medical professional looking to give your career a
                boost with some of the best medical minds in Mumbai, then Oma is
                the place for you. We value individuals who love what they do,
                care for others without expecting anything in return, and have a
                passion for healing lives. Join the Oma team and together let’s
                make the world better.
              </div>
            </div>
          </div>

          <div className="openings-bottom">
            <div className="opeings-bottom-heading">
              <h5>Below mentioned are the current opening</h5>
            </div>
            <div className="openings-cards-container">
              <Row>
                {props.data.map((d) => {
                  return (
                    <>
                      <Col xs="12" sm="12" md="4">
                        <div className="openings-card">
                          <div className="openings-title">{d.Domain}</div>
                          <div className="openings-description-container">
                            {d.data.map((subdata) => {
                              return (
                                <>
                                  <div className="openings-description template-content">
                                    {subdata}
                                    <hr className="line" />
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })}
                {/* <Col xs="12" sm="4">
                <div className="openings-card">
                  <div className="openings-title">Medical</div>
                  <div className="openings-description template-content">
                    Registrar/ Clinical Assistant/ Associate - Paediatric
                    <hr />
                    Senior Clinical Associate for Surgical Oncology
                    <hr />
                    Full Time Consultancy - Gynecology
                  </div>
                </div>
              </Col>
              <Col xs="12" sm="4">
                <div className="openings-card">
                  <div className="openings-title">Medical</div>
                  <div className="openings-description">
                    Registrar/ Clinical Assistant/ Associate - Paediatric
                    <hr />
                    Senior Clinical Associate for Surgical Oncology
                    <hr />
                    Full Time Consultancy - Gynecology
                  </div>
                </div>
              </Col> */}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Openings;
