export const Logo = () => (
  <svg
    id="Logo"
    xmlns="http://www.w3.org/2000/svg"
    width="96.15"
    height="93.633"
    viewBox="0 0 96.153 93.633"
  >
    <path
      id="Path_1"
      data-name="Path 1"
      d="M418.145,55.6a6.747,6.747,0,0,0-5.126-2.4,7.708,7.708,0,0,0-4.979,2.383l-9.432,9.257L408.04,74.1a7.706,7.706,0,0,0,4.979,2.385,6.747,6.747,0,0,0,5.126-2.4l9.32-9.245Z"
      transform="translate(-348.935 -34.116)"
      fill="#dd003b"
    />
    <path
      id="Path_2"
      data-name="Path 2"
      d="M364.926,55.6a6.754,6.754,0,0,0-5.128-2.4,7.721,7.721,0,0,0-4.981,2.383l-9.43,9.257,9.43,9.257a7.719,7.719,0,0,0,4.981,2.385,6.754,6.754,0,0,0,5.128-2.4l9.319-9.245Z"
      transform="translate(-328.305 -34.116)"
      fill="#dd003b"
    />
    <path
      id="Path_3"
      data-name="Path 3"
      d="M378.947,31.356a6.751,6.751,0,0,0-2.394,5.128,7.71,7.71,0,0,0,2.382,4.979l9.258,9.431,9.256-9.431a7.709,7.709,0,0,0,2.383-4.979,6.751,6.751,0,0,0-2.394-5.128l-9.245-9.32Z"
      transform="translate(-340.386 -22.036)"
      fill="#dd003b"
    />
    <path
      id="Path_4"
      data-name="Path 4"
      d="M330.637,114.563q6.733,0,9.938,3.205a11.54,11.54,0,0,1,3.205,8.538,14.22,14.22,0,0,1-.781,4.821,9.435,9.435,0,0,1-2.4,3.717,10.9,10.9,0,0,1-4.093,2.371,21.086,21.086,0,0,1-11.744,0,10.9,10.9,0,0,1-4.094-2.371,9.422,9.422,0,0,1-2.4-3.717,14.205,14.205,0,0,1-.781-4.821,11.536,11.536,0,0,1,3.206-8.538q3.2-3.2,9.938-3.205m0,18.692a12.711,12.711,0,0,0,2.666-.269,5.42,5.42,0,0,0,2.236-1.023,5.3,5.3,0,0,0,1.562-2.1,10.887,10.887,0,0,0,0-7.083,5.494,5.494,0,0,0-1.562-2.154,5.269,5.269,0,0,0-2.236-1.05,13.327,13.327,0,0,0-5.333,0,5.259,5.259,0,0,0-2.235,1.05,5.477,5.477,0,0,0-1.562,2.154,10.877,10.877,0,0,0,0,7.083,5.283,5.283,0,0,0,1.562,2.1,5.41,5.41,0,0,0,2.235,1.023,12.707,12.707,0,0,0,2.666.269"
      transform="translate(-317.493 -57.901)"
      fill="#dd003b"
    />
    <path
      id="Path_5"
      data-name="Path 5"
      d="M397.37,116.059v21.654h-6.625V121.692l-5.926,16.021h-6.356v-.054l-6.141-15.967v16.021H365.7V117.405a1.29,1.29,0,0,1,1.346-1.346h8.565l5.925,15.352q.7-2.047,1.616-4.471t1.724-4.552q.807-2.127,1.4-3.609t.646-1.643a1.8,1.8,0,0,1,.646-.916,2.773,2.773,0,0,1,1.131-.162Z"
      transform="translate(-336.178 -58.481)"
      fill="#dd003b"
    />
    <path
      id="Path_6"
      data-name="Path 6"
      d="M445.41,135.235a2.514,2.514,0,0,0-.727-.916,2.4,2.4,0,0,0-1.051-.162H432.844l1.994-3.878a3.25,3.25,0,0,1,.7-.889,2.084,2.084,0,0,1,1.078-.189h5.563l-4.309-8.134-8.619,16.645h-7.434q3.017-5.6,5.441-10.074,1.023-1.885,2.02-3.717t1.778-3.313q.78-1.481,1.293-2.424a8.514,8.514,0,0,1,.619-1.05,2.711,2.711,0,0,1,.7-.916,2.362,2.362,0,0,1,1.077-.162h7.433l11.905,21.654H446.7Z"
      transform="translate(-357.93 -58.481)"
      fill="#dd003b"
    />
    <path
      id="Path_7"
      data-name="Path 7"
      d="M323.066,172.068a.172.172,0,0,1-.039.073.167.167,0,0,1-.071.042.555.555,0,0,1-.111.021c-.044,0-.1.006-.162.006s-.122,0-.168-.006a.57.57,0,0,1-.115-.021.149.149,0,0,1-.067-.042.184.184,0,0,1-.035-.073l-.871-3.141h-.007l-.8,3.141a.16.16,0,0,1-.032.073.142.142,0,0,1-.064.042.5.5,0,0,1-.108.021c-.044,0-.1.006-.168.006s-.129,0-.177-.006a.56.56,0,0,1-.115-.021.159.159,0,0,1-.07-.042.155.155,0,0,1-.034-.073l-1.1-3.841a.59.59,0,0,1-.021-.116.074.074,0,0,1,.026-.064.167.167,0,0,1,.088-.029c.04,0,.1-.006.164-.006s.119,0,.157,0a.352.352,0,0,1,.089.018.077.077,0,0,1,.042.036.215.215,0,0,1,.018.057l.935,3.485h0l.888-3.478a.2.2,0,0,1,.024-.059.09.09,0,0,1,.047-.037.36.36,0,0,1,.092-.02,1.519,1.519,0,0,1,.156-.006,1.255,1.255,0,0,1,.143.006.348.348,0,0,1,.086.02.1.1,0,0,1,.047.037.27.27,0,0,1,.024.059l.955,3.478h.006l.916-3.481c.007-.021.013-.04.02-.056a.068.068,0,0,1,.04-.037.328.328,0,0,1,.087-.02,1.375,1.375,0,0,1,.149-.006,1.211,1.211,0,0,1,.152.008.146.146,0,0,1,.082.031.076.076,0,0,1,.024.064.846.846,0,0,1-.017.116Z"
      transform="translate(-317.972 -78.619)"
      fill="#030404"
    />
    <path
      id="Path_8"
      data-name="Path 8"
      d="M337.07,170.036a3.214,3.214,0,0,1-.119.91,1.906,1.906,0,0,1-.355.69,1.548,1.548,0,0,1-.593.437,2.1,2.1,0,0,1-.833.153,2.155,2.155,0,0,1-.811-.14,1.434,1.434,0,0,1-.566-.407,1.741,1.741,0,0,1-.333-.663,3.454,3.454,0,0,1-.109-.91,3.126,3.126,0,0,1,.119-.893,1.852,1.852,0,0,1,.356-.683,1.584,1.584,0,0,1,.594-.434,2.1,2.1,0,0,1,.833-.153,2.129,2.129,0,0,1,.8.138,1.445,1.445,0,0,1,.567.4,1.714,1.714,0,0,1,.338.656,3.3,3.3,0,0,1,.111.9m-.585.039a3.258,3.258,0,0,0-.062-.658,1.5,1.5,0,0,0-.208-.527,1.008,1.008,0,0,0-.39-.349,1.316,1.316,0,0,0-.6-.126,1.24,1.24,0,0,0-.6.135,1.135,1.135,0,0,0-.4.361,1.48,1.48,0,0,0-.218.525,2.93,2.93,0,0,0-.066.631,3.407,3.407,0,0,0,.062.675,1.513,1.513,0,0,0,.2.532.983.983,0,0,0,.388.347,1.321,1.321,0,0,0,.608.124,1.262,1.262,0,0,0,.611-.135,1.087,1.087,0,0,0,.4-.364,1.542,1.542,0,0,0,.216-.532,3.11,3.11,0,0,0,.064-.64"
      transform="translate(-323.639 -78.592)"
      fill="#030404"
    />
    <path
      id="Path_9"
      data-name="Path 9"
      d="M350.236,172.12a.08.08,0,0,1-.013.045.093.093,0,0,1-.047.031.519.519,0,0,1-.084.02,1.193,1.193,0,0,1-.265,0,.485.485,0,0,1-.086-.02.09.09,0,0,1-.045-.031.075.075,0,0,1-.013-.045V168.5h-.006l-1.466,3.639a.1.1,0,0,1-.027.038.136.136,0,0,1-.051.028.349.349,0,0,1-.081.017c-.031,0-.067,0-.111,0a1.039,1.039,0,0,1-.116-.006.49.49,0,0,1-.081-.018.147.147,0,0,1-.049-.028.078.078,0,0,1-.024-.035l-1.4-3.639h0v3.623a.08.08,0,0,1-.013.045.1.1,0,0,1-.047.031.532.532,0,0,1-.086.02,1.015,1.015,0,0,1-.135.007.994.994,0,0,1-.133-.007.475.475,0,0,1-.084-.02.081.081,0,0,1-.043-.031.09.09,0,0,1-.012-.045v-3.826a.231.231,0,0,1,.072-.193.249.249,0,0,1,.16-.058h.339a.77.77,0,0,1,.183.02.391.391,0,0,1,.137.061.356.356,0,0,1,.1.107.732.732,0,0,1,.069.151l1.19,3.012h.016l1.238-3a.888.888,0,0,1,.077-.164.446.446,0,0,1,.093-.108.322.322,0,0,1,.113-.058.53.53,0,0,1,.143-.018H350a.312.312,0,0,1,.093.014.2.2,0,0,1,.075.046.242.242,0,0,1,.051.077.312.312,0,0,1,.02.115Z"
      transform="translate(-328.431 -78.631)"
      fill="#030404"
    />
    <path
      id="Path_10"
      data-name="Path 10"
      d="M362.079,171.975a.7.7,0,0,1-.006.1.222.222,0,0,1-.022.073.112.112,0,0,1-.037.041.083.083,0,0,1-.047.013h-2a.225.225,0,0,1-.14-.05.206.206,0,0,1-.066-.175v-3.71a.207.207,0,0,1,.066-.175.232.232,0,0,1,.14-.05h1.974a.09.09,0,0,1,.047.013.092.092,0,0,1,.033.042.272.272,0,0,1,.021.072.592.592,0,0,1,.009.108.538.538,0,0,1-.009.1.254.254,0,0,1-.021.071.087.087,0,0,1-.033.04.085.085,0,0,1-.047.013h-1.626v1.3h1.4a.088.088,0,0,1,.047.014.126.126,0,0,1,.036.04.234.234,0,0,1,.021.07.894.894,0,0,1,.006.107.778.778,0,0,1-.006.1.229.229,0,0,1-.021.067.091.091,0,0,1-.036.037.1.1,0,0,1-.047.01h-1.4v1.489h1.648a.088.088,0,0,1,.047.013.127.127,0,0,1,.037.04.2.2,0,0,1,.022.07.77.77,0,0,1,.006.108"
      transform="translate(-333.878 -78.631)"
      fill="#030404"
    />
    <path
      id="Path_11"
      data-name="Path 11"
      d="M373.02,171.969a.254.254,0,0,1-.022.109.221.221,0,0,1-.135.116.3.3,0,0,1-.086.013h-.182a.588.588,0,0,1-.152-.018.351.351,0,0,1-.123-.064A.709.709,0,0,1,372.2,172a1.689,1.689,0,0,1-.123-.205l-1.269-2.366c-.066-.122-.133-.25-.2-.385s-.129-.264-.187-.39h-.007c0,.154.007.312.01.473s0,.32,0,.479v2.511a.081.081,0,0,1-.014.043.086.086,0,0,1-.046.032.515.515,0,0,1-.085.02,1,1,0,0,1-.134.007,1.014,1.014,0,0,1-.135-.007.473.473,0,0,1-.083-.02.1.1,0,0,1-.044-.032.08.08,0,0,1-.013-.043v-3.838a.214.214,0,0,1,.073-.183.26.26,0,0,1,.159-.055h.273a.691.691,0,0,1,.16.016.343.343,0,0,1,.116.053.445.445,0,0,1,.1.1,1.368,1.368,0,0,1,.1.165l.976,1.826c.06.111.118.22.174.326s.11.211.162.314.1.2.154.3.1.2.151.3h0q-.006-.254-.008-.529c0-.183,0-.359,0-.528V168.13a.071.071,0,0,1,.013-.042.123.123,0,0,1,.046-.034.3.3,0,0,1,.085-.021,1.158,1.158,0,0,1,.137-.007,1.1,1.1,0,0,1,.129.007.273.273,0,0,1,.085.021.127.127,0,0,1,.045.034.08.08,0,0,1,.013.042Z"
      transform="translate(-337.797 -78.625)"
      fill="#030404"
    />
    <path
      id="Path_12"
      data-name="Path 12"
      d="M379.622,167.75a1.652,1.652,0,0,1-.01.182.994.994,0,0,1-.032.157.873.873,0,0,1-.059.148,1.221,1.221,0,0,1-.091.149l-.441.663a.263.263,0,0,1-.037.042.164.164,0,0,1-.051.031.313.313,0,0,1-.071.016.9.9,0,0,1-.1,0,.763.763,0,0,1-.092,0,.122.122,0,0,1-.053-.016.039.039,0,0,1-.018-.031.1.1,0,0,1,.011-.048l.427-.884v-.408a.433.433,0,0,1,.018-.135.167.167,0,0,1,.056-.08.219.219,0,0,1,.1-.038.778.778,0,0,1,.141-.01.752.752,0,0,1,.138.01.208.208,0,0,1,.095.038.17.17,0,0,1,.055.08.433.433,0,0,1,.018.135"
      transform="translate(-341.166 -78.415)"
      fill="#030404"
    />
    <path
      id="Path_13"
      data-name="Path 13"
      d="M386.388,171a1.209,1.209,0,0,1-.108.521,1.12,1.12,0,0,1-.3.388,1.3,1.3,0,0,1-.451.239,1.878,1.878,0,0,1-.557.08,1.974,1.974,0,0,1-.388-.036,2.125,2.125,0,0,1-.318-.087,1.47,1.47,0,0,1-.234-.106.6.6,0,0,1-.132-.093.226.226,0,0,1-.055-.1.564.564,0,0,1-.018-.159,1.031,1.031,0,0,1,.006-.118.339.339,0,0,1,.02-.075.086.086,0,0,1,.032-.04.09.09,0,0,1,.045-.011.26.26,0,0,1,.127.055,2.193,2.193,0,0,0,.211.118,1.971,1.971,0,0,0,.311.121,1.418,1.418,0,0,0,.419.056,1.067,1.067,0,0,0,.33-.048.762.762,0,0,0,.257-.137.58.58,0,0,0,.165-.217.7.7,0,0,0,.058-.292.548.548,0,0,0-.08-.3.823.823,0,0,0-.212-.22,1.839,1.839,0,0,0-.3-.173l-.346-.161a3.744,3.744,0,0,1-.344-.181,1.468,1.468,0,0,1-.3-.235,1.086,1.086,0,0,1-.213-.316,1.049,1.049,0,0,1-.083-.436,1.09,1.09,0,0,1,.095-.464.95.95,0,0,1,.264-.343,1.19,1.19,0,0,1,.4-.211,1.7,1.7,0,0,1,.5-.072,1.652,1.652,0,0,1,.279.025,1.811,1.811,0,0,1,.263.064,1.384,1.384,0,0,1,.22.09.567.567,0,0,1,.127.08.259.259,0,0,1,.04.048.2.2,0,0,1,.016.045.513.513,0,0,1,.009.066c0,.026,0,.059,0,.1a1.007,1.007,0,0,1,0,.1.356.356,0,0,1-.015.076.1.1,0,0,1-.027.045.065.065,0,0,1-.04.014.267.267,0,0,1-.111-.045c-.05-.031-.113-.064-.185-.1a1.611,1.611,0,0,0-.258-.1,1.147,1.147,0,0,0-.336-.047.9.9,0,0,0-.3.047.608.608,0,0,0-.212.124.487.487,0,0,0-.126.182.618.618,0,0,0-.042.225.538.538,0,0,0,.08.3.828.828,0,0,0,.214.222,1.946,1.946,0,0,0,.3.177l.348.162c.117.055.233.115.347.18a1.447,1.447,0,0,1,.3.231,1.065,1.065,0,0,1,.216.315,1.034,1.034,0,0,1,.081.429"
      transform="translate(-343.206 -78.592)"
      fill="#030404"
    />
    <path
      id="Path_14"
      data-name="Path 14"
      d="M399.585,172.108a.08.08,0,0,1-.013.045.09.09,0,0,1-.045.031.533.533,0,0,1-.086.02,1.193,1.193,0,0,1-.265,0,.5.5,0,0,1-.084-.02.083.083,0,0,1-.045-.031.076.076,0,0,1-.013-.045v-1.846h-1.893v1.846a.08.08,0,0,1-.013.045.091.091,0,0,1-.045.031.522.522,0,0,1-.085.02,1,1,0,0,1-.133.007,1.016,1.016,0,0,1-.132-.007.511.511,0,0,1-.086-.02.093.093,0,0,1-.045-.031.08.08,0,0,1-.013-.045v-3.993a.076.076,0,0,1,.013-.044.089.089,0,0,1,.045-.031.464.464,0,0,1,.086-.019,1,1,0,0,1,.132-.008.991.991,0,0,1,.133.008.471.471,0,0,1,.085.019.088.088,0,0,1,.045.031.077.077,0,0,1,.013.044v1.666h1.893v-1.666a.072.072,0,0,1,.013-.044.08.08,0,0,1,.045-.031.455.455,0,0,1,.084-.019,1.1,1.1,0,0,1,.265,0,.481.481,0,0,1,.086.019.087.087,0,0,1,.045.031.076.076,0,0,1,.013.044Z"
      transform="translate(-348.152 -78.619)"
      fill="#030404"
    />
    <path
      id="Path_15"
      data-name="Path 15"
      d="M411.486,170.036a3.214,3.214,0,0,1-.119.91,1.916,1.916,0,0,1-.355.69,1.543,1.543,0,0,1-.593.437,2.094,2.094,0,0,1-.832.153,2.152,2.152,0,0,1-.811-.14,1.431,1.431,0,0,1-.566-.407,1.74,1.74,0,0,1-.333-.663,3.429,3.429,0,0,1-.109-.91,3.126,3.126,0,0,1,.119-.893,1.861,1.861,0,0,1,.356-.683,1.581,1.581,0,0,1,.595-.434,2.1,2.1,0,0,1,.833-.153,2.128,2.128,0,0,1,.8.138,1.448,1.448,0,0,1,.568.4,1.72,1.72,0,0,1,.337.656,3.278,3.278,0,0,1,.111.9m-.585.039a3.216,3.216,0,0,0-.063-.658,1.492,1.492,0,0,0-.208-.527,1,1,0,0,0-.39-.349,1.315,1.315,0,0,0-.6-.126,1.242,1.242,0,0,0-.6.135,1.141,1.141,0,0,0-.4.361,1.492,1.492,0,0,0-.217.525,2.892,2.892,0,0,0-.067.631,3.454,3.454,0,0,0,.062.675,1.513,1.513,0,0,0,.2.532.983.983,0,0,0,.388.347,1.321,1.321,0,0,0,.608.124,1.259,1.259,0,0,0,.611-.135,1.094,1.094,0,0,0,.4-.364,1.53,1.53,0,0,0,.216-.532,3.069,3.069,0,0,0,.064-.64"
      transform="translate(-352.484 -78.592)"
      fill="#030404"
    />
    <path
      id="Path_16"
      data-name="Path 16"
      d="M422.157,171a1.2,1.2,0,0,1-.108.521,1.126,1.126,0,0,1-.3.388,1.309,1.309,0,0,1-.451.239,1.883,1.883,0,0,1-.557.08,1.969,1.969,0,0,1-.388-.036,2.087,2.087,0,0,1-.318-.087A1.477,1.477,0,0,1,419.8,172a.636.636,0,0,1-.132-.093.232.232,0,0,1-.055-.1.561.561,0,0,1-.018-.159,1.013,1.013,0,0,1,.006-.118.283.283,0,0,1,.02-.075.082.082,0,0,1,.032-.04.088.088,0,0,1,.045-.011.265.265,0,0,1,.127.055,2.053,2.053,0,0,0,.211.118,1.963,1.963,0,0,0,.311.121,1.421,1.421,0,0,0,.419.056,1.064,1.064,0,0,0,.33-.048.762.762,0,0,0,.257-.137.573.573,0,0,0,.165-.217.691.691,0,0,0,.058-.292.543.543,0,0,0-.08-.3.83.83,0,0,0-.212-.22,1.845,1.845,0,0,0-.3-.173l-.346-.161a3.743,3.743,0,0,1-.344-.181,1.485,1.485,0,0,1-.3-.235,1.087,1.087,0,0,1-.213-.316,1.049,1.049,0,0,1-.082-.436,1.08,1.08,0,0,1,.095-.464.942.942,0,0,1,.263-.343,1.19,1.19,0,0,1,.4-.211,1.7,1.7,0,0,1,.5-.072,1.65,1.65,0,0,1,.278.025,1.838,1.838,0,0,1,.263.064,1.424,1.424,0,0,1,.22.09.565.565,0,0,1,.127.08.26.26,0,0,1,.04.048.2.2,0,0,1,.016.045.42.42,0,0,1,.009.066.915.915,0,0,1,0,.1,1.007,1.007,0,0,1,0,.1.354.354,0,0,1-.015.076.093.093,0,0,1-.027.045.066.066,0,0,1-.04.014.267.267,0,0,1-.111-.045c-.05-.031-.112-.064-.185-.1a1.587,1.587,0,0,0-.258-.1,1.144,1.144,0,0,0-.336-.047.894.894,0,0,0-.3.047.611.611,0,0,0-.212.124.486.486,0,0,0-.125.182.606.606,0,0,0-.042.225.533.533,0,0,0,.08.3.816.816,0,0,0,.214.222,1.977,1.977,0,0,0,.3.177l.348.162c.118.055.234.115.347.18a1.448,1.448,0,0,1,.3.231,1,1,0,0,1,.3.744"
      transform="translate(-357.071 -78.592)"
      fill="#030404"
    />
    <path
      id="Path_17"
      data-name="Path 17"
      d="M432.484,169.261a1.468,1.468,0,0,1-.1.563,1.18,1.18,0,0,1-.295.427,1.309,1.309,0,0,1-.469.274,2.05,2.05,0,0,1-.664.1h-.472v1.5a.07.07,0,0,1-.015.045.1.1,0,0,1-.045.031.419.419,0,0,1-.083.02,1.215,1.215,0,0,1-.267,0,.473.473,0,0,1-.086-.02.091.091,0,0,1-.045-.031.08.08,0,0,1-.013-.045v-3.838A.221.221,0,0,1,430,168.1a.231.231,0,0,1,.151-.055h.891c.089,0,.176,0,.258.011a2.527,2.527,0,0,1,.292.048,1.217,1.217,0,0,1,.344.138,1.1,1.1,0,0,1,.3.249,1.082,1.082,0,0,1,.188.343,1.322,1.322,0,0,1,.066.429m-.582.045a.865.865,0,0,0-.095-.424.653.653,0,0,0-.235-.254.792.792,0,0,0-.29-.107,1.978,1.978,0,0,0-.29-.022h-.511v1.668h.5a1.169,1.169,0,0,0,.416-.064.784.784,0,0,0,.279-.178.742.742,0,0,0,.17-.273.992.992,0,0,0,.059-.346"
      transform="translate(-361.075 -78.631)"
      fill="#030404"
    />
    <path
      id="Path_18"
      data-name="Path 18"
      d="M440.9,172.108a.079.079,0,0,1-.013.045.088.088,0,0,1-.045.031.532.532,0,0,1-.086.02.994.994,0,0,1-.133.007,1.007,1.007,0,0,1-.132-.007.529.529,0,0,1-.088-.02.091.091,0,0,1-.045-.031.079.079,0,0,1-.012-.045v-3.993a.069.069,0,0,1,.014-.044.091.091,0,0,1,.048-.031.5.5,0,0,1,.086-.019.933.933,0,0,1,.127-.008.982.982,0,0,1,.133.008.48.48,0,0,1,.086.019.085.085,0,0,1,.045.031.076.076,0,0,1,.013.044Z"
      transform="translate(-365.112 -78.619)"
      fill="#030404"
    />
    <path
      id="Path_19"
      data-name="Path 19"
      d="M450.2,168.281a.768.768,0,0,1-.006.106.237.237,0,0,1-.021.072.1.1,0,0,1-.036.04.084.084,0,0,1-.047.013h-1.17v3.607a.078.078,0,0,1-.013.045.087.087,0,0,1-.045.031.6.6,0,0,1-.086.02.991.991,0,0,1-.133.007,1.013,1.013,0,0,1-.132-.007.606.606,0,0,1-.087-.02.084.084,0,0,1-.045-.031.078.078,0,0,1-.012-.045v-3.607H447.2a.086.086,0,0,1-.047-.013.09.09,0,0,1-.034-.04.306.306,0,0,1-.021-.072.6.6,0,0,1-.008-.106.627.627,0,0,1,.008-.108.327.327,0,0,1,.021-.075.089.089,0,0,1,.034-.042.086.086,0,0,1,.047-.013h2.893a.084.084,0,0,1,.047.013.1.1,0,0,1,.036.042.254.254,0,0,1,.021.075.8.8,0,0,1,.006.108"
      transform="translate(-367.727 -78.631)"
      fill="#030404"
    />
    <path
      id="Path_20"
      data-name="Path 20"
      d="M460.85,171.995a.4.4,0,0,1,.027.114.078.078,0,0,1-.024.066.159.159,0,0,1-.086.029,1.545,1.545,0,0,1-.159.006c-.066,0-.119,0-.159,0a.31.31,0,0,1-.09-.016.092.092,0,0,1-.045-.03.318.318,0,0,1-.027-.048l-.356-1.012H458.2l-.342,1a.166.166,0,0,1-.025.05.128.128,0,0,1-.047.036.291.291,0,0,1-.088.021,1.336,1.336,0,0,1-.146.006,1.276,1.276,0,0,1-.152-.007.159.159,0,0,1-.084-.031.085.085,0,0,1-.023-.066.4.4,0,0,1,.028-.115l1.395-3.864a.181.181,0,0,1,.034-.058.137.137,0,0,1,.062-.036.514.514,0,0,1,.1-.018c.042,0,.095,0,.159,0s.126,0,.171,0a.587.587,0,0,1,.109.018.149.149,0,0,1,.064.037.168.168,0,0,1,.035.059Zm-1.791-3.411h0l-.717,2.074h1.446Z"
      transform="translate(-371.683 -78.619)"
      fill="#030404"
    />
    <path
      id="Path_21"
      data-name="Path 21"
      d="M471.252,171.95a.734.734,0,0,1-.007.108.228.228,0,0,1-.021.074.1.1,0,0,1-.036.045.083.083,0,0,1-.05.015H469.3a.226.226,0,0,1-.14-.05.206.206,0,0,1-.066-.175v-3.851a.075.075,0,0,1,.013-.045.079.079,0,0,1,.045-.03.415.415,0,0,1,.086-.02,1.006,1.006,0,0,1,.132-.008.992.992,0,0,1,.134.008.393.393,0,0,1,.084.02.088.088,0,0,1,.046.03.085.085,0,0,1,.013.045v3.6h1.491a.088.088,0,0,1,.05.014.106.106,0,0,1,.036.042.194.194,0,0,1,.021.072.771.771,0,0,1,.007.109"
      transform="translate(-376.257 -78.619)"
      fill="#030404"
    />
  </svg>
);

export const InstragramLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.781"
    height="42.781"
    viewBox="0 0 42.781 42.781"
  >
    <g
      id="Group_37"
      data-name="Group 37"
      transform="translate(-75.441 -3535.326)"
    >
      <path
        id="Path_1596"
        data-name="Path 1596"
        d="M118.222,6599.717a21.39,21.39,0,1,1-21.39-21.391A21.39,21.39,0,0,1,118.222,6599.717Z"
        transform="translate(0 -3043)"
        fill="#fff"
      />
      <path
        id="Path_1597"
        data-name="Path 1597"
        d="M91.036,6587.354a7.264,7.264,0,0,0-7.256,7.256v10.214a7.264,7.264,0,0,0,7.256,7.256h11.591a7.264,7.264,0,0,0,7.256-7.256V6594.61a7.264,7.264,0,0,0-7.256-7.256Zm11.591,26.8H91.036a9.34,9.34,0,0,1-9.329-9.329V6594.61a9.34,9.34,0,0,1,9.329-9.33h11.591a9.34,9.34,0,0,1,9.329,9.33v10.214A9.34,9.34,0,0,1,102.627,6614.153Z"
        transform="translate(0 -3043)"
        fill="#d9000d"
      />
      <path
        id="Path_1598"
        data-name="Path 1598"
        d="M96.832,6593.485a6.232,6.232,0,1,0,6.231,6.232A6.239,6.239,0,0,0,96.832,6593.485Zm0,14.536a8.3,8.3,0,1,1,8.3-8.3A8.314,8.314,0,0,1,96.832,6608.021Z"
        transform="translate(0 -3043)"
        fill="#d9000d"
      />
      <path
        id="Path_1599"
        data-name="Path 1599"
        d="M106.732,6591.668a1.723,1.723,0,1,1-1.723-1.723A1.723,1.723,0,0,1,106.732,6591.668Z"
        transform="translate(0 -3043)"
        fill="#d9000d"
      />
    </g>
  </svg>
);

export const FacebookLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.781"
    height="42.781"
    viewBox="0 0 42.781 42.781"
  >
    <g
      id="Group_36"
      data-name="Group 36"
      transform="translate(-129.348 -3535.326)"
    >
      <path
        id="Path_1594"
        data-name="Path 1594"
        d="M172.129,6599.717a21.39,21.39,0,1,0-21.39,21.39l.183,0V6606.18h-5.151v-5.965h5.151v-4.4c0-5.1,3.117-7.884,7.67-7.884a42.488,42.488,0,0,1,4.6.235v5.335h-3.159c-2.476,0-2.955,1.177-2.955,2.9v3.807h5.906l-.769,5.965h-5.137v13.971A21.4,21.4,0,0,0,172.129,6599.717Z"
        transform="translate(0 -3043)"
        fill="#fff"
      />
      <path
        id="Path_1595"
        data-name="Path 1595"
        d="M162.218,6606.18l.769-5.965h-5.906v-3.807c0-1.727.479-2.9,2.955-2.9H163.2v-5.335a42.506,42.506,0,0,0-4.6-.235c-4.553,0-7.67,2.78-7.67,7.884v4.4h-5.151v5.965h5.151V6621.1a21.42,21.42,0,0,0,6.159-.953V6606.18Z"
        transform="translate(0 -3043)"
        fill="#d9000d"
      />
    </g>
  </svg>
);

export const TwitterLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.782"
    height="42.782"
    viewBox="0 0 42.782 42.782"
  >
    <g
      id="Group_38"
      data-name="Group 38"
      transform="translate(-237.101 -3535.55)"
    >
      <path
        id="Path_1600"
        data-name="Path 1600"
        d="M279.883,6599.94a21.391,21.391,0,1,1-21.391-21.39A21.392,21.392,0,0,1,279.883,6599.94Z"
        transform="translate(0 -3043)"
        fill="#fff"
      />
      <path
        id="Path_1602"
        data-name="Path 1602"
        d="M271.169,6592.543a3.394,3.394,0,0,1-.987.129l.017-.1a8.1,8.1,0,0,0,1.983-2.266c.074-.255-.014-.343-.015-.351l-1.656.7-1.386.625a5.527,5.527,0,0,0-4.025-1.657c-2.882,0-5.218,2.041-5.218,4.559a6.8,6.8,0,0,0,.213,1.721c0,.132.006-.132,0,0a16.28,16.28,0,0,1-6.352-1.885c-4.178-2.117-4.44-3.582-4.44-3.582-.656.7-.995,3-.329,4.813a5.987,5.987,0,0,0,1.889,2.2,4.4,4.4,0,0,1-1.34-.22,2.948,2.948,0,0,1-.945-.461c-.407.994.411,2.77,1.824,4.044a7.49,7.49,0,0,0,2.33,1.208l-2.352.067c-.132,2.285,4.793,3.643,4.793,3.643a8.049,8.049,0,0,1-4.914,1.786,9.361,9.361,0,0,1-2.683-.394,12.8,12.8,0,0,0,8.735,3.13c8.794-.463,13.758-8.022,13.995-15.446l.011.015a6.159,6.159,0,0,0,1.293-1.087,9.076,9.076,0,0,0,1.429-1.911S271.722,6592.391,271.169,6592.543Z"
        transform="translate(0 -3043)"
        fill="#d9000d"
      />
    </g>
  </svg>
);

export const CoreTeamDesignImg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="850.77"
    height="127.683"
    viewBox="0 0 850.77 127.683"
  >
    <g
      id="Group_42"
      data-name="Group 42"
      transform="translate(-25.979 -29.115)"
      opacity="0.56"
    >
      <path
        id="Rectangle_61"
        data-name="Rectangle 61"
        d="M0,0H37.017a22.9,22.9,0,0,1,22.9,22.9V59.919a0,0,0,0,1,0,0H22.9A22.9,22.9,0,0,1,0,37.017V0A0,0,0,0,1,0,0Z"
        transform="translate(170.742 100.068) rotate(-45)"
        fill="#ef889f"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_62"
        data-name="Rectangle 62"
        d="M0,0H37.017a22.9,22.9,0,0,1,22.9,22.9V59.919a0,0,0,0,1,0,0H22.9A22.9,22.9,0,0,1,0,37.017V0A0,0,0,0,1,0,0Z"
        transform="translate(322.01 100.068) rotate(-45)"
        fill="#f7c4d0"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_63"
        data-name="Rectangle 63"
        d="M0,0H20.207a12.5,12.5,0,0,1,12.5,12.5V32.709a0,0,0,0,1,0,0H12.5A12.5,12.5,0,0,1,0,20.207V0A0,0,0,0,1,0,0Z"
        transform="translate(534.838 100.068) rotate(-45)"
        fill="#f7c4d0"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_64"
        data-name="Rectangle 64"
        d="M0,0H20.207a12.5,12.5,0,0,1,12.5,12.5V32.709a0,0,0,0,1,0,0H12.5A12.5,12.5,0,0,1,0,20.207V0A0,0,0,0,1,0,0Z"
        transform="translate(726.895 100.068) rotate(-45)"
        fill="#fff"
        stroke="#f7c4d0"
        stroke-miterlimit="10"
        stroke-width="4"
      />
      <path
        id="Rectangle_65"
        data-name="Rectangle 65"
        d="M0,0H22.838a14.13,14.13,0,0,1,14.13,14.13V36.968a0,0,0,0,1,0,0H14.13A14.13,14.13,0,0,1,0,22.838V0A0,0,0,0,1,0,0Z"
        transform="translate(273.461 130.305) rotate(-45)"
        fill="#ef889f"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_66"
        data-name="Rectangle 66"
        d="M0,0H22.838a14.13,14.13,0,0,1,14.13,14.13V36.968a0,0,0,0,1,0,0H14.13A14.13,14.13,0,0,1,0,22.838V0A0,0,0,0,1,0,0Z"
        transform="translate(467.414 58.084) rotate(-45)"
        fill="#fff"
        stroke="#ef889f"
        stroke-miterlimit="10"
        stroke-width="4"
      />
      <path
        id="Rectangle_67"
        data-name="Rectangle 67"
        d="M0,0H10.941A6.769,6.769,0,0,1,17.71,6.769V17.71a0,0,0,0,1,0,0H6.769A6.769,6.769,0,0,1,0,10.941V0A0,0,0,0,1,0,0Z"
        transform="translate(145.282 116.698) rotate(-45)"
        fill="#f7c4d0"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_68"
        data-name="Rectangle 68"
        d="M0,0H10.941A6.769,6.769,0,0,1,17.71,6.769V17.71a0,0,0,0,1,0,0H6.769A6.769,6.769,0,0,1,0,10.941V0A0,0,0,0,1,0,0Z"
        transform="translate(683.825 125.071) rotate(-45)"
        fill="#f7c4d0"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_69"
        data-name="Rectangle 69"
        d="M0,0H10.941A6.769,6.769,0,0,1,17.71,6.769V17.71a0,0,0,0,1,0,0H6.769A6.769,6.769,0,0,1,0,10.941V0A0,0,0,0,1,0,0Z"
        transform="translate(408.95 125.071) rotate(-45)"
        fill="#f7c4d0"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_70"
        data-name="Rectangle 70"
        d="M0,0H10.941A6.769,6.769,0,0,1,17.71,6.769V17.71a0,0,0,0,1,0,0H6.769A6.769,6.769,0,0,1,0,10.941V0A0,0,0,0,1,0,0Z"
        transform="translate(638.823 100.068) rotate(-45)"
        fill="#ef889f"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="Rectangle_71"
        data-name="Rectangle 71"
        d="M0,0H10.941A6.769,6.769,0,0,1,17.71,6.769V17.71a0,0,0,0,1,0,0H6.769A6.769,6.769,0,0,1,0,10.941V0A0,0,0,0,1,0,0Z"
        transform="translate(59.421 100.068) rotate(-45)"
        fill="#fff"
        stroke="#ef889f"
        stroke-miterlimit="10"
        stroke-width="4"
      />
      <line
        id="Line_38"
        data-name="Line 38"
        x2="83.735"
        transform="translate(107.527 100.068)"
        fill="#fff"
        stroke="#ef889f"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
      <line
        id="Line_39"
        data-name="Line 39"
        x2="73.098"
        transform="translate(801.651 100.068)"
        fill="#fff"
        stroke="#ef889f"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
      <line
        id="Line_40"
        data-name="Line 40"
        x2="318.65"
        transform="translate(284.954 100.068)"
        fill="#fff"
        stroke="#f7c4d0"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
      <line
        id="Line_41"
        data-name="Line 41"
        x2="52.334"
        transform="translate(603.193 74.948)"
        fill="#fff"
        stroke="#f7c4d0"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
      <line
        id="Line_42"
        data-name="Line 42"
        x2="80.594"
        transform="translate(27.979 134.608)"
        fill="#fff"
        stroke="#f7c4d0"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
      <line
        id="Line_43"
        data-name="Line 43"
        x2="17.794"
        transform="translate(678.554 74.948)"
        fill="#fff"
        stroke="#f7c4d0"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
    </g>
  </svg>
);

export const HeaderWhatsappLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.50002 12C3.50002 7.30558 7.3056 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C10.3278 20.5 8.77127 20.0182 7.45798 19.1861C7.21357 19.0313 6.91408 18.9899 6.63684 19.0726L3.75769 19.9319L4.84173 17.3953C4.96986 17.0955 4.94379 16.7521 4.77187 16.4751C3.9657 15.176 3.50002 13.6439 3.50002 12ZM12 1.5C6.20103 1.5 1.50002 6.20101 1.50002 12C1.50002 13.8381 1.97316 15.5683 2.80465 17.0727L1.08047 21.107C0.928048 21.4637 0.99561 21.8763 1.25382 22.1657C1.51203 22.4552 1.91432 22.5692 2.28599 22.4582L6.78541 21.1155C8.32245 21.9965 10.1037 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM14.2925 14.1824L12.9783 15.1081C12.3628 14.7575 11.6823 14.2681 10.9997 13.5855C10.2901 12.8759 9.76402 12.1433 9.37612 11.4713L10.2113 10.7624C10.5697 10.4582 10.6678 9.94533 10.447 9.53028L9.38284 7.53028C9.23954 7.26097 8.98116 7.0718 8.68115 7.01654C8.38113 6.96129 8.07231 7.046 7.84247 7.24659L7.52696 7.52195C6.76823 8.18414 6.3195 9.2723 6.69141 10.3741C7.07698 11.5163 7.89983 13.314 9.58552 14.9997C11.3991 16.8133 13.2413 17.5275 14.3186 17.8049C15.1866 18.0283 16.008 17.7288 16.5868 17.2572L17.1783 16.7752C17.4313 16.5691 17.5678 16.2524 17.544 15.9269C17.5201 15.6014 17.3389 15.308 17.0585 15.1409L15.3802 14.1409C15.0412 13.939 14.6152 13.9552 14.2925 14.1824Z"
      fill="#f5f5f5"
    />
  </svg>
);
