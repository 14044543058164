import React from "react";
import CoreTeam from "../../component/CoreTeam";
import AboutOffering from "../../component/AboutOffering";
import BgCoverImg from "../../component/BgCoverImg";
import Ambience from "../../component/Ambience";
import IvfSuccessAndCost from "../../component/IvfSuccessAndCost";
import Whyoma from "../../component/WhyOma";

import {
  AboutOfferingData,
  ivfData,
  CommonBackgroundImage,
  MeetDoctorsData,
  AmbienceData,
  AmbienceImages,
  IvfSuccessAndCostData,
  AboutOfferingSubData,
} from "./data";
import Footer from "../../common/Footer";
import ComplexCommon from "../../component/ComplexComponent";
import FertilityTreatmentTabs from "../../component/FertilityTreatmentTab";
import AboutUs from "../../component/AboutUs";
import OurServices from "../../component/OurServices";

const Ivf = () => {
  return (
    <>
      <AboutOffering
        data={AboutOfferingData}
        form={<FertilityTreatmentTabs />}
      />
      <Whyoma />
      <AboutUs data={AboutOfferingSubData}/>
      {/* <ComplexCommon data={ivfCircleData} circleCount={"ten"} /> */}
      <OurServices data={ivfData} noofcolumns="2" pinkBg />
      <BgCoverImg data={CommonBackgroundImage} />
      <IvfSuccessAndCost data={IvfSuccessAndCostData} />
      {/* <OurServices /> */}
      <CoreTeam data={MeetDoctorsData} slidesToShow={1} />
      {/* <Ambience data={AmbienceData} items={AmbienceImages} /> */}
      <Footer />
    </>
  );
};

export default Ivf;
