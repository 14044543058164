import React from "react";
import { Row, Col } from "reactstrap";
import "./style.scss";

const OurTreatments = ({ data, colSize }) => {
  return (
    <section className="section-wrapper bottom-common-effect">
      <div className="container">
        <div className="content-wrapper">
          <div className="our-treatments-main-container">
            {data?.mainHeading && (
              <div className="template-heading">{data?.mainHeading}</div>
            )}
            <div className="our-treatments-data">
              <Row>
                {data?.map((data) => {
                  return (
                    <Col
                      className="bg-light border"
                      sm={colSize || "4"}
                      xs="12"
                      key={data?.key}
                    >
                      <div className="our-treatments-wrapper">
                        <div className="circle-wrapper">
                          <div className="our-treatments-circle" />
                        </div>
                        <div className="our-treatments-description">
                          {data?.treatment && <div>{data?.treatment}</div>}
                          {data?.desc && (
                            <div style={{ marginTop: "8px" }}>{data?.desc}</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
            {/* <div className="common-design-img">
          <img src={CommonDesign} />
        </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTreatments;
