import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { navLinks, offcanvasLinks, communicationInformation } from "./data";
import "./index.scss";
import { HeaderWhatsappLogo, Logo } from "../../assets/img/Svg";
import callLogo from "../../assets/img/Call.png";
import mailLogo from "../../assets/img/Email.png";
import emLogo from "../../assets/img/emergency.png";
import menuMobile from "../../assets/img/menuMobile.png";
import whatsapp from "../../assets/img/watsapp.png";

const Header = () => {
  const [show, setShow] = useState();
  const { pathname } = useLocation();

  const handleCanvas = () => {
    setShow(!show);
  };

  let scrollPage = (d, sub) => {
    if (d.menuId === "aboutoma") {
      let el = document.getElementById(sub.id);
      el.scrollIntoView();
    } else {
      window.scroll(0, 0);
    }
  };
  const currentLocation = pathname;
  const setActiveTab = (route) => {
    if (currentLocation === route) {
      // setState(currentLocation)
      return "active";
    } else {
      return "";
    }
  };

  return (
    <>
      <header>
        <div className="header top-header">
          <div className="header-left"></div>
          <div className="header-right">
            <div className="contact-header">
              <a href="tel:7208973301" class="contact-link">
                <span className="contact-header-img">
                  <img src={callLogo} alt="" />
                </span>
                {communicationInformation?.call}
              </a>
            </div>
            <div className="contact-header">
              <a href="mailto:info@omahospital.com" class="contact-link">
                <span className="contact-header-img">
                  <img src={mailLogo} alt="" />
                </span>
                {communicationInformation?.email}
              </a>
            </div>
            <div className="contact-header">
              <a href="tel:9136956680" class="contact-link">
                <span className="contact-header-img">
                  <img src={emLogo} alt="" />
                </span>
                {communicationInformation?.emergency}
              </a>
            </div>

            <div className="contact-header">
              <a
                href="https://wa.me/7208973301"
                class="contact-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="contact-header-img">
                  {/* <img src={watsapp} alt="" /> */}
                  <span>
                    <HeaderWhatsappLogo />
                  </span>
                </span>
                <span>{communicationInformation?.whatsapp}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="header main-header">
          <div className="header-left">
            <Link to="/" className="logo" onClick={() => window.scroll(0, 0)}>
              <Logo />
            </Link>
          </div>
          <div className="header-right">
            <Nav>
              {navLinks.map((d) => {
                return (
                  <NavItem key={d.label}>
                    <Link
                      className="nav-link"
                      to={d.url}
                      onClick={() => window.scroll(0, 0)}
                    >
                      {d.label}
                    </Link>
                    {d.subnav ? (
                      <div key={Math.random()}>
                        <div className="sub-header">
                          <ul>
                            {d.subnavList.map((subdata) => (
                              <li
                                key={subdata.label}
                                onClick={() => scrollPage(d, subdata)}
                              >
                                <Link
                                  // to="#{d.id}"
                                  to={subdata.url}
                                  className="subheader-link"
                                >
                                  <span className="sub-header-img">
                                    <img
                                      src={subdata.icons}
                                      alt=""
                                      className="sub-header-icon"
                                    />
                                  </span>
                                  <div className="sub-header-title">
                                    {subdata.label}
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </NavItem>
                );
              })}
            </Nav>
          </div>

          <div className="offcanvas-container-main">
            <span className="offcanvas-btn">
              <img src={menuMobile} alt="" onClick={() => handleCanvas()} />
            </span>
            <Offcanvas
              direction="end"
              isOpen={show}
              toggle={() => handleCanvas()}
            >
              <OffcanvasHeader toggle={() => handleCanvas()}></OffcanvasHeader>
              <OffcanvasBody>
                <ul className="offcanvas-list" onClick={() => handleCanvas()}>
                  {offcanvasLinks.map((d) => {
                    return (
                      <>
                        <Link
                          key={d.label}
                          to={d.url}
                          className={`${setActiveTab(d.url)}`}
                        >
                          <li
                            className="offcanvas-list-data"
                            onClick={() => window.scroll(0, 0)}
                          >
                            {d.label}
                          </li>
                        </Link>
                        {d.subnav ? (
                          <ul>
                            {d.subnavList.map((subdata) => (
                              <li
                                key={subdata.label}
                                className="offcanvas-list-subdata"
                                onClick={() => window.scroll(0, 0)}
                              >
                                &nbsp;&nbsp;
                                <Link
                                  to={subdata.url}
                                  className={`offcanvas-list-subdata-link ${setActiveTab(
                                    subdata.url
                                  )}`}
                                >
                                  {subdata.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </ul>

                <div className="offcanvas-body-bottom">
                  <div className="offcanvas-bottom-wrapper">
                    <div className="offcanvas-bottom-content">
                      <span className="offcanvas-img">
                        <img src={callLogo} alt="" className="offcanvas-img" />
                      </span>
                      {communicationInformation?.call}
                    </div>
                    <div className="offcanvas-bottom-content">
                      <Link
                        to="#"
                        onClick={(e) => {
                          window.location.href = "mailto:hello@omahospital.com";
                          e.preventDefault();
                        }}
                      >
                        <span className="offcanvas-img">
                          <img
                            src={mailLogo}
                            alt=""
                            className="offcanvas-img"
                          />
                        </span>
                        {communicationInformation?.email}
                      </Link>
                    </div>
                    <div className="offcanvas-bottom-content">
                      <span className="offcanvas-img">
                        <img src={emLogo} alt="" className="offcanvas-img" />
                      </span>
                      {communicationInformation?.emergency}
                    </div>
                    {/* <div className="offcanvas-bottom-content">
                      <a href="https://wa.me/7208973301">
                        <span className="offcanvas-img">
                          <HeaderWhatsappLogo className="offcanvas-img" />
                        </span>
                        {communicationInformation?.whatsapp}
                      </a>
                    </div> */}
                  </div>
                </div>
              </OffcanvasBody>
            </Offcanvas>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
