import React from "react";
import CoreTeam from "../../component/CoreTeam";
import AboutOffering from "../../component/AboutOffering";
import Ambience from "../../component/Ambience";
import {
  AboutOfferingData,
  radiologyCircleData,
  MeetDoctorsData,
  AmbienceData,
  AmbienceImages,
  RadiologyData,
} from "./data";
import Footer from "../../common/Footer";
import ComplexCommon from "../../component/ComplexComponent";
import OurServices from "../../component/OurServices";

const Radiology = () => {
  return (
    <>
      <AboutOffering data={AboutOfferingData} />
      {/* <ComplexCommon data={radiologyCircleData} circleCount={"seven"} /> */}
      <OurServices data={RadiologyData} noofcolumns="2" pinkBg />
      <CoreTeam data={MeetDoctorsData} />
      <Ambience data={AmbienceData} items={AmbienceImages} />
      <Footer />
    </>
  );
};
export default Radiology;
