import React from "react";
import { foundingDoctor } from "./data";
import OurTreatments from "../../component/OurTreatment";
import { Col, Row } from "reactstrap";
import "./style.scss";

const FoundingDoctor = () => {
  return (
    <section
      id="founding-doctor"
      className="section-wrapper about-offering-section"
    >
      <div className="container">
        <div className="content-wrapper">
          <div
            className="template-heading page-heading"
            style={{ paddingBottom: "30px" }}
          >
            {foundingDoctor?.mainHeading}
          </div>
          <div className="template-heading">{foundingDoctor?.subHeading}</div>
          {foundingDoctor?.description?.map((desc) => {
            return <p className="template-content">{desc}</p>;
          })}
          <Row style={{ paddingBottom: "20px" }}>
            {foundingDoctor?.expertise?.map((data) => {
              return (
                <Col
                  sm="12"
                  xs="12"
                  key={data?.key}
                  // style={{ padding: "0px 160px" }}
                >
                  <div className="our-treatments-wrapper">
                    <div className="circle-wrapper">
                      <div className="our-treatments-circle" />
                    </div>
                    <div className="our-treatments-description">
                      {data?.treatment && <div>{data?.treatment}</div>}
                      {data?.desc && (
                        <div style={{ marginTop: "8px" }}>{data?.desc}</div>
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          {foundingDoctor?.bottomDescription?.map((desc) => {
            return <p className="template-content">{desc}</p>;
          })}
        </div>
      </div>
    </section>
  );
};

export default FoundingDoctor;
