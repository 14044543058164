import React from "react";
import AboutUs from "../../component/AboutUs";
import CoreTeam from "../../component/CoreTeam";
import Infrastructure from "../../component/Infrastructure";
import Award from "../../component/Award";
import Footer from "../../common/Footer";
import SuccessStories from "../../component/SuccessStories";
// import TeamSlider from "../../component/TeamSlider";

import {
  AboutUsData,
  MeetCoreTeamData,
  successStories,
  awardData,
  FoundingDoctor,
  ourInfrastructure,
} from "./data";
import AboutYou from "../../component/AboutYou";

const AboutOMA = () => {
  return (
    <>
      <AboutUs data={AboutUsData} />
      <AboutYou />
      <CoreTeam
        data={FoundingDoctor}
        slidesToShow={1}
        hideButton
        KnowMoreFoundingDoctor
        navlink={"/founding-doctor"}
      />
      <CoreTeam data={MeetCoreTeamData} />
      <Infrastructure data={ourInfrastructure} />
      {/* <Award data={awardData} /> */}
      {/* <SuccessStories data={successStories} /> */}
      <Footer />
    </>
  );
};

export default AboutOMA;
