import React from "react";
import "./style.scss";
const AboutUs = ({ data }) => {
  return (
    <>
      <section
        className="section-wrapper about-detail-section"
        id="aboutus-page"
      >
        <div className="container">
          <div className="content-wrapper">
            <div className="template-heading-ivf oma-aboutus-heading">
              {data.heading1}
              <br />
              {data.heading2}
            </div>
            <p className="template-content">{data?.description1}</p>
            <p className="template-content">{data?.description2}</p>
            <p className="template-content">{data?.description3}</p>
            <p className="template-content">{data?.description4}</p>
            <p className="template-content">{data?.description5}</p>
            {/* <div className="stat-wrapper">
              {data.circleData.map((item) => {
                return (
                  <div className="stat-detail" key={item.key}>
                    <div className="stat-circle"></div>
                    <h2 className="stats-number">{item.Number}</h2>
                    <p className="stats-descreption">{item.desc}</p>
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
