import React from "react";
import CoreTeam from "../../component/CoreTeam";
import ComplexCommon from "../../component/ComplexComponent";
import AboutOffering from "../../component/AboutOffering";
import BgCoverImg from "../../component/BgCoverImg";
import Ambience from "../../component/Ambience";
import {
  AboutOfferingData,
  CommonBackgroundImage,
  MeetDoctorsData,
  AmbienceData,
  AmbienceImages,
  maternityCircleData,
} from "./data";
import Footer from "../../common/Footer";

const Maternity = () => {
  return (
    <>
      <AboutOffering data={AboutOfferingData} />
      <ComplexCommon data={maternityCircleData} circleCount={"eight"} />
      <BgCoverImg data={CommonBackgroundImage} />
      <CoreTeam data={MeetDoctorsData} />
      <Ambience data={AmbienceData} items={AmbienceImages} maternity={true}/>
      <Footer />
    </>
  );
};

export default Maternity;
