import ComponentImg from "../../assets/img/fertility-circle.png";
export let knowMoreData = {
    mainHeading: "Welcome to Oma Hospital, where excellence meets innovation in healthcare",
    subHeading1: "Our commitment to patient well-being is reflected in our cutting-edge Modular Operation Theatre (OT), designed to elevate the standards of surgical environments. Explore the features, advantages, and benefits that distinguish our Modular OT",
    services: [
      {
        key: 1,
        title: "Medical-Grade Stainless Steel Walls",
        service: `Oma Hospital's Modular OT is fortified with medical-grade stainless steel walls, ensuring a robust and sterile environment. The durable structure simplifies cleaning procedures and upholds the highest standards of infection control.`,
      },
      {
        key: 2,
        title: "Anti-Bacterial Coating",
        service:
          "We prioritize patient safety by incorporating anti-bacterial coating on our OT walls. This advanced feature minimizes infection risks, establishing a secure space for surgeries and post-operative care.",
      },
      {
        key: 3,
        title: "Laminar Airflow",
        service: `Equipped with laminar airflow technology, our Modular OT maintains a consistent, contaminant-free air supply. This ensures a sterile surgical field, reducing the likelihood of surgical site infections and enhancing overall surgical precision.`,
      },
      {
        key: 4,
        title: "Climate Control",
        service:
          "Oma Hospital recognizes the importance of a comfortable environment for both patients and surgical teams. Our Modular OT features advanced climate control, maintaining optimal temperature and humidity levels for a conducive surgical atmosphere.",
      },
      {
        key: 5,
        title: "Steris OT Lights (from France): ",
        service:
          "Illuminate the path to success with Steris OT lights from France. These state-of-the-art lights provide unparalleled brightness and focus, ensuring surgeons have optimal visibility during complex procedures. Precision and clarity define every surgery performed under the brilliance of Steris lights.",
      },
      {
        key: 6,
        title: "Maquet OT Table (from Germany)",
        service:
          "Elevate your surgical experience with the Maquet OT Table from Germany. Engineered for flexibility and stability, this table allows for seamless patient positioning, enhancing the efficiency of surgical teams and contributing to positive patient outcomes.",
      },
      {
        key: 7,
        title: "Medec Anaesthesia Machine (from Belgium)",
        service:
          "Trust in the precision and reliability of the Medec Anaesthesia Machine from Belgium. Designed to meet the highest safety standards, this machine provides precise control over anesthesia delivery, ensuring a secure and monitored experience for patients.",
      },
    ],
  };



  export let infraCircleData = {
    mainHeading: "Advantages and Benefits",
    circleImg: ComponentImg,
    circleData: [
      {},
      {
        class: "dot-container left-alignT",
        textSpan: "header-hover",
        headingClass: "radiology-header",
        headingText: "Global Excellence in Equipment:",
        text: "The integration of cutting-edge equipment from Steris (France), Maquet (Germany), and Medec (Belgium) underscores our commitment to global excellence, ensuring reliability, efficiency, and adherence to the highest international standards.",
      },
      {},
      {},
      {},
      {
        class: "dot-container right-alignT",
        textSpan: "ag-text-left-side header-hover",
        headingClass: "radiology-header",
        headingText: "Patient-Centric Climate Control:",
        text: "Oma Hospital's commitment to patient comfort is evident in the climate-controlled Modular OT, fostering a positive experience for patients and supporting the concentration of surgical teams. ",
      },
      {},
      {
        class: "dot-container right-alignT",
        textSpan: "ag-text-left-side header-hover",
        headingClass: "radiology-header",
        headingText: "Optimized Infection Control:",
        text: "The combination of stainless steel walls and anti-bacterial coating ensures a hygienic environment, minimizing infection risks and prioritizing the safety of patients and healthcare professionals",
      },
      {},
      {},
      {},
      {
        class: "dot-container left-alignT",
        textSpan: "header-hover",
        headingClass: "radiology-header",
        headingText: "Enhanced Surgical Precision:",
        text: "Our top-tier equipment, including Steris lights, Maquet OT Table, and Medec Anaesthesia Machine, facilitates enhanced surgical precision, contributing to successful outcomes and shorter recovery times.",
      },
    ],
    responsiveCircleData: [
      {
        positionClass: "bottom",
        headingClass: "radiology-header-mobile",
        headingText: "Ultrasound",
        text: "Ultrasound at high frequencies is used for this prenatal imaging. It is also used frequently in gynaecology, obstetrics, and paediatrics.",
      },
      {
        positionClass: "bottom",
        headingClass: "radiology-header-mobile",
        headingText: "MRI (Magnetic Resonance Imaging)",
        text: "High-quality, radiation-free imaging of internal organs is made possible by this method. Unless absolutely necessary, we avoid pregnant women from getting an MRI.",
      },
      {
        positionClass: "top",
        headingClass: "radiology-header-mobile",
        headingText: "CT (Computed Tomography)",
        text: "Medical imaging in this format provides cross-sectional images of the body, facilitating the diagnosis and localisation of the disease.",
      },
      {
        positionClass: "top",
        headingClass: "radiology-header-mobile",
        headingText: "X-RAY",
        text: "A deeper look at the cavities, bones or other foreign objects through the tissue is made possible by this type of medical imaging. To obtain a real-time video, contrast agents may be utilised by our specialists",
      },
    ],
    contentDesc:
    "At Oma Hospital, we strive for excellence in healthcare through innovation and patient-centric care. Join us in experiencing the future of surgery. Your health, our priority.",
  };