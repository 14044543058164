import React from "react";
import ConnectPage from "../../component/ConnectPage";
import QuickPoint from "../../component/QuickTouchpoint";
import Openings from "../../component/Openings";
import { connectPageData, openingsData, quickPointData } from "./data.js";
import Footer from "../../common/Footer";


const LetsConnect = () => {
  return (
    <>
      <ConnectPage data={connectPageData} />
      <QuickPoint data={quickPointData}/>
      <Openings data={openingsData} />
      <Footer/>
    </>
  );
};
export default LetsConnect;
