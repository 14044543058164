import React, { useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";

import Banner1 from "../../assets/img/HomeBanner1.jpg";
import Banner2 from "../../assets/img/HomeBanner2.jpg";
import Banner3 from "../../assets/img/HomeBanner3.jpg";
import Banner4 from "../../assets/img/HomeBanner4.jpg";
import Banner5 from "../../assets/img/HomeBanner5.jpg";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Button,
} from "reactstrap";
import { HashLink } from "react-router-hash-link";
import AboutOMA from "../AboutOMA";

const Home = () => {
  const items = [
    {
      src: Banner1,
      heading: "A WOMEN'S SPECIALITY HOSPITAL: IT’S ALL ABOUT YOU",
      subheading: "Delivering compassionate & trusted patient-centred care",
      btn: "Ask us",
      link: "/aboutus",
      content_position: "left",
      key: 1,
      showBtn: true,
    },
    {
      src: Banner2,
      heading: "DR. TANUJA - THE HEART BEHIND THE MIND OF OMA",
      subheading:
        "Her expertise and compassion are what make OMA so extraordinary",
      btn: "KNOW MORE",
      link: "/founding-doctor",
      content_position: "left",
      key: 2,
      showBtn: true,
    },
    {
      src: Banner3,
      heading: "TREASURING EVERY MOMENT, VALUING EVERY EMOTION ",
      subheading:
        "Aesthetic Gynaecology - A unique and specialised approach to intimate well-being",
      btn: "know More",
      link: "/aesthetic/#offerings",
      content_position: "right",
      key: 3,
      showBtn: true,
    },
    {
      src: Banner4,
      heading: "FROM IF TO IVF - ADDING JOY & LAUGHTER",
      subheading: "The network of exceptional care is our backbone",
      btn: "We Can Help",
      link: "/ivf/#offerings",
      content_position: "left",
      key: 4,
      showBtn: true,
    },
    {
      src: Banner5,
      heading: "ADDING AESTHETICS TO GYNECOLOGY",
      subheading:
        "State-of-the-art equipment and infrastructure that simplifies women's healthcare",
      btn: "",
      content_position: "top",
      key: 5,
      showBtn: false,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const nav = useNavigate();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        className={item.content_position}
      >
        <img src={item.src} alt="" className="carousel-img" />
        <div className="carousel-heading">
          <h1 className="primary-text">{item.heading}</h1>
          <p className="carousel-subheading">{item.subheading}</p>
          {item.showBtn && (
            <HashLink to={item?.link}>
              <Button color="secondary">{item.btn}</Button>
            </HashLink>
          )}
        </div>
      </CarouselItem>
    );
  });

  // const handleWheel = (e) => {
  //   const delta = Math.sign(e.deltaY);
  //   if (delta > 0) {
  //     next();
  //   } else if (delta < 0) {
  //     previous();
  //   }
  // };

  return (
    <>
      <div
        className="home-carousel-wrapper"
        id="home-carousel-wrapper"
        // onWheel={handleWheel}
      >
        <Carousel
          className="home-carousel"
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </div>

      <AboutOMA />
    </>
  );
};

export default Home;
