export let foundingDoctor = {
  mainHeading: "Meet Our Founding Doctor:",
  subHeading: "Dr. Tanuja Uchil",
  description: [
    "Dr. Tanuja Uchil serves as a full-time consultant in Obstetrics and Gynecology at OMA Hospital. Dr. Uchil's journey in the field of medicine is characterized by excellence, expertise, and a genuine commitment to women's health.",
    "Educational Background: Dr. Uchil's academic journey commenced with her MBBS degree from Seth G S Medical College & KEM Hospital in 1997 followed by her role as a resident medical officer specializing in Obstetrics and Gynecology at the Nowrosjee Wadia Maternity Hospital and pursuing the degree of MD in Obstetrics & Gynecology from Seth G S Medical College & KEM Hospital, University of Mumbai.",
    "Specialized Expertise: Dr. Uchil's professional journey has been marked by a deep commitment to specialized areas within her field & genuine care and warmth for her patients. She has a special interest in Infertility, Cosmetic, and Regenerative Gynecology and has actively pursued training in the field:",
  ],
  expertise: [
    { key: "1", treatment: "Advanced fertility workshops" },
    {
      key: "2",
      treatment:
        "Updates in ultrasound and infertility through a certificate course on ultrasonography in obstetrics and gynecology",
    },
    {
      key: "3",
      treatment:
        "Focused training in the management of infertility, leading to a Diploma in Reproductive Medicine from the Christian Albrechts University of Kiel, Germany",
    },
    {
      key: "4",
      treatment:
        "Advanced training in endoscopic techniques at the Center for Minimal Access Surgery Training (CEMAST)",
    },
    {
      key: "5",
      treatment:
        "Advanced Diploma in Gynaecological Laparoscopic Surgery from the University of Kiel, Germany",
    },
  ],
  bottomDescription: [
    "Contributions to the Field: Dr. Uchil is not only a dedicated practitioner but also a pioneer in her field. She is a founder member and office bearer of the Indian Society of Aesthetic & Regenerative Gynecology (InSARG). Her involvement in this society highlights her commitment to advancing and promoting regenerative Gynecology.",
    "Dr. Tanuja Uchil's journey from her early education to becoming the head of Oma Hospital is a testament to her unwavering dedication to women's health.  Her vision and leadership have played a pivotal role in establishing our institution as a boutique women's hospital specializing in Maternity, Medical & Surgical Gynecology, Gynaec Endoscopic Surgeries, Infertility, and Aesthetic Regenerative Gynecology in Chembur.",
  ],
};
